import { LetterDetailType, LettersResponse } from '../../../../type/letter'
import { format } from 'date-fns-tz'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useState } from 'react'

type ElectronicDisplayBoardType = {
  letters: LettersResponse
  clickLetter: (letter: LetterDetailType) => void
}

const handleClickLetter = (
  clickLetter: ElectronicDisplayBoardType['clickLetter'],
  letter: LetterDetailType,
) => {
  clickLetter(letter)
}

const handleClickToggle = (
  toggle: boolean,
  setToggle: Dispatch<SetStateAction<boolean>>,
) => {
  setToggle(!toggle)
}

function ElectronicDisplayBoard({
  letters,
  clickLetter,
}: ElectronicDisplayBoardType) {
  const { t } = useTranslation()
  const [toggle, setToggle] = useState(true)
  return (
    <div
      className={`${styles.container} ${toggle ? '' : styles.toggle_active}`}
    >
      <div className={styles.container_title}>New Letter</div>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Departure</th>
            <th>Departure time</th>
            <th>Arrive</th>
            <th>Arrival time</th>
          </tr>
        </thead>
        <tbody>
          {letters.map((letter, i) => {
            if (i >= 5) return []
            return (
              <tr
                onClick={() => handleClickLetter(clickLetter, letter)}
                key={i}
              >
                <th>{letter.id}</th>
                <th>{letter.sender.name}</th>
                <th>
                  {format(
                    new Date(letter.createdAt),
                    t(`util.date-time-format`),
                  )}
                </th>
                <th>{letter.recipient.name}</th>
                <th>
                  {format(
                    new Date(letter.arrivalDate),
                    t(`util.date-time-format`),
                  )}
                </th>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div
        className={`${styles.container_footer}`}
        onClick={() => handleClickToggle(toggle, setToggle)}
      >
        {toggle ? (
          <i className="fa-solid fa-chevron-up"></i>
        ) : (
          <i className="fa-solid fa-chevron-down"></i>
        )}
      </div>
    </div>
  )
}

export default ElectronicDisplayBoard
