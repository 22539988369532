import { LetterDetailType } from '../../../../type/letter'
import { format } from 'date-fns-tz'
import styles from './style.module.scss'
import { LoggedInUser } from '../../../../type/account'
import { useTranslation } from 'react-i18next'
import { hasFriendWithId } from './functions'
import Avatar from '../Avatar'

type LetterUserType = {
  letter: LetterDetailType
  loggedInUser: LoggedInUser | null
  switchAddPeople: (id: number) => void
  type: 'sender' | 'recipient'
}

function LetterUser({
  letter,
  loggedInUser,
  switchAddPeople,
  type,
}: LetterUserType) {
  const { t } = useTranslation()

  let isSomeLoggedInUserAndLetterTypeId = true
  let isIncludesFriends = true
  const friendId = letter[type].id

  if (loggedInUser) {
    isSomeLoggedInUserAndLetterTypeId = loggedInUser.id === letter[type].id
    isIncludesFriends = hasFriendWithId(loggedInUser, friendId)
  }

  // 친구 목록에 포함되어 있지도 않고 로그인 유저도 아니라면 true.
  const showAddFriend = !isSomeLoggedInUserAndLetterTypeId && !isIncludesFriends

  /**
   * 편지지에서 친구추가를 눌렀을 시 실행
   * @returns
   */
  const handleClickEvent = () => {
    if (showAddFriend) {
      return switchAddPeople(letter[type].id)
    }
  }

  // 친구 아이콘을 보여줄지 말지 결정하는 함수
  const showFriendIcon = () =>
    showAddFriend || isSomeLoggedInUserAndLetterTypeId ? (
      ''
    ) : (
      <div className={styles.container_friend_icon}>
        <i className="fa-solid fa-user-check"></i>
      </div>
    )
  return (
    <div className={styles.container}>
      <div className={styles.container_info}>
        <div className={styles.container_info_wrap}>
          <Avatar userId={letter.sender.id} imgWidth={50} imgHeight={50} />
          <div className={styles.container_info_wrap_text}>
            {type === 'sender' ? <span>From</span> : <span>To</span>}
            <div className={styles.container_info_wrap_text_user}>
              <p>{letter[type].name}</p>
              <span>{showFriendIcon()}</span>
            </div>
            {type === 'sender' ? (
              <span>
                {format(new Date(letter.createdAt), t(`util.date-time-format`))}
                {type === 'sender' ? (
                  <span>{t(`ui.molecules.letter-card.label.send`)}</span>
                ) : (
                  <span>
                    {t(`ui.molecules.letter-card.label.scheduled-to-arrive`)}
                  </span>
                )}
              </span>
            ) : (
              <span>
                {format(
                  new Date(letter.arrivalDate),
                  t(`util.date-time-format`),
                )}
              </span>
            )}
          </div>
        </div>
        {showAddFriend ? (
          <div className={styles.container_friend} onClick={handleClickEvent}>
            {t(`ui.molecules.letter-card.label.add-friend`)}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default LetterUser
