import { Link, useNavigate } from 'react-router-dom'
import { constants } from '../../../../constants'
import Button from '../../atoms/Button'
import styles from './style.module.scss'
import { goToLogin, goToLogout, transitionPage } from './functions'
import { useTranslation } from 'react-i18next'

type HamburgerMenuType = {
  showHamburgerMenu: boolean
  setShowHamburgerMenu: (arg: boolean) => void
  isAuth: number
}
const { CLICK_NUMBER } = constants.MENU

function HamburgerMenu({
  showHamburgerMenu,
  setShowHamburgerMenu,
  isAuth,
}: HamburgerMenuType) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  // 로그아웃 버튼를 표시 할지 말지를 정함
  const loginComponent = () => {
    return (
      <div className={styles.button}>
        <Button
          btnName={t(`ui.atom.button.login`)}
          disabled={false}
          clickEvent={() => goToLogin(navigate)}
        />
      </div>
    )
  }
  const logoutComponent = () => {
    return (
      <div className={styles.button}>
        <Button
          btnName={t(`ui.atom.button.logout`)}
          type="white"
          disabled={false}
          clickEvent={() => goToLogout(t)}
        />
      </div>
    )
  }
  return (
    <div className={styles.hamburgermenu}>
      <div
        className={styles.hamburgermenu_back}
        onClick={() => {
          setShowHamburgerMenu(!showHamburgerMenu)
        }}
      >
        <i className="fa-solid fa-arrow-right"></i>
      </div>
      <div className={styles.hamburgermenu_wrap}>
        <div className={styles.hamburgermenu_wrap_detail}>
          <ul>
            <li
              onClick={() =>
                transitionPage(
                  CLICK_NUMBER.MY_PAGE,
                  navigate,
                  setShowHamburgerMenu,
                  showHamburgerMenu,
                )
              }
            >
              <i className="fa-solid fa-user"></i>{' '}
              <span>{t(`header.my-page`)}</span>
            </li>
            <li
              onClick={() =>
                transitionPage(
                  CLICK_NUMBER.RECEIVING_TRAY,
                  navigate,
                  setShowHamburgerMenu,
                  showHamburgerMenu,
                )
              }
            >
              <i className="fa-solid fa-envelope"></i>{' '}
              <span>{t(`header.receive-letter`)}</span>
            </li>
            <li
              onClick={() =>
                transitionPage(
                  CLICK_NUMBER.SEND_TRAY,
                  navigate,
                  setShowHamburgerMenu,
                  showHamburgerMenu,
                )
              }
            >
              <i className="fa-solid fa-paper-plane"></i>{' '}
              <span>{t(`header.sent-letter`)}</span>
            </li>
            <li
              onClick={() =>
                transitionPage(
                  CLICK_NUMBER.HOW_TO_USE,
                  navigate,
                  setShowHamburgerMenu,
                  showHamburgerMenu,
                )
              }
            >
              <i className="fa-solid fa-circle-question"></i>{' '}
              <span>{t(`footer.contact`)}</span>
            </li>

            <li
              onClick={() =>
                transitionPage(
                  CLICK_NUMBER.STORE,
                  navigate,
                  setShowHamburgerMenu,
                  showHamburgerMenu,
                )
              }
            >
              <i className="fa-solid fa-people-group"></i>{' '}
              <span>{t(`header.register-membership`)}</span>
            </li>
          </ul>
        </div>
        {isAuth ? logoutComponent() : loginComponent()}
      </div>
    </div>
  )
}

export default HamburgerMenu
