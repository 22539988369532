import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import imageCompression from 'browser-image-compression'
import TabBar from '../../UI/molecules/tabBar/index'
import { Context } from '../../../Root'
import { useContext, useEffect, useState } from 'react'
import { EditAccountInfo } from '../../../type/account'
import {
  cancelMembership,
  cancelSubscription,
  deleteFriend,
  goToChangePassword,
  handleCancelEvent,
  handleTab,
  handleUserEditEvent,
  handleUserSaveEvent,
} from './functions'
import {
  PaymentHistoryResponse,
  PaymentHistoryTransformDatas,
  ProfileEditPayload,
  TabsDetail,
} from '../../../type/mypage'
import { useLocation, useNavigate } from 'react-router-dom'
import { constants } from '../../../constants'
import AccountInfo from '../../UI/organisms/AccountInfo'
import { transformPaymentHistory } from '../../../utils/transform'
import Friends from '../../UI/organisms/Friends'
import MyQRcodeModal from '../../UI/organisms/MyQRcodeModal'
import { scrollToFirst } from '../../../utils/domEvent'

const { LOCATION } = constants

const initAccount: EditAccountInfo = {
  name: '',
  email: '',
  description: '',
  coordinate: [0, 0],
}

function Mypage() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loggedInUser, reloadAccount } = useContext(Context)
  const [isLogged, setIsLogged] = useState(false)
  const [isUserProfileEdit, setIsUserProfileEdit] = useState(false)
  const [showQRcode, setShowQRcode] = useState(false)
  const [showSaveBtn, setShowSaveBtn] = useState(false)
  const [account, setAccount] = useState(initAccount)
  const [mapMarkerState, setMapMarkerState] = useState({ noMoveMarker: false })
  const [nameState, setNameState] = useState({ noName: false })
  const [selectedTabId, setSelectedTabId] = useState(1)
  const [QRCodeToken, setQRCodeToken] = useState('')
  const [payments, setPayments] = useState<PaymentHistoryResponse | null>(null)
  const [avatarFile, setAvatarFile] = useState<File | null>(null)
  const tokyo = { lng: 139.753, lat: 35.6844 }
  const [zoom] = useState(3)
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const tabId =
    queryParams.get('tab-id') === null ? 1 : Number(queryParams.get('tab-id'))
  const userId = loggedInUser?.id ?? 0

  let transformPayments: PaymentHistoryTransformDatas = []
  if (payments?.length) {
    transformPayments = transformPaymentHistory(t, payments)
  }

  const tabs: TabsDetail = [
    {
      tabId: 1,
      tabName: t(`page.my-page.tab.account`),
    },
    {
      tabId: 2,
      tabName: t(`page.my-page.tab.friends`),
    },
  ]

  const profileEditPayload: ProfileEditPayload = {
    tokyo,
    zoom,
    loggedInUser,
    account,
    mapMarkerState,
    nameState,
    setAccount,
    setMapMarkerState,
    setNameState,
  }

  useEffect(() => {
    if (!userId) {
      alert(t(`page.receive-letters.error-massage.no-login`))
      navigate(LOCATION.PATH.LOGIN)
      return
    }
    if (loggedInUser?.id) {
      setIsLogged(true)
    }
    setAccount((prevState) => {
      return Object.assign(
        { ...prevState },
        {
          name: loggedInUser?.name ?? '',
          email: loggedInUser?.email ?? '',
          description: loggedInUser?.description ?? '',
          coordinate: [
            loggedInUser?.coordinate[0] ?? 0,
            loggedInUser?.coordinate[1] ?? 0,
          ],
        },
      )
    })
    setSelectedTabId(tabId)
  }, [])

  useEffect(() => {
    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [])

  return (
    <div>
      {isLogged ? (
        <div>
          <TabBar
            pageTitle={t(`page.my-page.page-name`)}
            tabs={tabs}
            selectedTabId={selectedTabId}
            handleTab={handleTab(setSelectedTabId, navigate)}
          />
          <div className={styles.container}>
            <div>
              {selectedTabId === 1 ? (
                <AccountInfo
                  isUserProfileEdit={isUserProfileEdit}
                  profileEditPayload={profileEditPayload}
                  loggedInUser={loggedInUser}
                  handleCancelEvent={handleCancelEvent}
                  setIsUserProfileEdit={setIsUserProfileEdit}
                  handleUserSaveEvent={handleUserSaveEvent}
                  account={account}
                  mapMarkerState={mapMarkerState}
                  setAccount={setAccount}
                  setMapMarkerState={setMapMarkerState}
                  setNameState={setNameState}
                  handleUserEditEvent={handleUserEditEvent}
                  goToChangePassword={goToChangePassword}
                  navigate={navigate}
                  t={t}
                  setShowQRcode={setShowQRcode}
                  setQRCodeToken={setQRCodeToken}
                  cancelMembership={() => cancelMembership(t, navigate)}
                  avatarFile={avatarFile}
                  setAvatarFile={setAvatarFile}
                  imageCompression={imageCompression}
                  showSaveBtn={showSaveBtn}
                  setShowSaveBtn={setShowSaveBtn}
                  cancelSubscription={() => cancelSubscription(t)}
                />
              ) : (
                ''
              )}
              {selectedTabId === 2 ? (
                <Friends
                  loggedInUser={loggedInUser}
                  deleteFriend={(id) => deleteFriend(t, id, reloadAccount)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {showQRcode ? (
        <MyQRcodeModal
          cancelEvent={() => {
            setShowQRcode(!showQRcode)
          }}
          loggedInUser={loggedInUser}
          QRCodeToken={QRCodeToken}
        />
      ) : (
        ''
      )}
    </div>
  )
}
export default Mypage
