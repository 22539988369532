import { LoggedInUser } from '../../../../type/account'
import Button from '../../atoms/Button'
import ProfileEdit from '../ProfileEdit'
import ProfileInfo from '../ProfileInfo'
import styles from './style.module.scss'
import { AccountInfoType } from './functions'
import { checkSubscriptionNow } from '../../../../utils/authentication/checkSubscriptionNow'
import { format } from 'date-fns-tz'

function AccountInfo({
  isUserProfileEdit,
  profileEditPayload,
  loggedInUser,
  handleCancelEvent,
  setIsUserProfileEdit,
  handleUserSaveEvent,
  account,
  mapMarkerState,
  setAccount,
  setMapMarkerState,
  setNameState,
  handleUserEditEvent,
  goToChangePassword,
  navigate,
  t,
  setShowQRcode,
  setQRCodeToken,
  cancelMembership,
  avatarFile,
  setAvatarFile,
  imageCompression,
  showSaveBtn,
  setShowSaveBtn,
  cancelSubscription,
}: AccountInfoType) {
  const subscriptionCancelElement = () => {
    if (!loggedInUser) return
    const now = new Date()
    const membershipStartAt = new Date(loggedInUser.membershipStartAt)
    const membershipCancelAt = new Date(loggedInUser.membershipCancelAt)

    // 구독 종료일이 현재보다 크거나 같다면 구독취소를 하고 아직 시간이 남은 상태이기 때문에 true를 리턴한다
    if (now <= membershipCancelAt) {
      const motificateDate = format(
        new Date(membershipCancelAt),
        t(`util.date-time-format`),
      )
      return (
        <span style={{ fontSize: '14px', color: '#8e8e8e8e' }}>
          {t(`page.my-page.label.membership-withdrawal-date`)}: {motificateDate}
        </span>
      )
    }

    if (now >= membershipStartAt && !loggedInUser.membershipCancelAt)
      return (
        <div
          className={styles.container_wrap_detail_pw_link}
          onClick={cancelSubscription}
        >
          <p>{t(`page.my-page.link.cancel-membership`)}</p>
        </div>
      )
  }
  return (
    <div className={styles.container}>
      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_detail}>
          {isUserProfileEdit ? (
            <ProfileEdit
              profileEditPayload={profileEditPayload}
              setAvatarFile={setAvatarFile}
              imageCompression={imageCompression}
              setShowSaveBtn={setShowSaveBtn}
            />
          ) : (
            <ProfileInfo
              loggedInUser={loggedInUser as LoggedInUser}
              setShowQRcode={setShowQRcode}
              setQRCodeToken={setQRCodeToken}
              handleUserEditEvent={handleUserEditEvent}
              isUserProfileEdit={isUserProfileEdit}
              setIsUserProfileEdit={setIsUserProfileEdit}
            />
          )}
          <div className={styles.container_wrap_detail_button}>
            <div className={styles.container_wrap_detail_button_wrap}>
              {isUserProfileEdit ? (
                <div
                  className={styles.container_wrap_detail_button_wrap_buttons}
                >
                  <Button
                    btnName={t(`ui.atom.button.cancel`)}
                    type="white"
                    disabled={false}
                    clickEvent={() =>
                      handleCancelEvent(isUserProfileEdit, setIsUserProfileEdit)
                    }
                  />
                  <Button
                    btnName={t(`ui.atom.button.save`)}
                    type="secondary"
                    disabled={showSaveBtn}
                    clickEvent={() =>
                      handleUserSaveEvent(
                        account,
                        isUserProfileEdit,
                        mapMarkerState,
                        setIsUserProfileEdit,
                        setAccount,
                        setMapMarkerState,
                        setNameState,
                        avatarFile,
                        t,
                      )
                    }
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={styles.container_wrap_detail_pw}>
            <div
              className={styles.container_wrap_detail_pw_link}
              onClick={() => goToChangePassword(navigate)}
            >
              <p>{t(`page.my-page.link.password-change`)}</p>
            </div>
            <div
              className={styles.container_wrap_detail_pw_link}
              onClick={cancelMembership}
            >
              <p>{t(`page.my-page.link.delete-account`)}</p>
            </div>
            {subscriptionCancelElement()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountInfo
