import styles from './style.module.scss'
import Button from '../../../UI/atoms/Button'
import { useNavigate } from 'react-router-dom'
import { handleClickResetBtn, imgStyle, updateEmail } from './functions'
import TextInput from '../../../UI/atoms/TextInput'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import i18n from '../../../../locales/i18n'
import { constants } from '../../../../constants'
import {
  showEmailValidationErrMsg,
  showNoEmailErrMsg,
} from '../../../../utils/errorMsg'
import { scrollToFirst } from '../../../../utils/domEvent'

const { LOCATION } = constants

function ForgetPassword() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [emailState, setEmailState] = useState('')
  const [emailErrorState, setEmailErrorState] = useState({
    emailValidationErr: false,
  })
  const [noEnteredEmailState, setNoEnteredEmailState] = useState({
    noEmail: false,
  })

  useEffect(() => {
    // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
    // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
    const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
    const lang = localStorage.getItem('language') ?? localeLanguege
    i18n.changeLanguage(lang)

    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [])

  return (
    <div className={styles.container}>
      <div style={imgStyle}></div>
      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_form}>
          <div
            className={styles.container_wrap_form_arrow}
            onClick={() => {
              navigate(LOCATION.PATH.LOGIN)
            }}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </div>
          <h1>{t(`page.reset-your-password.page-name`)}</h1>
          <span>{t(`page.reset-your-password.discription`)}</span>
          <div className={styles.container_wrap_form_inputs}>
            <TextInput
              type="email"
              placeholder={t(`ui.atom.placeholder.email`)}
              onChange={updateEmail(
                setEmailState,
                setEmailErrorState,
                setNoEnteredEmailState,
              )}
            />
            {showEmailValidationErrMsg(emailErrorState, noEnteredEmailState, t)}
            {showNoEmailErrMsg(noEnteredEmailState, t)}
          </div>
          <div className={styles.container_wrap_form_btn}>
            <Button
              btnName={t(`ui.atom.button.reset-password`)}
              disabled={false}
              clickEvent={() =>
                handleClickResetBtn(
                  emailState,
                  setEmailErrorState,
                  setNoEnteredEmailState,
                  navigate,
                  t,
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword
