import { TFunction } from 'i18next'
import { postRegistLetter } from '../../../../api/letter'
import { constants } from '../../../../constants'
import { checkMinLengthText } from '../../../../utils/validate'
import { LetterRequest } from '../../../../type/letter'

const { MIN_INPUT_LENGTH } = constants

/**
 * 편지 보내기 버튼을 클릭 했을 시 실행
 * @param inputText
 * @param selectedLetterType
 * @param showLetterModal
 * @param setShowLetterModal
 * @param setNoInputErr
 * @param t
 */
export const sendLetter = async (
  registLetter: string | null,
  selectedLetterType: number,
  selectedReceiveUserId: number,
  showLetterModal: boolean,
  setShowLetterModal: (arg: boolean) => void,
  setNoInputErr: React.Dispatch<React.SetStateAction<boolean>>,
  backgroundFile: File | null,
  selectedLetterIconId: number,
  t: TFunction<'translation', undefined>,
) => {
  if (!registLetter) return
  const isMinInput = checkMinLengthText(registLetter, MIN_INPUT_LENGTH)
  setNoInputErr(isMinInput)

  if (!isMinInput) {
    const letterRequest: LetterRequest = {
      message: registLetter,
      selectedLetterType,
      selectedReceiveUserId,
      selectedLetterIconId,
    }
    // 편지 등록 API를 호출한다
    await postRegistLetter(t, letterRequest, backgroundFile)
    setShowLetterModal(!showLetterModal)
  }
}
