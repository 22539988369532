import styles from './style.module.scss'

type ProgressBarProps = {
  progress: number // 0부터 100까지의 숫자
}

const ProgressBar = ({ progress }: ProgressBarProps) => {
  const progressPercentage = `${progress}%`

  return (
    <div className={styles.container}>
      <div
        className={styles.progress_bar}
        style={{ width: progressPercentage }}
      ></div>
    </div>
  )
}

export default ProgressBar
