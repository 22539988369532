import { TFunction } from 'i18next'
import { ApiError } from '.'

/** メンテナンス中 */
export class RequestEntityTooLargeError extends ApiError {
  constructor(t: TFunction<'translation', undefined>) {
    super(
      'RequestEntityTooLargeError',
      t(`api-error-msg.request-entity-to-large`),
    )
  }
}
