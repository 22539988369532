import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as maptilersdk from '@maptiler/sdk'
import { LoggedInUser } from '../../../../type/account'
import Button from '../../atoms/Button'
import { switchOverflowHidden } from '../../../../utils/customStyle'
import { scrollToFirst } from '../../../../utils/domEvent'
import { fetchQRCodeToken } from '../../../../api/friend'
import { Context } from '../../../../Root'
import defaultUser from '../../../../media/img/default_user.png'
import Label from '../../atoms/Label'
import { checkSubscriptionNow } from '../../../../utils/authentication/checkSubscriptionNow'

type ProfileInfoType = {
  loggedInUser: LoggedInUser
  setShowQRcode: Dispatch<SetStateAction<boolean>>
  setQRCodeToken: Dispatch<SetStateAction<string>>
  handleUserEditEvent: (
    isUserProfileEdit: boolean,
    setIsUserProfileEdit: Dispatch<SetStateAction<boolean>>,
  ) => void
  isUserProfileEdit: boolean
  setIsUserProfileEdit: Dispatch<SetStateAction<boolean>>
}

function ProfileInfo({
  loggedInUser,
  setShowQRcode,
  setQRCodeToken,
  handleUserEditEvent,
  isUserProfileEdit,
  setIsUserProfileEdit,
}: ProfileInfoType) {
  const { t } = useTranslation()
  const { reactEnv } = useContext(Context)
  const mapContainer = useRef<HTMLDivElement>(null)
  const [zoom] = useState(3)
  const map = useRef<maptilersdk.Map | null>(null)
  const tokyo = { lng: 139.753, lat: 35.6844 }

  const checkCoordinate = () => {
    const [lng, lat] = loggedInUser.coordinate as number[]
    if (lng === 0 && lat === 0) {
      return [tokyo.lng, tokyo.lat]
    }
    return [lng, lat]
  }
  const nowCoordinate = checkCoordinate() as [number, number]

  // 나의 QR코드를 클릭 했을 시 실행
  const handleQRcodeClickEvent = async () => {
    scrollToFirst()
    setShowQRcode(true)
    switchOverflowHidden(true)
    await fetchQRCodeToken(t, setQRCodeToken)
  }

  // DB에 아바타 사진이 없다면 기본 이미지를 보여준다
  const showUserImg = () => {
    return loggedInUser.avatar ? loggedInUser.avatar : defaultUser
  }

  useEffect(() => {
    // Maptiler apikey를 불러온다
    const apiKey = reactEnv.reactAppMaptilerApi
    maptilersdk.config.apiKey = apiKey

    map.current = new maptilersdk.Map({
      container: mapContainer.current!,
      style: maptilersdk.MapStyle.OUTDOOR,
      center: nowCoordinate,
      zoom: zoom,
      interactive: false,
      navigationControl: false,
      geolocateControl: false,
    })

    new maptilersdk.Marker({
      draggable: false,
    })
      .setLngLat(nowCoordinate)
      .addTo(map.current)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <p>{t(`page.my-page.label.input-info`)}</p>
        <div className={styles.container_header_btn}>
          <Button
            btnName={t(`ui.atom.button.my-qr-code`)}
            disabled={false}
            clickEvent={() => handleQRcodeClickEvent()}
          />
        </div>
      </div>

      <div className={styles.container_form}>
        <div className={styles.container_form_wrap}>
          <div className={styles.container_form_wrap_avatar}>
            <div className={styles.container_form_wrap_avatar_wrap}>
              <div
                className={styles.container_form_wrap_avatar_wrap_img}
                style={{ backgroundImage: `url(${showUserImg()})` }}
              ></div>
            </div>
          </div>
          <div className={styles.container_form_wrap_desc}>
            <div className={styles.container_form_wrap_desc_text}>
              <div className={styles.container_form_wrap_desc_text_names}>
                <p>
                  <strong>{loggedInUser.name}</strong>
                </p>
                {checkSubscriptionNow(loggedInUser) ? (
                  <Label text="Membership" type="red" />
                ) : (
                  ''
                )}
              </div>

              <span>{loggedInUser.email}</span>
              <span>
                {loggedInUser.description
                  ? loggedInUser.description
                  : t(`ui.organisms.friends.label.no-desc`)}
              </span>
            </div>
            <div className={styles.container_form_wrap_desc_edit}>
              <Button
                btnName={t(`ui.atom.button.edit`)}
                type="secondary"
                disabled={false}
                clickEvent={() =>
                  handleUserEditEvent(isUserProfileEdit, setIsUserProfileEdit)
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.container_form_map}>
          <div ref={mapContainer} className={styles.container_form_map_ref} />
        </div>
      </div>
    </div>
  )
}

export default ProfileInfo
