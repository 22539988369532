import { createBrowserRouter } from 'react-router-dom'
import Root from '../Root'
import NotFound from '../components/pages/Error/notFound'
import Home from '../components/pages/Home'
import Mypage from '../components/pages/Mypage/index'
import ReceivingTray from '../components/pages/ReceivingTray'
import { accountRoute } from './accountRoute'
import { constants } from '../constants'
import SendTray from '../components/pages/SendTray'
import RegisterMembership from '../components/pages/RegisterMembership/index'
import AddFriends from '../components/pages/AddFriends'
import LetterDetail from '../components/pages/LetterDetail'
import SubscriptComplete from '../components/pages/SubscriptComplete'
import ContactUs from '../components/pages/Contact'
import ContactUsComplete from '../components/pages/ContactUsComplete'
import SpecifiedCommercialTransactionAct from '../components/pages/SpecifiedCommercialTransactionAct'
import HowToUse from '../components/pages/HowToUse/indes'

const { PATH } = constants.LOCATION

export const router = createBrowserRouter([
  {
    path: PATH.HOME,
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        path: PATH.HOME,
        element: <Home />,
      },
      {
        path: PATH.MY_PAGE,
        element: <Mypage />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.RECEIVING_TRAY,
        element: <ReceivingTray />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.SEND_TRAY,
        element: <SendTray />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.REGISTER_MEMBERSHIP,
        element: <RegisterMembership />,
        errorElement: <NotFound />,
      },
      // 친구 등록 페이지
      {
        path: PATH.ADD_FRIENDS,
        element: <AddFriends />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.LETTER_DETAIL,
        element: <LetterDetail />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.SUBSCRIPT_COMPLETE,
        element: <SubscriptComplete />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.CONTACT,
        element: <ContactUs />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.CONTACT_COMPLETE,
        element: <ContactUsComplete />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.SPECIFIED_COMMERCIAL_TRANSACTION_ACT,
        element: <SpecifiedCommercialTransactionAct />,
        errorElement: <NotFound />,
      },
      {
        path: PATH.HOW_TO_USE,
        element: <HowToUse />,
        errorElement: <NotFound />,
      },
    ],
  },
  ...accountRoute,
])
