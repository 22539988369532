import React, { useState, ChangeEvent, useEffect } from 'react'
import styles from './style.module.scss'

interface VerticalRangeBarProps {
  min: number
  max: number
  step: number
  onChange: (value: number) => void
  RangeValue: number
}

function RangeBar({
  min,
  max,
  step,
  onChange,
  RangeValue,
}: VerticalRangeBarProps) {
  const [value, setValue] = useState<number>(min)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    setValue(newValue)
    onChange(newValue)
    updateBackground(newValue)
  }

  const updateBackground = (value: number) => {
    const percentage = ((value - min) / (max - min)) * 100
    const slider = document.querySelector(
      `.${styles.verticalSlider}`,
    ) as HTMLElement
    slider.style.setProperty('--value', `${percentage}%`)
  }

  useEffect(() => {
    setValue(RangeValue)
    updateBackground(RangeValue)
  }, [RangeValue])

  return (
    <div className={styles.verticalSliderContainer}>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className={styles.verticalSlider}
      />
    </div>
  )
}

export default RangeBar
