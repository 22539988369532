import { useLocation } from 'react-router-dom'
import styles from './style.module.scss'
import { constants } from '../../../../constants'
import { getRemainingDays } from '../../../../utils/date'
import { LetterDetailType } from '../../../../type/letter'
import { LoggedInUser } from '../../../../type/account'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { handleResize } from '../../../../utils/domEvent'
import { customContainerStyle, customContainerTextStyle } from './functions'

const { LOCATION } = constants

type LetterCardBodyType = {
  letter: LetterDetailType
  loggedInUser: LoggedInUser | null
  showReplyBtn: boolean
  cancelEvent?: () => void
  switchAddPeople: (senderId: number) => void
}

function LetterCardBody({ letter, loggedInUser }: LetterCardBodyType) {
  const { t } = useTranslation()
  const pathname = useLocation().pathname
  const arrivalDate = new Date(letter.arrivalDate)
  const nowDate = new Date()
  const remainingDays = getRemainingDays(arrivalDate, nowDate)
  const containerWrapRef = useRef<HTMLDivElement>(null) // Ref 생성
  const [containerWrapWidth, setContainerWrapWidth] = useState(0)
  const [isTextVisible, setIsTextVisible] = useState(true) // 클릭 상태 변수 추가

  // 클릭 이벤트 핸들러 작성
  const handleTextToggle = () => {
    if (letter.images[0]) {
      setIsTextVisible(!isTextVisible) // 클릭 상태를 토글
    }
  }

  useEffect(() => {
    handleResize(containerWrapRef, setContainerWrapWidth) // 초기 렌더링 시 가로 길이 측정
    window.addEventListener('resize', () => handleResize) // 리사이징 감지

    return () => {
      window.removeEventListener('resize', () => handleResize) // 컴포넌트 언마운트 시 이벤트 리스너 제거
    }
  }, [])

  // 로그인을 하지 않았다면 본문 내용을 안보여주고 로그인 버튼을 표시한다
  const showCoverMsg = () => {
    if (
      pathname === LOCATION.PATH.RECEIVING_TRAY ||
      (pathname === LOCATION.PATH.HOME &&
        remainingDays > 0 &&
        loggedInUser?.id === letter.recipient.id)
    ) {
      return (
        <div className={styles.container_wrap_mask}>
          {t(`ui.molecules.letter-card.label.not-arrived-msg`)}
        </div>
      )
    }
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.container_wrap}
        ref={containerWrapRef} // Ref를 요소에 할당
        style={customContainerStyle(letter, containerWrapWidth)}
        onClick={handleTextToggle} // 클릭 이벤트 핸들러 할당
      >
        {isTextVisible && (
          <div
            className={styles.container_wrap_text}
            style={customContainerTextStyle(letter)}
          >
            <div className={styles.container_wrap_text_desc}>
              <span>To {letter.recipient.name}</span>
              <p>{letter.message}</p>
            </div>
            {letter.images[0] ? (
              <div className={styles.container_wrap_text_click}>
                <i className="fa-regular fa-hand-pointer"></i>
              </div>
            ) : (
              ''
            )}
          </div>
        )}

        {/* 받은 편지함이면서 편지가 아직 도착하지 않았다면 편지 내용을 볼수 없게 한다 */}
        {showCoverMsg()}
      </div>
    </div>
  )
}

export default LetterCardBody
