import styles from './style.module.scss'
import backgroundImg from '../../../media/img/login_background.jpeg'
import Button from '../../UI/atoms/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { constants } from '../../../constants'

const { LOCATION } = constants

export const imgStyle = {
  background: `url(${backgroundImg})`,
  backgroundSize: 'cover',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  filter: 'blur(14px)',
  backdropFilter: 'blur(14px)',
  WebkitBackdropFilter: 'blur(14px)',
  WebkitFilter: 'blur(10px)',
  transform: 'scale(1.07)',
} as const

function ContactUsComplete() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const sendToSlack = async () => {
    navigate(LOCATION.PATH.HOME)
  }

  return (
    <div className={styles.container}>
      <div style={imgStyle}></div>

      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_form}>
          <div className={styles.container_wrap_form_title}>
            {t(`page.contact-us.title`)}
          </div>
          <div className={styles.container_wrap_form_desc}>
            <span>{t(`page.contact-us.label.complete-description`)}</span>
          </div>
          <Button
            btnName={t(`ui.atom.button.home`)}
            disabled={false}
            clickEvent={sendToSlack}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactUsComplete
