import styles from './style.module.scss'
import React, { useState } from 'react'

type InputType = {
  type: 'email' | 'number' | 'password' | 'text'
  placeholder?: string
  onChange?: (e: string) => void
  value?: string
  disabled?: boolean
  required?: boolean
  min?: number
  maxLength?: number
}

function TextInput({
  type,
  placeholder,
  onChange,
  value,
  disabled,
  required,
  min,
  maxLength,
}: InputType) {
  const [text, setText] = useState('')
  const inputValue = () => {
    if (value) return value
    return text
  }

  // maxLength가 있을 때만 입력 글자 수 제한을 한다
  function numberMaxLength(e: React.FormEvent<HTMLInputElement>) {
    const target = e.currentTarget
    if (!maxLength) return
    if (target.value.length > target.maxLength) {
      target.value = target.value.slice(0, target.maxLength)
    }
  }
  return (
    <div className={styles.container}>
      <input
        type={type}
        placeholder={placeholder ? placeholder : ''}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value)
          }
          setText(e.target.value)
        }}
        onInput={(e) => numberMaxLength(e)}
        value={inputValue()}
        min={min}
        maxLength={maxLength}
        disabled={disabled}
        required={required}
      />
    </div>
  )
}

export default TextInput
