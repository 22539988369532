import { LoggedInUser } from '../../type/account'

/**
 * 현재 유저가 구독 상태인지 아닌지를 확인한다
 * @param loggedInUser
 * @returns true: 구독중 false: 미구독
 */
export const checkSubscriptionNow = (loggedInUser: LoggedInUser | null) => {
  // 유저 정보가 없다면 false
  if (!loggedInUser) return false
  const now = new Date()
  const membershipCancelAt = new Date(loggedInUser.membershipCancelAt)

  // membershipStartAt이 없다면 애당초 구독을 하지 않은 상태임으로 false를 리턴한다
  if (!loggedInUser.membershipStartAt) {
    return false
  }

  // 구독을 종료하면 반드시 구독 종료일이 있기 때문에 구독 시작일은 있으나 구독 종료일은 없는 경우 구독중인 것으로 판단하여 true를 리턴한다
  if (!loggedInUser.membershipCancelAt) {
    return true
  }

  // 구독 종료일이 현재보다 크거나 같다면 구독취소를 하고 아직 시간이 남은 상태이기 때문에 true를 리턴한다
  if (now <= membershipCancelAt) {
    return true
  }

  // 구독 종료일이 현재보다 작다면 이미 구독 기간이 끝난 상태이기 때문에 false를 리턴한다
  if (now > membershipCancelAt) {
    return false
  }

  // 그 위의 조건은 무조건 false를 리턴해서 구독하지 않은 상태라고 알린다
  return false
}
