import { QRCodeCanvas } from 'qrcode.react'
import styles from './style.module.scss'
import logoImage from '../../../../media/img/heartter_icon.png'
import { constants } from '../../../../constants'
import { LoggedInUser } from '../../../../type/account'
import { useContext } from 'react'
import { Context } from '../../../../Root'

const { PATH } = constants.LOCATION

type MyQRCodeType = {
  loggedInUser: LoggedInUser | null
  QRCodeToken: string
}

function MyQRCode({ loggedInUser, QRCodeToken }: MyQRCodeType) {
  const { reactEnv } = useContext(Context)
  const baseUrl = reactEnv.baseUrl
  const url = `${baseUrl}${PATH.ADD_FRIENDS}?id=${loggedInUser?.id}`
  // QR코드를 30분 단위로 변경하고 싶을 때는 하기의 코드를 사용한다
  // const url = `${baseUrl}${PATH.ADD_FRIENDS}?id=${loggedInUser?.id}&token=${QRCodeToken}`
  return (
    <div className={styles.container}>
      <div className={styles.container_qr}>
        <QRCodeCanvas
          value={url}
          size={256}
          bgColor={'#FFF'}
          fgColor={'#000'}
          level={'L'}
          includeMargin={true}
          imageSettings={{
            src: logoImage,
            x: undefined,
            y: undefined,
            height: 40,
            width: 40,
            excavate: true,
          }}
        />
      </div>
    </div>
  )
}

export default MyQRCode
