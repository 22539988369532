import axios, { AxiosError } from 'axios'
import {
  AccountInfo,
  ApiError,
  LoginInfo,
  LoggedInUser,
  EditAccountInfo,
  DuplicateEmailCheck,
  TermsOfUseResponse,
} from '../../type/account'
import { defineErrorHandler } from '../../plugins/AxiosPlugin'
import { NavigateFunction } from 'react-router-dom'
import { constants } from '../../constants'
import { SetterOrUpdater } from 'recoil'
import { defaultAccount } from '../../store/account'
import { API_STATUS } from '../../constants/apiStatus'
import { Dispatch, SetStateAction } from 'react'
import { TFunction } from 'i18next'

const { LOCATION, API_URL } = constants

/**
 * 계정등록
 * @param account
 */
export const postRegistAccount = async (
  t: TFunction<'translation', undefined>,
  navigate: NavigateFunction,
  account: AccountInfo,
  setAccount: SetterOrUpdater<AccountInfo>,
) => {
  await axios
    .post(API_URL.PATH.REGIST_ACCOUNT, account)
    .then(() => {
      // 계정등록이 성공하면 프론트에서 가지고 있는 입력정보를 삭제
      initAccount(setAccount)
      navigate(LOCATION.PATH.REGIST_COMPLETE)
      return
    })
    .catch((err) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 로그인 체크
 * @param setLoggedInUser
 * @param setIsApiCallComplete
 */
export const fetchCheckLogin = async (
  t: TFunction<'translation', undefined>,
  setLoggedInUser: React.Dispatch<React.SetStateAction<null | LoggedInUser>>,
  setIsApiCallComplete: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setIsApiCallComplete(false)
  await axios
    .get(API_URL.PATH.LOGIN_CHECK)
    .then(({ data }) => {
      setLoggedInUser(data)
      setIsApiCallComplete(true)
    })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) return
      const errConstant = defineErrorHandler(errorStatus, t)
      setIsApiCallComplete(true)
      alert(errConstant)
      return
    })
}

/**
 * 로그인
 * @param loginInfo
 */
export const fetchLogin = async (
  t: TFunction<'translation', undefined>,
  loginInfo: LoginInfo,
) => {
  await axios
    .post<LoginInfo>(API_URL.PATH.LOGIN, loginInfo)
    .then(() => {
      window.location.href = LOCATION.PATH.HOME
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        alert(t(`api-error-msg.log-in-failed`))
        return
      }
      if (errorStatus === API_STATUS.LOCKED) {
        alert(t(`page.cancel-membership.warning`))
        return
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 로그아웃
 */
export const fetchLogout = async (
  t: TFunction<'translation', undefined>,
  isCancelMembership?: boolean,
) => {
  await axios
    .post(API_URL.PATH.LOGOUT)
    .then(() => {
      if (!isCancelMembership) {
        window.location.href = LOCATION.PATH.HOME
      }
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

// Reset input information
const initAccount = (setAccount: SetterOrUpdater<AccountInfo>) => {
  setAccount((preveState) => {
    return Object.assign({ ...preveState }, defaultAccount)
  })
}

/**
 * 회원정보 수정
 * @param account
 */
export const updateAccount = async (
  t: TFunction<'translation', undefined>,
  account: EditAccountInfo,
) => {
  await axios
    .post(API_URL.PATH.UPDATE_ACCOUNT, account)
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 프로필 이미지 갱신
 * @param t
 * @param account
 */
export const updateAvatar = async (
  t: TFunction<'translation', undefined>,
  avatarFile: File,
) => {
  const formData = new FormData()
  formData.append('avatarFile', avatarFile) // 백엔드의 upload.single("avatarFile") 인수명과 일치해야함
  await axios
    .post(API_URL.PATH.UPDATE_AVATAR, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 파일 전송을 위해 Content-Type을 설정
      },
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 비밀번로 변경시 입력한 기존 비밀번호가 일치하는지 확인
 * @param oldPassword
 */
export const checkPassword = async (
  password: string,
  setHasOldPasswordNoMatch: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >,
  t: TFunction<'translation', undefined>,
) => {
  return await axios
    .post(API_URL.PATH.CHECKING_PASSWORD, { password })
    .then(() => {
      return true
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNPROCESSABLE_ENTITY) {
        setHasOldPasswordNoMatch({ isError: true })
        return false
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return false
    })
}

/**
 * 비밀번호 변경
 * @param t
 * @param password
 * @param navigate
 */
export const changePassword = async (
  t: TFunction<'translation', undefined>,
  password: string,
  navigate: NavigateFunction,
) => {
  await axios
    .post(API_URL.PATH.PASSWORD_CHANGE, { password })
    .then(() => {
      alert(t(`page.password-change.alert.success`))
      navigate(LOCATION.PATH.MY_PAGE)
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 회원가입시 이메일 중복체크 하기
 * @param email
 */
export const checkDuplicateEmail = async (
  t: TFunction<'translation', undefined>,
  email: string,
): Promise<DuplicateEmailCheck> => {
  try {
    const { data } = await axios.post<DuplicateEmailCheck>(
      API_URL.PATH.DUPLICATE_EMAIL,
      { email },
    )
    return data
  } catch (error) {
    const err = error as AxiosError
    const errorStatus = err.response?.status as number
    const errConstant = defineErrorHandler(errorStatus, t)
    alert(errConstant)
    throw new Error('Error checking duplicate email')
  }
}

/**
 * 인증번호 발송API
 * @returns
 */
export const sendAuthenticationCode = async (
  t: TFunction<'translation', undefined>,
  email: AccountInfo['email'],
) => {
  return await axios
    .post(API_URL.PATH.AUTHENTICATION_CODE, { email })
    .then(({ data }) => data)
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 인증번호 체크하기
 * @param authenticationCode
 */
export const checkAuthenticationCode = async (
  t: TFunction<'translation', undefined>,
  authenticationCode: string,
) => {
  return await axios
    .get(API_URL.PATH.AUTHENTICATION_CODE, { params: { authenticationCode } })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 리셋용 Email보내기
 * @param email
 */
export const sendResetPasswordEmail = async (
  t: TFunction<'translation', undefined>,
  email: string,
  navigate: NavigateFunction,
) => {
  await axios
    .post(API_URL.PATH.SEND_RESET_PASSWORD_MAIL, { params: { email } })
    .then(() => {
      alert(t(`page.reset-your-password.alert-msg.success`))
      navigate(LOCATION.PATH.LOGIN)
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNPROCESSABLE_ENTITY) {
        alert(t(`page.reset-your-password.alert-msg.not-email`))
        return
      }
      if (errorStatus === API_STATUS.LOCKED) {
        alert(t(`page.cancel-membership.warning`))
        return
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 비밀번호 리셋 화면에서 토큰이 유효한지 확인하는 API
 * @param token
 */
export const checkResetToken = async (
  t: TFunction<'translation', undefined>,
  token: string,
  navigate: NavigateFunction,
) => {
  await axios
    .get(API_URL.PATH.CHECK_RESET_TOKEN, { params: { token } })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNPROCESSABLE_ENTITY) {
        alert(t(`page.password-reset.alert-msg.not-token`))
        navigate(LOCATION.PATH.LOGIN)
        return
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 비밀번호 리셋
 * @param t
 * @param password
 * @param navigate
 */
export const resetPassword = async (
  t: TFunction<'translation', undefined>,
  password: string,
  navigate: NavigateFunction,
) => {
  await axios
    .post(API_URL.PATH.RESET_PASSWORD, { password })
    .then(() => {
      alert(t(`page.password-change.alert.success`))
      navigate(LOCATION.PATH.LOGIN)
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 회원탈퇴하기
 * @param t
 * @param navigate
 */
export const postCancelMembership = async (
  t: TFunction<'translation', undefined>,
  navigate: NavigateFunction,
) => {
  await axios
    .post(API_URL.PATH.CANCEL_MEMBERSHIP)
    .then(() => {
      navigate(LOCATION.PATH.CANCEL_MEMBERSHIP_COMPLETE)
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 이용규약의 취득(한국어, 일본어, 영어)
 * @param t
 */
export const fetchTermsOfUse = async (
  t: TFunction<'translation', undefined>,
  setTermsOfUse: Dispatch<SetStateAction<TermsOfUseResponse['termsOfUse']>>,
) => {
  await axios
    .get<TermsOfUseResponse>(API_URL.PATH.TERMS_OF_USE)
    .then(({ data }) => {
      setTermsOfUse(data.termsOfUse)
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}
