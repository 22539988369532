import styles from './style.module.scss'

function LoadingSpinner() {
  return (
    <div className={styles.container}>
      <div className={styles.spinner}>
        <div className={styles.loadingText}>Loading...</div>
      </div>
    </div>
  )
}

export default LoadingSpinner
