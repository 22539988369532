import styles from './style.module.scss'

function SpecifiedCommercialTransactionAct() {
  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        <h3>特定商取引法に基づく表記</h3>
      </div>

      <div className={styles.container_body}>
        <div className={styles.container_body_section}>
          <p>販売業社の名称</p>
          <span>Heartter</span>
        </div>
        <div className={styles.container_body_section}>
          <p>所在地</p>
          <span>
            〒174-0072
            <br />
            東京都板橋区南常盤台1-11-6 レフア南常盤台101号室
          </span>
        </div>
        <div className={styles.container_body_section}>
          <p>メールアドレス</p>
          <span>hearttercompany@gmail.com</span>
        </div>
        <div className={styles.container_body_section}>
          <p>電話番号</p>
          <span>請求があったら遅滞なく開示します。</span>
        </div>
        <div className={styles.container_body_section}>
          <p>運営統括責任者</p>
          <span>朴管城</span>
        </div>
        <div className={styles.container_body_section}>
          <p>販売価格</p>
          <span>
            一般会員は無料。
            <br />
            Membership会員は月300円（サブスクリプション）
          </span>
        </div>
        <div className={styles.container_body_section}>
          <p>追加手数料等の追加料金</p>
          <span>なし</span>
        </div>
        <div className={styles.container_body_section}>
          <p>支払い方法</p>
          <span>クレジットカード</span>
        </div>
        <div className={styles.container_body_section}>
          <p>決済期間</p>
          <span>
            一般会員は無料。クレジットカード決済の場合はただちに処理され、翌月から自動引き落としとなります。
          </span>
        </div>
        <div className={styles.container_body_section}>
          <p>交換および返品（返金ポリシー）</p>
          <span>
            決済後は基本返金は受け付けておりません。なお、サブスクリプションのキャンセルを行いますと、直ちにサブスクリプションが停止され、翌月の支払い分が最後の支払いとなります。
          </span>
        </div>
        <div className={styles.container_body_section}>
          <p>引渡時期</p>
          <span>決済後、直ちにサービスのご利用が可能です。</span>
        </div>
      </div>
    </div>
  )
}

export default SpecifiedCommercialTransactionAct
