import styles from './style.module.scss'

type LabelType = {
  text: string
  type: 'red' | 'white'
}

const redStyle = {
  border: '1px solid #fff',
  backgroundColor: '#ff4b7e',
  color: '#fff',
}

const whiteStyle = {
  border: '1px solid #ff4b7e',
  backgroundColor: '#fff',
  color: '#ff4b7e',
}

function Label({ text, type }: LabelType) {
  const customStyle = type === 'red' ? redStyle : whiteStyle
  return (
    <div className={styles.label} style={customStyle}>
      {text}
    </div>
  )
}

export default Label
