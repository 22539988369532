import axios from 'axios'
import { constants } from '../../constants'
import { defineErrorHandler } from '../../plugins/AxiosPlugin'
import { API_STATUS } from '../../constants/apiStatus'
import {
  AvatarUrl,
  LetterDetailType,
  LetterRequest,
  LettersResponse,
  ReplyLetterRequest,
} from '../../type/letter'
import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { ApiError } from '../../type/account'

const { LOCATION, API_URL } = constants

/**
 * 신규 편지쓰기
 * @param letterRequest
 */
export const postRegistLetter = async (
  t: TFunction<'translation', undefined>,
  letterRequest: LetterRequest,
  backgroundFile: File | null,
) => {
  try {
    const formData = new FormData()
    formData.append('backgroundFile', backgroundFile ?? '') // 백엔드의 upload.single("backgroundFile") 인수명과 일치해야함
    formData.append('message', letterRequest.message?.toString() ?? '') // message 추가
    formData.append(
      'selectedLetterIconId',
      letterRequest.selectedLetterIconId?.toString() ?? '',
    ) // selectedLetterIconId 추가
    formData.append(
      'selectedLetterType',
      letterRequest.selectedLetterType.toString(),
    ) // selectedLetterType 추가
    formData.append(
      'selectedReceiveUserId',
      letterRequest.selectedReceiveUserId.toString(),
    ) // selectedReceiveUserId 추가
    const response = await axios.post<LetterRequest, { data: { id: number } }>(
      API_URL.PATH.REGIST_LETTER,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // 파일 전송을 위해 Content-Type을 설정
        },
      },
    )
    const { id } = response.data
    window.location.href = `/?id=${id}`
  } catch (err) {
    const apiError = err as ApiError // 에러 캐스팅
    const errorStatus = apiError.response?.status
    if (errorStatus === API_STATUS.UNAUTHORIZED) {
      window.location.href = LOCATION.PATH.LOGIN
    }
    const errConstant = defineErrorHandler(errorStatus, t)
    alert(errConstant)
    return null
  }
}

/**
 * 탑 페이지에 보이는 편지를 취득
 */
export const fetchLetters = async (
  t: TFunction<'translation', undefined>,
  setLetters: React.Dispatch<React.SetStateAction<LettersResponse>>,
  setIsCallApi: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  await axios
    .get<LettersResponse>(API_URL.PATH.FETCH_LETTERS)
    .then(({ data }) => {
      setLetters(data)
      setIsCallApi(true)
      return
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 받은 편지함 취득하기
 * @param userId
 * @param setReceivingLetters
 */
export const fetchReceivingLetters = async (
  t: TFunction<'translation', undefined>,
  userId: number,
  setReceivingLetters: React.Dispatch<
    React.SetStateAction<LettersResponse | null>
  >,
) => {
  const apiUrl = API_URL.PATH.RECEIVING_LETTERS.replace(
    ':loggedInUserId',
    String(userId),
  )
  await axios
    .get<LettersResponse>(apiUrl)
    .then(({ data }) => {
      setReceivingLetters(data)
      return
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 보낸 편지함 취득하기
 * @param userId
 * @param setSendLetters
 */
export const fetchSendLetters = async (
  t: TFunction<'translation', undefined>,
  userId: number,
  setSendLetters: React.Dispatch<React.SetStateAction<LettersResponse | null>>,
) => {
  const apiUrl = API_URL.PATH.SEND_LETTERS.replace(
    ':loggedInUserId',
    String(userId),
  )
  await axios
    .get<LettersResponse>(apiUrl)
    .then(({ data }) => {
      setSendLetters(data)
      return
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

// 편지를 클릭했을 때, 혹은 URL을 직접 때렸을 때 호출
export const fetchLetter = async (
  t: TFunction<'translation', undefined>,
  letterId: number,
  setLetterDetail: React.Dispatch<
    React.SetStateAction<LetterDetailType | null>
  >,
) => {
  await axios
    .get<LetterDetailType>(API_URL.PATH.LETTER_DETAIL, {
      params: {
        letterId,
      },
    })
    .then(({ data }) => {
      setLetterDetail(data)
      return
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

// 편지를 클릭했을 때, 혹은 URL을 직접 때렸을 때 letter의 views를 갱싱하는 API
export const putUpdateLetterViews = async (
  t: TFunction<'translation', undefined>,
  letterId: number,
) => {
  await axios
    .put(API_URL.PATH.LETTER_VIEWS_UPDATE, {
      params: {
        letterId,
      },
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.FAILED_DEPENDENCY) {
        alert(t(`api-error-msg.arrived-letter-error`))
        window.location.href = LOCATION.PATH.HOME
        return
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 답장 편지쓰기
 * @param replyLetterRequest 등록할 편지 정보
 */
export const postReplyLetter = async (
  t: TFunction<'translation', undefined>,
  replyLetterRequest: ReplyLetterRequest,
) => {
  await axios
    .post<ReplyLetterRequest, { data: { id: number } }>(
      API_URL.PATH.REPLY_LETTER,
      replyLetterRequest,
    )
    .then(({ data }) => {
      window.location.href = `/?id=${data.id}`
    })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        window.location.href = LOCATION.PATH.LOGIN
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 프로필 이미지 취득
 * @param t
 * @param id userId
 * @returns 프로필 이미지 URL
 */
export const fetchAvatar = async (
  t: TFunction<'translation', undefined>,
  id: number,
  setSenderAvatarUrl: Dispatch<SetStateAction<string>>,
) => {
  return await axios
    .get<AvatarUrl>(API_URL.PATH.AVATAR, {
      params: { id },
    })
    .then(({ data }) => {
      setSenderAvatarUrl(data.avatarUrl)
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 댓글 등록
 * @param t
 * @param letterId
 * @param comment
 * @returns
 */
export const postComment = async (
  t: TFunction<'translation', undefined>,
  letterId: number,
  comment: string,
) => {
  await axios
    .post<AvatarUrl>(API_URL.PATH.COMMENT, {
      params: { letterId, comment },
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 댓글 좋아요 업데이트
 * @param t
 * @param letterId
 * @param commentId
 */
export const postCommentPavorite = async (
  t: TFunction<'translation', undefined>,
  letterId: number,
  commentId: number,
) => {
  await axios
    .post<AvatarUrl>(API_URL.PATH.COMMENT_FAVORITE, {
      params: { letterId, commentId },
    })
    .catch((err) => {
      if (!err.response?.status) return err
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}
