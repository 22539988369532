import { useEffect, useRef } from 'react'
import style from './style.module.scss'
import Button from '../../../UI/atoms/Button'
import { useNavigate } from 'react-router-dom'
import { constants } from '../../../../constants'
import i18n from '../../../../locales/i18n'
import { useTranslation } from 'react-i18next'
import { scrollToFirst } from '../../../../utils/domEvent'

const { LOCATION } = constants

export function RegistComplete() {
  const { t } = useTranslation()
  const videoTag = useRef<HTMLVideoElement>(null)
  const navigate = useNavigate()
  const playVideo = () => {
    return (
      <video
        src={require('../../../../media/videos/video-1.mp4')}
        ref={videoTag}
        autoPlay={true}
        controls={false}
        loop={true}
        muted={true}
        playsInline={true}
      />
    )
  }

  const goTologin = () => {
    navigate(LOCATION.PATH.LOGIN)
  }

  useEffect(() => {
    // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
    // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
    const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
    const lang = localStorage.getItem('language') ?? localeLanguege
    i18n.changeLanguage(lang)

    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [])

  return (
    <div className={style.container}>
      <div className={style.container_body}>
        <div className={style.container_body_wrap}>
          <p>{t(`page.regist-complete.page-name`)}</p>
          <div className={style.container_body_wrap_btn}>
            <Button
              btnName={t(`ui.atom.button.login`)}
              disabled={false}
              clickEvent={goTologin}
            />
          </div>
        </div>
      </div>
      <div className={style.background_video}>{playVideo()}</div>
    </div>
  )
}
export default RegistComplete
