import { useRef, useEffect, useState, useContext } from 'react'
import * as maptilersdk from '@maptiler/sdk'
import '@maptiler/sdk/dist/maptiler-sdk.css'
import styles from './style.module.scss'
import { customMapEffect } from './function'
import { Context } from '../../../../Root'
import { LettersResponse, LetterDetailType } from '../../../../type/letter'
import RangeBar from '../../atoms/RangeBar/index'

type MainMap = {
  letters: LettersResponse
  clickLetter: (letter: LetterDetailType) => void
}

function MainMap({ letters, clickLetter }: MainMap) {
  const mapContainer = useRef<HTMLDivElement>(null)
  const defaultCoordinate = { lng: 133.753, lat: 36.6844 }
  const map = useRef<maptilersdk.Map | null>(null)
  const { loggedInUser, reactEnv } = useContext(Context)
  const [RangeValue, setRangeValue] = useState<number>(5)

  const payload = {
    defaultCoordinate,
    mapContainer,
    RangeValue,
    map,
    loggedInUser,
    letters,
    reactEnv,
    clickLetter,
  }

  let vh = 0

  useEffect(() => {
    vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  useEffect(() => {
    customMapEffect(payload)
  }, [RangeValue])

  const handleRangeChange = (value: number) => {
    setRangeValue(value)
  }

  return (
    <div>
      <div className={styles.map_wrap}>
        <div ref={mapContainer} className={styles.map} />
        <div className={styles.range_bar}>
          <RangeBar
            min={0}
            max={20}
            step={0.1}
            onChange={handleRangeChange}
            RangeValue={RangeValue}
          />
        </div>
      </div>
    </div>
  )
}

export default MainMap
