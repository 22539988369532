import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import { handleImageChange, nextStep, showNoInputErr } from './functions'
import { useTranslation } from 'react-i18next'
import { SetterOrUpdater } from 'recoil'
import { clickModalOutSide } from '../../../../utils/domEvent'
import CreateLetterBody from '../../molecules/CreateLetterBody'
import ProgressBar from '../../atoms/ProgressBar'
import SelecteLetterIcons from '../SelecteLetterIcons'
import { LoggedInUser } from '../../../../type/account'

type RegistLetterFirstStepModalType = {
  loggedInUser: LoggedInUser | null
  noInputErr: boolean
  cancelEvent: () => void
  registLetter: string
  setRegistLetter: SetterOrUpdater<string>
  setBackgroundFile: SetterOrUpdater<File | null>
  setIsFirstStep: Dispatch<SetStateAction<boolean>>
  compressedImageUrl: string
  setCompressedImageUrl: SetterOrUpdater<string>
  setNoInputErr: Dispatch<SetStateAction<boolean>>
  progress: number
  setProgress: Dispatch<SetStateAction<number>>
  selectedLetterIconId: number
  setSelectedLetterIconId: Dispatch<SetStateAction<number>>
}

function RegistLetterFirstStepModal({
  loggedInUser,
  noInputErr,
  cancelEvent,
  registLetter,
  setRegistLetter,
  setBackgroundFile,
  setIsFirstStep,
  compressedImageUrl,
  setCompressedImageUrl,
  setNoInputErr,
  progress,
  setProgress,
  selectedLetterIconId,
  setSelectedLetterIconId,
}: RegistLetterFirstStepModalType) {
  const { t } = useTranslation()
  const modalRef = useRef<HTMLDivElement>(null)
  const [mounted, setMounted] = useState(false)
  const [isImgTypeErr, setIsImgTypeErr] = useState(false)
  const [isImgSizeErr, setIsImgSizeErr] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [progress])

  return (
    <div
      className={`${styles.container}  ${mounted && styles.fade_in}`}
      onClick={(e) => clickModalOutSide(e, modalRef, cancelEvent)}
    >
      <div className={styles.container_window} ref={modalRef}>
        <div className={styles.container_window_title}>
          <div
            className={styles.container_window_title_left}
            onClick={cancelEvent}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
          <div className={styles.container_window_title_center}>
            {t(`page.top.modal.new-letter.title`)}
          </div>
          <div
            className={styles.container_window_title_right}
            onClick={() =>
              nextStep(registLetter, setNoInputErr, setIsFirstStep)
            }
          >
            {t(`ui.atom.button.next`)}
          </div>
        </div>
        <ProgressBar progress={progress} />
        <div className={styles.container_window_content}>
          <CreateLetterBody
            handleImageChange={handleImageChange}
            setRegistLetter={setRegistLetter}
            setBackgroundFile={setBackgroundFile}
            registLetter={registLetter}
            setIsImgTypeErr={setIsImgTypeErr}
            setIsImgSizeErr={setIsImgSizeErr}
            compressedImageUrl={compressedImageUrl}
            setCompressedImageUrl={setCompressedImageUrl}
            setProgress={setProgress}
          />
          <p>{t(`ui.organisms.regist-letter-modal.label.input-info`)}</p>
          <p>{t(`ui.organisms.regist-letter-modal.label.only-jpg`)}</p>
          <div className={styles.container_window_content_err}>
            {noInputErr ? showNoInputErr(t) : ''}
            {isImgTypeErr ? (
              <div className={styles.container_form_profile_img_inner_err}>
                {t(`page.my-page.error-msg.avatar-upload-type-err`)}
              </div>
            ) : (
              ''
            )}
            {isImgSizeErr ? (
              <div className={styles.container_form_profile_img_inner_err}>
                {t(`page.my-page.error-msg.avatar-upload-size-err`)}
              </div>
            ) : (
              ''
            )}
          </div>
          {loggedInUser ? (
            <SelecteLetterIcons
              loggedInUser={loggedInUser}
              selectedLetterIconId={selectedLetterIconId}
              setSelectedLetterIconId={setSelectedLetterIconId}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default RegistLetterFirstStepModal
