import { Dispatch, SetStateAction } from 'react'
import { EditAccountInfo } from '../../../type/account'
import {
  PaymentHistoryResponse,
  ProfileEditPayload,
} from '../../../type/mypage'
import { checkEmptyValues } from './validation'
import {
  fetchLogout,
  postCancelMembership,
  updateAccount,
  updateAvatar,
} from '../../../api/account'
import { NavigateFunction } from 'react-router-dom'
import { constants } from '../../../constants'
import { TFunction } from 'i18next'
import { postDeleteFriend } from '../../../api/friend'
import { postCancelSubscription } from '../../../api/payment'

const { LOCATION } = constants

// 수정 버튼을 눌렀을 시에 실행
export const handleUserEditEvent = (
  isUserProfileEdit: boolean,
  setIsUserProfileEdit: Dispatch<SetStateAction<boolean>>,
) => {
  setIsUserProfileEdit(!isUserProfileEdit)
}

// 취소 버튼을 눌렀을 시 실행
export const handleCancelEvent = (
  isUserProfileEdit: boolean,
  setIsUserProfileEdit: Dispatch<SetStateAction<boolean>>,
) => {
  setIsUserProfileEdit(!isUserProfileEdit)
}

// 저장하기 버튼을 눌렀을 때 실행
export const handleUserSaveEvent = async (
  account: EditAccountInfo,
  isUserProfileEdit: boolean,
  mapMarkerState: ProfileEditPayload['mapMarkerState'],
  setIsUserProfileEdit: Dispatch<SetStateAction<boolean>>,
  setAccount: ProfileEditPayload['setAccount'],
  setMapMarkerState: ProfileEditPayload['setMapMarkerState'],
  setNameState: ProfileEditPayload['setNameState'],
  avatarFile: File | null,
  t: TFunction<'translation', undefined>,
) => {
  const newLng = account.coordinate[0]
  const newLat = account.coordinate[1]
  setAccount((prevState) => {
    return Object.assign({ ...prevState }, { coordinate: [newLng, newLat] })
  })

  const isEmpty = checkEmptyValues(account, setMapMarkerState)
  let isValidationError = false
  if (!account.name) {
    setNameState((preveState) => {
      return Object.assign({ ...preveState }, { noName: true })
    })
    isValidationError = true
  }

  if (!mapMarkerState.noMoveMarker && !isValidationError && !isEmpty) {
    if (avatarFile) {
      await updateAvatar(t, avatarFile) // 프로필 사진 업데이트
    }

    await updateAccount(t, account)
    setIsUserProfileEdit(!isUserProfileEdit)
    window.location.href = LOCATION.PATH.MY_PAGE
  }
}

export const goToChangePassword = (navigate: NavigateFunction) => {
  navigate(LOCATION.PATH.CHANGE_PASSWORD)
}

/**
 * 마이페이지에서 탭을 클릭했을 시 실행
 * @param setSelectedTabId
 * @returns
 */
export const handleTab =
  (
    setSelectedTabId: Dispatch<SetStateAction<number>>,
    navigate: NavigateFunction,
  ) =>
  (tabId: number) => {
    setSelectedTabId(tabId)
    navigate(`${LOCATION.PATH.MY_PAGE}?tab-id=${tabId}`)
  }

/**
 * 친구를 삭제한다
 * @param t
 * @param id
 */
export const deleteFriend = async (
  t: TFunction<'translation', undefined>,
  friendId: number,
  reloadAccount: () => Promise<void>,
) => {
  await postDeleteFriend(t, friendId)
  await reloadAccount()
}

/**
 * 회원탈퇴하기
 */
export const cancelMembership = async (
  t: TFunction<'translation', undefined>,
  navigate: NavigateFunction,
) => {
  if (window.confirm(t(`page.cancel-membership.warning`))) {
    await postCancelMembership(t, navigate)
    await fetchLogout(t, true)
  }
  return
}

/**
 * 구독 취소하기 버튼을 클릭했을 시 실행
 * @param t
 * @returns
 */
export const cancelSubscription = async (
  t: TFunction<'translation', undefined>,
) => {
  if (window.confirm(t(`page.my-page.alert.cancel-membership`))) {
    await postCancelSubscription(t)
    alert(t(`page.my-page.alert.canceled-membership`))
    window.location.href = LOCATION.PATH.MY_PAGE
  }
  return
}
