import { Dispatch, SetStateAction } from 'react'
import { checkValidPassword } from '../../../../utils/validate'
import { changePassword, checkPassword } from '../../../../api/account'
import { NavigateFunction } from 'react-router-dom'
import { TFunction } from 'i18next'

type SubmitPayloadType = {
  t: TFunction<'translation', undefined>
  oldPassword: string
  newPassword: string
  newConfirmPassword: string
  setPasswordNoMatchErrorState: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >
  setPasswordState: Dispatch<
    SetStateAction<{
      noPassword: boolean
    }>
  >
  setInappropriatePasswordState: Dispatch<
    SetStateAction<{
      inappropriatePassword: boolean
    }>
  >
  setHasPasswordChanged: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >
  setHasOldPasswordNoMatch: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >
  navigate: NavigateFunction
}

/**
 * 기존 패스워드 입력시
 * @param setOldPassword
 * @returns
 */
export const handleOldPasswordEvent =
  (setOldPassword: Dispatch<SetStateAction<string>>) => (e: string) => {
    setOldPassword(e)
  }

/**
 * 새 패스워드 입력시
 * @param setNewPassword
 * @returns
 */
export const handleNewPasswordEvent =
  (setNewPassword: Dispatch<SetStateAction<string>>) => (e: string) => {
    setNewPassword(e)
  }

/**
 * 새 패스워드 확인용
 * @param setNewConfirmPassword
 * @returns
 */
export const handleNewConfirmPasswordEvent =
  (setNewConfirmPassword: Dispatch<SetStateAction<string>>) => (e: string) => {
    setNewConfirmPassword(e)
  }

/**
 * 변경하기 버튼을 눌렀을 시
 * @param oldPassword
 * @param newPassword
 * @param newConfirmPassword
 */
export const submitBtn = async (submitPayload: SubmitPayloadType) => {
  const {
    t,
    oldPassword,
    newPassword,
    newConfirmPassword,
    setPasswordNoMatchErrorState,
    setPasswordState,
    setInappropriatePasswordState,
    setHasPasswordChanged,
    setHasOldPasswordNoMatch,
    navigate,
  } = submitPayload
  initState(
    setPasswordNoMatchErrorState,
    setPasswordState,
    setInappropriatePasswordState,
    setHasPasswordChanged,
    setHasOldPasswordNoMatch,
  )

  // 패스워드 입력란이 공란인지 체크
  if (!oldPassword || !newPassword || !newConfirmPassword) {
    setPasswordState({ noPassword: true })
    return
  }

  // 기존 패스워드와 새로운 패스워드가 일치한다면 에러를 true로 변경
  if (oldPassword === newPassword) {
    setHasPasswordChanged({ isError: true })
    return
  }

  // 새로운 패스워드와 확인용 패스워드가 일치한지 체크
  if (newPassword !== newConfirmPassword) {
    setPasswordNoMatchErrorState({ isError: true })
    return
  }

  // 패스워드 형식 체크
  const isValidPassword = checkValidPassword(newPassword)
  if (!isValidPassword) {
    setInappropriatePasswordState({ inappropriatePassword: !isValidPassword })
    return
  }

  const isOldPasswordCheck = await checkPassword(
    oldPassword,
    setHasOldPasswordNoMatch,
    t,
  )
  if (isOldPasswordCheck) {
    await changePassword(t, newPassword, navigate)
  }
}

/**
 * state의 초기화
 * @param setPasswordNoMatchErrorState
 * @param setPasswordState
 * @param setInappropriatePasswordState
 */
const initState = (
  setPasswordNoMatchErrorState: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >,
  setPasswordState: Dispatch<
    SetStateAction<{
      noPassword: boolean
    }>
  >,
  setInappropriatePasswordState: Dispatch<
    SetStateAction<{
      inappropriatePassword: boolean
    }>
  >,
  sethasPasswordChanged: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >,
  setHasOldPasswordNoMatch: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >,
) => {
  setPasswordNoMatchErrorState({ isError: false })
  sethasPasswordChanged({ isError: false })
  setHasOldPasswordNoMatch({ isError: false })
  setPasswordState({ noPassword: false })
  setInappropriatePasswordState({ inappropriatePassword: false })
}
