import { Dispatch, SetStateAction } from 'react'

// 남은 시작을 시간 형식으로 변환하는 함수
export const formatTime = (time: number) => {
  const seconds = String(Math.floor((time % (1000 * 60)) / 1000)).padStart(
    2,
    '0',
  )
  const minutes = String(
    Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)),
  ).padStart(2, '0')

  return `${minutes}: ${seconds}`
}

/**
 * 남은시간 카운트다운하는 함수
 * @param setRemainingTime
 * @returns
 */
export const countDown = (
  startTime: number,
  setRemainingTime: Dispatch<SetStateAction<number>>,
  setAuthenticationTimeout: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const intervalId = setInterval(() => {
    const now = new Date().getTime()
    const remaining = startTime - now
    setAuthenticationTimeout(true)

    // 남은 시간이 음수가 되면 카운트다운 종료
    if (remaining < 0) {
      clearInterval(intervalId)
      setRemainingTime(0)
      setAuthenticationTimeout(false)
    } else {
      setRemainingTime(remaining)
    }
  }, 1000)
  return intervalId
}
