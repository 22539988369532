import { TFunction } from 'i18next'
import { ApiError } from '.'

/** 予期せぬサーバーエラー */
export class UnexpectedServerError extends ApiError {
  public readonly error: unknown

  constructor(t: TFunction<'translation', undefined>, error: unknown) {
    super('UnexpectedServerError', t(`api-error-msg.unexpected-server-error`))
    this.error = error
  }
}
