import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { ReactEnv } from '../../type/react'
import axios from 'axios'
import { constants } from '../../constants'
import { ApiError } from '../../type/account'
import { defineErrorHandler } from '../../plugins/AxiosPlugin'

const { API_URL } = constants

/**
 * env 파일을 취득 (MAPTILER_API_KEY & domain)
 * @param t
 * @param setReactEnv
 */
export const fetchReactEnv = async (
  t: TFunction<'translation', undefined>,
  setReactEnv: Dispatch<SetStateAction<ReactEnv>>,
) => {
  await axios
    .get<ReactEnv>(API_URL.PATH.REACT_ENV)
    .then(({ data }) => {
      setReactEnv(data)
    })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}
