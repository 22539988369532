import styles from './style.module.scss'
import { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import heartterMail from '../../../media/img/heartter_main.png'
import letterWriting from '../../../media/img/letter_writing.jpg'
import expressMail from '../../../media/img/express_mail.png'
import dearLetter from '../../../media/img/dear_letter.png'
import noticeable from '../../../media/img/noticeable.png'

import { Elements } from '@stripe/react-stripe-js'
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import CheckoutForm from '../../UI/molecules/CheckoutForm'
import { Context } from '../../../Root'

function Store() {
  const { t } = useTranslation()
  const { reactEnv } = useContext(Context)

  const stripePromise = loadStripe(reactEnv.stripePublicKey)

  const background1 = useRef<HTMLDivElement>(null)
  const backgroundImage1 = useRef<HTMLDivElement>(null)
  const background2 = useRef<HTMLDivElement>(null)
  const backgroundImage2 = useRef<HTMLDivElement>(null)
  const background3 = useRef<HTMLDivElement>(null)
  const backgroundImage3 = useRef<HTMLDivElement>(null)
  const background4 = useRef<HTMLDivElement>(null)
  const backgroundImage4 = useRef<HTMLDivElement>(null)
  const background5 = useRef<HTMLDivElement>(null)
  const backgroundImage5 = useRef<HTMLDivElement>(null)

  const options: StripeElementsOptions = {
    mode: 'subscription',
    amount: 330,
    currency: 'jpy',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY

      if (
        background1.current &&
        background2.current &&
        background3.current &&
        background4.current &&
        background5.current &&
        backgroundImage1.current &&
        backgroundImage2.current &&
        backgroundImage5.current
      ) {
        // 배경 및 텍스트 애니메이션 처리
        if (scrollPosition + 300 < window.innerHeight) {
          background1.current.style.opacity = '1'
          background2.current.style.opacity = '0'
          background3.current.style.opacity = '0'
          background4.current.style.opacity = '0'
          background5.current.style.opacity = '0'

          backgroundImage1.current.style.opacity = `${0.5 - scrollPosition / window.innerHeight}`
          backgroundImage2.current.style.opacity = '1'
        } else if (
          scrollPosition >= window.innerHeight &&
          scrollPosition < window.innerHeight * 2
        ) {
          background1.current.style.opacity = '1'
          background2.current.style.opacity = '1'
          background3.current.style.opacity = '0'
          background4.current.style.opacity = '0'
          background5.current.style.opacity = '0'

          backgroundImage1.current.style.opacity = '0'
          backgroundImage2.current.style.opacity = `${2 - scrollPosition / window.innerHeight}`
        } else if (
          scrollPosition >= window.innerHeight &&
          scrollPosition < window.innerHeight * 3
        ) {
          background1.current.style.opacity = '1'
          background2.current.style.opacity = '1'
          background3.current.style.opacity = '1'
          background4.current.style.opacity = '0'
          background5.current.style.opacity = '0'

          backgroundImage1.current.style.opacity = '0'
          backgroundImage2.current.style.opacity = '0'
        } else if (
          scrollPosition >= window.innerHeight &&
          scrollPosition < window.innerHeight * 4
        ) {
          background1.current.style.opacity = '1'
          background2.current.style.opacity = '1'
          background3.current.style.opacity = '1'
          background4.current.style.opacity = '1'
          background5.current.style.opacity = '0'

          backgroundImage1.current.style.opacity = '0'
          backgroundImage2.current.style.opacity = '0'
        } else {
          background1.current.style.opacity = '1'
          background2.current.style.opacity = '1'
          background3.current.style.opacity = '1'
          background4.current.style.opacity = '1'
          background5.current.style.opacity = '1'

          backgroundImage1.current.style.opacity = '0'
          backgroundImage2.current.style.opacity = '0'
          backgroundImage5.current.style.opacity = `${5 - scrollPosition / window.innerHeight}`
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={styles.landing_page}>
      <section ref={background1} className={styles.background_1}>
        <div
          ref={backgroundImage1}
          className={styles.background_image_1}
          style={{ backgroundImage: `url(${letterWriting})` }}
        ></div>
        <div className={styles.benefits}>
          <div className={styles.benefit}>
            <p className={styles.benefit_title_1}>
              {t(`page.register-membership.label.section1.month`)}
            </p>
            <p className={styles.benefit_title_2}>
              {t(`page.register-membership.label.section1.membership-benefits`)}
            </p>
            <div className={styles.benefit_btn}>
              <div className="button_container"></div>
            </div>
          </div>
        </div>
      </section>
      <section ref={background2} className={`${styles.background_2}`}>
        <div
          ref={backgroundImage2}
          className={styles.background_image_2}
          style={{ backgroundImage: `url(${expressMail})` }}
        ></div>

        <div className={styles.benefits}>
          <div className={styles.benefit}>
            <p className={styles.benefit_title_1}>
              {t(
                `page.register-membership.label.section2.unlimited-faster-letter`,
              )}
            </p>
            <p>{t(`page.register-membership.label.section2.random-speed`)}</p>
            <p>
              {t(
                `page.register-membership.label.section2.depending-on-the-distance`,
              )}
            </p>
            <p>
              {t(
                `page.register-membership.label.section2.itmay-take-up-to-15-years`,
              )}
            </p>
            <p>
              {t(
                `page.register-membership.label.section2.but-if-register-membership`,
              )}
            </p>
            <p>
              {t(
                `page.register-membership.label.section2.you-can-send-faster-letter`,
              )}
            </p>
          </div>
        </div>
      </section>
      <section
        ref={background3}
        className={`${styles.background_3} ${styles.animated_text}`}
      >
        <div
          ref={backgroundImage3}
          className={styles.background_image_3}
          style={{ backgroundImage: `url(${dearLetter})` }}
        ></div>
        <div className={styles.benefits}>
          <div className={styles.benefit}>
            <p className={styles.benefit_title_1}>
              {t(`page.register-membership.label.section3.select-receiver`)}
            </p>
            <p>
              {t(
                `page.register-membership.label.section3.you-can-select-receiver`,
              )}
            </p>
            <p>
              {t(
                `page.register-membership.label.section3.the-letter-has-showed-on-map`,
              )}
            </p>
          </div>
        </div>
      </section>
      <section
        ref={background4}
        className={`${styles.background_4} ${styles.animated_text}`}
      >
        <div
          ref={backgroundImage4}
          className={styles.background_image_4}
          style={{ backgroundImage: `url(${noticeable})` }}
        ></div>
        <div className={styles.benefits}>
          <div className={styles.benefit}>
            <p className={styles.benefit_title_1}>
              {t(`page.register-membership.label.section4.change-icon`)}
            </p>
            <p>
              {t(
                `page.register-membership.label.section4.if-you-can-change-letter-icon`,
              )}
            </p>
            <p>
              {t(
                `page.register-membership.label.section4.encourage-comments-from-more-people`,
              )}
            </p>
          </div>
        </div>
      </section>
      <section
        ref={background5}
        className={`${styles.background_5} ${styles.animated_text}`}
      >
        <div
          ref={backgroundImage5}
          className={styles.background_image_5}
          style={{ backgroundImage: `url(${heartterMail})` }}
        ></div>
        <div className={styles.benefits}>
          <div className={styles.benefit}>
            <p className={styles.benefit_center_text}>
              {' '}
              {t(`page.register-membership.label.section5.for-membership`)}
            </p>
            <p className={styles.benefit_center_text}>
              {t(
                `page.register-membership.label.section5.you-can-have-more-benefits`,
              )}
            </p>
            <div className={styles.benefit_bottom_btn}>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Store
