import { NavigateFunction } from 'react-router-dom'
import backgroundImg from '../../../../media/img/login_background.jpeg'
import { checkValidate } from '../../../../utils/validate'
import { TFunction } from 'i18next'
import { sendResetPasswordEmail } from '../../../../api/account'

export const imgStyle = {
  background: `url(${backgroundImg})`,
  backgroundSize: 'cover',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  filter: 'blur(14px)',
  backdropFilter: 'blur(14px)',
  WebkitBackdropFilter: 'blur(14px)',
  WebkitFilter: 'blur(10px)',
  transform: 'scale(1.07)',
} as const

export const updateEmail =
  (
    setEmailState: React.Dispatch<React.SetStateAction<string>>,
    setEmailErrorState: React.Dispatch<
      React.SetStateAction<{
        emailValidationErr: boolean
      }>
    >,
    setNoEnteredEmailState: React.Dispatch<
      React.SetStateAction<{
        noEmail: boolean
      }>
    >,
  ) =>
  (e: string) => {
    setNoEnteredEmailState({ noEmail: false })
    setEmailErrorState({ emailValidationErr: false })
    setEmailState(e)
  }

export const handleClickResetBtn = async (
  emailState: string,
  setEmailErrorState: React.Dispatch<
    React.SetStateAction<{
      emailValidationErr: boolean
    }>
  >,
  setNoEnteredEmailState: React.Dispatch<
    React.SetStateAction<{
      noEmail: boolean
    }>
  >,
  navigate: NavigateFunction,
  t: TFunction<'translation', undefined>,
) => {
  const isError = checkValidate(
    emailState,
    setEmailErrorState,
    setNoEnteredEmailState,
  )
  if (!isError) {
    await sendResetPasswordEmail(t, emailState, navigate)
  }
}
