import { TFunction } from 'i18next'
import { ApiError } from '.'

/** 予期せぬサーバーエラー */
export class UnprocessableEntityError extends ApiError {
  constructor(t: TFunction<'translation', undefined>) {
    super(
      'UnprocessableEntityError',
      t(`api-error-msg.unprocessed-able-entity-error`),
    )
  }
}
