import styles from './style.module.scss'
import Button from '../../../UI/atoms/Button'
import { useNavigate } from 'react-router-dom'
import { customStyle } from './style'
import { useTranslation } from 'react-i18next'
import { callApi, goToBack, goToNext } from './functions'
import { useEffect, useState } from 'react'
import CheckBox from '../../../UI/atoms/CheckBox'
import i18n from '../../../../locales/i18n'
import { scrollToFirst } from '../../../../utils/domEvent'
import { TermsOfUseResponse } from '../../../../type/account'

function TermsOfUse() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isChecked, setIsCehcked] = useState(false)
  const [calledApi, setCalledApi] = useState(false) // api call이 끝나면 true로 변경 됨
  const [language, setLanguage] = useState('')
  const [showTermsOfUse, setShowTermsOfUse] = useState('')
  const [termsOfUse, setTermsOfUse] = useState<
    TermsOfUseResponse['termsOfUse']
  >({
    ko: '',
    jp: '',
    en: '',
  })

  // 언어 변경하기
  const handleChangeLanguage: React.ChangeEventHandler<HTMLSelectElement> = (
    e,
  ) => {
    const lang = e.target.value
    i18n.changeLanguage(lang)
    localStorage.setItem('language', lang)
    setLanguage(lang)
    if (lang === 'ko') {
      setShowTermsOfUse(termsOfUse.ko)
    } else if (lang === 'jp') {
      setShowTermsOfUse(termsOfUse.jp)
    } else {
      setShowTermsOfUse(termsOfUse.en)
    }
  }

  useEffect(() => {
    // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
    // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
    const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
    const lang = localStorage.getItem('language') ?? localeLanguege

    setLanguage(lang)
    i18n.changeLanguage(lang)

    callApi(t, setTermsOfUse, setCalledApi)

    if (lang === 'ko') {
      setShowTermsOfUse(termsOfUse.ko)
    } else if (lang === 'jp') {
      setShowTermsOfUse(termsOfUse.jp)
    } else {
      setShowTermsOfUse(termsOfUse.en)
    }

    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [calledApi])

  return (
    <div className={styles.container}>
      <div style={customStyle}></div>
      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_form}>
          <div className={styles.container_wrap_form_title}>
            <h1>{t(`page.terms-of-use.page-name`)}</h1>
            <select
              className={styles.language}
              onChange={handleChangeLanguage}
              value={language}
            >
              <option value="ko">한국어</option>
              <option value="jp">日本語</option>
              <option value="en">English</option>
            </select>
          </div>
          <div
            className={styles.container_wrap_form_detail}
            dangerouslySetInnerHTML={{ __html: showTermsOfUse }}
          ></div>
          <CheckBox
            labelName={t(`ui.atom.check-box.trems-of-use`)}
            setIsCehcked={setIsCehcked}
            isChecked={isChecked}
          />
          <div className={styles.container_wrap_form_btn}>
            <Button
              btnName={t(`ui.atom.button.next`)}
              disabled={!isChecked}
              clickEvent={() => goToNext(navigate)}
            />
            <Button
              btnName={t(`ui.atom.button.back`)}
              disabled={false}
              type="white"
              clickEvent={() => goToBack(navigate)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse
