import { LetterDetailType } from '../../../../type/letter'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { goToLetterDetail } from './functions'
import { getRemainingDays } from '../../../../utils/date'
import { useLocation, useNavigate } from 'react-router-dom'
import { constants } from '../../../../constants'
import { Context } from '../../../../Root'
import LetterUser from '../LetterUser'
import LetterCardBody from '../LetterCardBody'
import LetterCardDescription from '../LetterCardDescription'

type LetterCardType = {
  letter: LetterDetailType
  showReplyBtn: boolean
  cancelEvent?: () => void
  switchAddPeople: (senderId: number) => void
}

const { LOCATION } = constants

function LetterCard({
  letter,
  showReplyBtn,
  cancelEvent,
  switchAddPeople,
}: LetterCardType) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loggedInUser, setIsLetterWriteModal, setReplyUserId } =
    useContext(Context)
  const arrivalDate = new Date(letter.arrivalDate)
  const nowDate = new Date()
  const remainingDays = getRemainingDays(arrivalDate, nowDate)
  const pathname = useLocation().pathname

  // 편지 도착일이 아직 남았고 받은 편지함이라면 "편지 도착 후에 답장 가능"이라는 메세지 표시
  const showReplyIcon = () => {
    if (showReplyBtn && remainingDays <= 0) {
      return (
        <div
          className={styles.letter_wrap_content_buttons_right_comments}
          onClick={() => {
            setReplyUserId(letter.sender.id)
            setIsLetterWriteModal(true)
          }}
        >
          {t(`ui.atom.button.reply`)}
        </div>
      )
    } else if (
      pathname === LOCATION.PATH.RECEIVING_TRAY &&
      remainingDays > 0 &&
      showReplyBtn
    ) {
      return (
        <div className={styles.letter_wrap_content_buttons_wait}>
          {t(`ui.molecules.letter-card.label.be-able-to-reply-msg`)}
        </div>
      )
    } else {
      return ''
    }
  }

  return (
    <div className={styles.letter_wrap} key={letter.id}>
      {cancelEvent ? (
        <div className={styles.letter_wrap_header}>
          <div
            className={styles.letter_wrap_header_cancel}
            onClick={cancelEvent}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className={styles.letter_wrap_content}>
        <LetterUser
          letter={letter}
          loggedInUser={loggedInUser}
          switchAddPeople={switchAddPeople}
          type="sender"
        />

        <LetterCardBody
          letter={letter}
          loggedInUser={loggedInUser}
          showReplyBtn={showReplyBtn}
          cancelEvent={cancelEvent}
          switchAddPeople={switchAddPeople}
        />

        <LetterCardDescription letter={letter} remainingDays={remainingDays} />
      </div>

      <div className={styles.letter_wrap_content_buttons}>
        <div className={styles.letter_wrap_content_buttons_left}>
          <div
            className={styles.letter_wrap_content_buttons_left_comments}
            onClick={() => goToLetterDetail(navigate, letter.id)}
          >
            {t(`ui.molecules.letter-card.label.show-comments`)}
          </div>
        </div>
        {showReplyIcon()}
      </div>
    </div>
  )
}

export default LetterCard
