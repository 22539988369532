import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import LetterSelect from '../../molecules/letterSelect'
import styles from './style.module.scss'
import { clickModalOutSide } from '../../../../utils/domEvent'
import { LoggedInUser } from '../../../../type/account'
import UserSelect from '../../molecules/UserSelect'
import { useTranslation } from 'react-i18next'
import MembershipInfo from '../../molecules/MembershipInfo'
import { constants } from '../../../../constants'
import { SelectedLetterClassType } from '../../../../type/letter'
import { checkSubscriptionNow } from '../../../../utils/authentication/checkSubscriptionNow'

const { LETTER } = constants

type RegistLetterSecondStepModalType = {
  loggedInUser: LoggedInUser
  cancelEvent: () => void
  setIsFirstStep: Dispatch<SetStateAction<boolean>>
  sendEvent: (
    registLetter: string,
    selectedLetterType: number,
    selectedReceiveUserId: number,
  ) => void
  registLetter: string
  replyUserId: number
}

function RegistLetterSecondStepModal({
  loggedInUser,
  cancelEvent,
  setIsFirstStep,
  sendEvent,
  registLetter,
  replyUserId,
}: RegistLetterSecondStepModalType) {
  const { t } = useTranslation()
  const [selectedLetterClass, setSelectedLetterClass] =
    useState<SelectedLetterClassType>(0)
  const [selectedReceiveUserId, setSelectedReceiveUserId] = useState(0)
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSelectedReceiveUserId(replyUserId)
  }, [replyUserId])

  /**
   * 편지지를 선택했을 때 setSelectedType에 선택한 편지지 ID를 넣는다
   * @param selectedId
   */
  const setHandleEvent = async (selectedId: SelectedLetterClassType) => {
    setSelectedLetterClass(selectedId)
  }

  // 유료 회원이 아니면 편지를 못보내게 한다
  const handleSendBtn = () => {
    if (
      !checkSubscriptionNow(loggedInUser) &&
      (selectedLetterClass === LETTER.TYPE.CLASS1 ||
        selectedLetterClass === LETTER.TYPE.CLASS10)
    ) {
      return
    } else {
      sendEvent(registLetter, selectedLetterClass, selectedReceiveUserId)
    }
  }

  const customSendBtnStyle = () => {
    if (
      !checkSubscriptionNow(loggedInUser) &&
      (selectedLetterClass === LETTER.TYPE.CLASS1 ||
        selectedLetterClass === LETTER.TYPE.CLASS10)
    ) {
      return {
        color: '#eee',
        cursor: 'not-allowed',
      }
    }
  }

  return (
    <div
      className={styles.container}
      onClick={(e) => clickModalOutSide(e, modalRef, cancelEvent)}
    >
      <div className={styles.container_window} ref={modalRef}>
        <div className={styles.container_window_title}>
          <div
            className={styles.container_window_title_left}
            onClick={() => setIsFirstStep(true)}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </div>
          <div className={styles.container_window_title_center}>
            {t(`page.top.modal.new-letter.title`)}
          </div>
          <div
            className={styles.container_window_title_right}
            style={customSendBtnStyle()}
            onClick={handleSendBtn}
          >
            {t(`ui.atom.button.send`)}
          </div>
        </div>
        <div className={styles.container_window_content}>
          <LetterSelect setHandleEvent={setHandleEvent} />

          {/* 느린 편지지를 선택 했을 경우 친구목록 표시 */}
          {selectedLetterClass === LETTER.TYPE.CLASS10 ? (
            <UserSelect
              friends={loggedInUser.friends}
              setSelectedReceiveUserId={setSelectedReceiveUserId}
            />
          ) : (
            ''
          )}

          {/* 친구가 없다면 친구등록 메세지를 표시 */}
          {selectedLetterClass === LETTER.TYPE.CLASS10 &&
          !loggedInUser.friends.length ? (
            <div className={styles.container_window_no_friends}>
              <p>{t(`ui.organisms.regist-letter-modal.label.no-friends`)}</p>
              <span>
                {t(`ui.organisms.regist-letter-modal.label.random-receiver`)}
              </span>
            </div>
          ) : (
            ''
          )}

          {/* 유료 멤버쉽 회원이 아니면 멤버쉽 안내 메세지를 표시 */}
          {!checkSubscriptionNow(loggedInUser) &&
          (selectedLetterClass === LETTER.TYPE.CLASS1 ||
            selectedLetterClass === LETTER.TYPE.CLASS10) ? (
            <MembershipInfo cancelEvent={cancelEvent} />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default RegistLetterSecondStepModal
