import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { useLocation } from 'react-router-dom'
import { constants } from '../../../../constants'
import { format } from 'date-fns-tz'
import { LetterDetailType } from '../../../../type/letter'
import Label from '../../atoms/Label'

const { LOCATION } = constants

type LetterCardDescriptionType = {
  letter: LetterDetailType
  remainingDays: number
}

function LetterCardDescription({
  letter,
  remainingDays,
}: LetterCardDescriptionType) {
  const { t } = useTranslation()
  const pathname = useLocation().pathname

  // 홈 화면과 보낸 편지함이 아니며 답장을 완료했다면 "답장완료" 라벨이 보이도록 한다
  const showReplyedLabel = () => {
    if (
      pathname !== LOCATION.PATH.SEND_TRAY &&
      pathname !== LOCATION.PATH.HOME &&
      letter.isReplyed
    ) {
      return (
        <div className={styles.container_replyed}>
          <Label
            text={t(`ui.molecules.letter-card.label.replyed`)}
            type="white"
          />
        </div>
      )
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.container_recipient}>
        <span>
          {format(new Date(letter.arrivalDate), t(`util.date-time-format`))}
          <span>
            {remainingDays > 0
              ? t(`ui.molecules.letter-card.label.scheduled-to-arrive`)
              : t(`ui.molecules.letter-card.label.arrived`)}
          </span>
          <div className={styles.container_recipient_item}>
            view
            <span>{letter.views}</span>
          </div>
          <div className={styles.container_recipient_item}>
            comments
            <span>{letter.comments.length}</span>
          </div>
        </span>
        {showReplyedLabel()}
      </div>
    </div>
  )
}

export default LetterCardDescription
