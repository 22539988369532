/**
 * 모달이 켜지면 overflow를 hidden으로 변경한다
 * @param isModal
 * @returns
 */
export const switchOverflowHidden = (isModal: boolean) => {
  if (isModal) {
    window.document.body.style.overflow = 'hidden'
    window.document.body.style.position = 'fixed'
    window.document.body.style.width = '100%'
    window.document.body.style.height = '100%'
  } else {
    window.document.body.style.overflow = 'visible'
    window.document.body.style.removeProperty('position')
    window.document.body.style.removeProperty('width')
    window.document.body.style.removeProperty('height')
  }
}
