import { useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import { LetterDetailType } from '../../../../type/letter'
import LetterCard from '../../molecules/letterCard'
import { clickModalOutSide } from '../../../../utils/domEvent'

type LetterModalType = {
  letter: LetterDetailType
  cancelEvent: () => void
  switchAddPeople: (id: number) => void
}

function LetterModal({
  letter,
  cancelEvent,
  switchAddPeople,
}: LetterModalType) {
  const childElementRef = useRef<HTMLDivElement>(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div
      className={`${styles.modal_overlay} ${mounted && styles.fade_in}`}
      onClick={(e) => clickModalOutSide(e, childElementRef, cancelEvent)}
    >
      <div className={`${styles.modal_overlay_window}`} ref={childElementRef}>
        <LetterCard
          letter={letter}
          showReplyBtn={false}
          cancelEvent={cancelEvent}
          switchAddPeople={switchAddPeople}
        />
      </div>
    </div>
  )
}

export default LetterModal
