import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import TextBox from '../TextBox'
import { constants } from '../../../../constants'
import { SetterOrUpdater } from 'recoil'
import { clickImgIcon } from './functions'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

const { MAX_INPUT_LENGTH, INPUT_BOX_DEFAULT_HEIGHT } = constants

type CreateLetterBodyType = {
  handleImageChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    setCompressedImageUrl: SetterOrUpdater<string>,
    setIsImgTypeErr: Dispatch<SetStateAction<boolean>>,
    setIsImgSizeErr: Dispatch<SetStateAction<boolean>>,
    setBackgroundFile: SetterOrUpdater<File | null>,
    setProgress: Dispatch<SetStateAction<number>>,
    t: TFunction<'translation', undefined>,
  ) => Promise<void>
  setRegistLetter: SetterOrUpdater<string>
  setBackgroundFile: SetterOrUpdater<File | null>
  registLetter: string
  setIsImgTypeErr: Dispatch<SetStateAction<boolean>>
  setIsImgSizeErr: Dispatch<SetStateAction<boolean>>
  compressedImageUrl: string
  setCompressedImageUrl: SetterOrUpdater<string>
  setProgress: Dispatch<SetStateAction<number>>
}

function CreateLetterBody({
  handleImageChange,
  setRegistLetter,
  setBackgroundFile,
  registLetter,
  setIsImgTypeErr,
  setIsImgSizeErr,
  compressedImageUrl,
  setCompressedImageUrl,
  setProgress,
}: CreateLetterBodyType) {
  const { t } = useTranslation()
  const inputElement = useRef<HTMLDivElement>(null)
  const backgroundImgRef = useRef<HTMLInputElement>(null)

  const [customStyle, setCustomStyle] = useState({})
  const [maskStyle, setMaskStyle] = useState({})
  const [inputBoxHeight, setInputBoxHeight] = useState<number | 100>(
    INPUT_BOX_DEFAULT_HEIGHT,
  )
  const [imageHeight, setImageHeight] = useState<number | null>(null)
  const containerInputWrapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const inputElementWidth =
      inputElement.current?.clientWidth ?? INPUT_BOX_DEFAULT_HEIGHT
    if (imageHeight) {
      setInputBoxHeight(imageHeight - 60) //이미지 높이에서 마진 30 * 2를 빼준다
    }

    // 이미지의 높이를 측정합니다.
    const handleImageLoad = () => {
      if (compressedImageUrl) {
        setImageHeight(inputElementWidth)

        setCustomStyle({
          backgroundImage: `url(${compressedImageUrl})`,
          backgroundSize: 'cover', // 이미지를 가로와 세로 중 큰 쪽에 맞게 확대하여 부모 요소를 덮도록 설정
          backgroundPosition: 'center', // 이미지를 가운데 정렬합니다.
          height: `${inputElementWidth}px`,
        })

        setMaskStyle({
          background: `linear-gradient(
                  to right,
                  rgba(0, 0, 0, 0.7) 20%,
                  rgba(0, 0, 0, 0)
                )` /* 그라데이션 설정 */,
          color: '#fff',
        })
      }
    }
    handleImageLoad()
  }, [compressedImageUrl, imageHeight])

  // .container_window_content_input_wrap 요소의 스타일 설정
  const inputWrapStyle = imageHeight
    ? { height: `${imageHeight - 60}px` }
    : { minHeight: `${INPUT_BOX_DEFAULT_HEIGHT}px` }
  return (
    <div className={styles.container}>
      <div
        className={styles.container_input}
        style={customStyle}
        ref={inputElement}
      >
        <div className={styles.container_input_mask} style={maskStyle}>
          <div
            className={styles.container_input_mask_wrap}
            style={inputWrapStyle}
            ref={containerInputWrapRef}
          >
            <TextBox
              setInputText={setRegistLetter}
              maxLength={MAX_INPUT_LENGTH}
              registLetter={registLetter}
              height={inputBoxHeight}
            />
            <input
              type="file"
              ref={backgroundImgRef}
              style={{ display: 'none' }}
              onChange={(e) =>
                handleImageChange(
                  e,
                  setCompressedImageUrl,
                  setIsImgTypeErr,
                  setIsImgSizeErr,
                  setBackgroundFile,
                  setProgress,
                  t,
                )
              } // input 파일이 변경될 때 실행되는 함수를 설정합니다.
            ></input>
          </div>
        </div>
        <div className={styles.container_input_mask_wrap_indicate}>
          <div
            className={styles.container_input_mask_wrap_indicate_img}
            onClick={() => clickImgIcon(backgroundImgRef)}
          >
            <i className="fa-solid fa-image"></i>
          </div>
          <span>
            {registLetter.length}/{MAX_INPUT_LENGTH}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CreateLetterBody
