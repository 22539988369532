import { Dispatch, SetStateAction } from 'react'
import TextBox from '../TextBox'
import styles from './style.module.scss'
import Button from '../../atoms/Button'
import { useTranslation } from 'react-i18next'
import { constants } from '../../../../constants'

const { COMMENT_MAX_INPUT_LENGTH, INPUT_BOX_DEFAULT_HEIGHT } = constants

type WriteCommentType = {
  setInputComment: Dispatch<SetStateAction<string>>
  saveComment: () => void
  inputComment: string
}

function WriteComment({
  setInputComment,
  saveComment,
  inputComment,
}: WriteCommentType) {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      <div className={styles.container_input}>
        <TextBox
          setInputText={setInputComment}
          maxLength={COMMENT_MAX_INPUT_LENGTH}
          height={INPUT_BOX_DEFAULT_HEIGHT}
        />
        <span>
          {inputComment.length}/{COMMENT_MAX_INPUT_LENGTH}
        </span>
      </div>
      <div className={styles.container_fotter}>
        <div className={styles.container_fotter_btn}>
          <Button
            btnName={t(`ui.atom.button.regist`)}
            disabled={!inputComment}
            clickEvent={saveComment}
          />
        </div>
      </div>
    </div>
  )
}

export default WriteComment
