// Check Email type
// Not Email type: false
export const checkValidEmail = (email: string) => {
  // 이메일 형식을 검증하는 정규식
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(String(email).toLowerCase())
}

export const checkValidPassword = (password: string): boolean => {
  // 8자리 이상
  const hasMinimumLength = password.length >= 8

  // 알파벳과 숫자의 조합
  const hasAlphaNumeric = /[a-zA-Z]/.test(password) && /[0-9]/.test(password)

  // 특수문자 포함 여부
  const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password)

  return hasMinimumLength && hasAlphaNumeric && hasSpecialCharacter
}

/**
 * 입력된 텍스트가 MIN_INPUT_LENGTH(10) 보다 큰지 확인
 * @param text
 * @returns MIN_INPUT_LENGTH(10) 보다 작다면 true
 */
export const checkMinLengthText = (text: string, MIN_INPUT_LENGTH: number) => {
  return text.length < MIN_INPUT_LENGTH
}

/**
 * Email이 입력되었는지 안되었는지 또는 Email형식에 문제가 없는지 확인
 * @param email
 * @param setEmailErrorState
 * @param setNoEnteredEmailState
 * @returns
 */
export const checkValidate = (
  email: string,
  setEmailErrorState: React.Dispatch<
    React.SetStateAction<{
      emailValidationErr: boolean
    }>
  >,
  setNoEnteredEmailState: React.Dispatch<
    React.SetStateAction<{
      noEmail: boolean
    }>
  >,
) => {
  const isNoEnteredEmail = email === ''
  let isEmailValidate = false
  if (isNoEnteredEmail) {
    setNoEnteredEmailState({ noEmail: isNoEnteredEmail })
  } else {
    isEmailValidate = !checkValidEmail(email)
    setEmailErrorState({ emailValidationErr: isEmailValidate })
  }

  return isEmailValidate || isNoEnteredEmail
}
