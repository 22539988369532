import { useContext, useEffect, useState } from 'react'
import Button from '../../atoms/Button'
import styles from './style.module.scss'
import logoImage from '../../../../media/img/heartter_icon.png'
import { goToHome } from './functions'
import { Context } from '../../../../Root'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { goToLogin } from '../../../../utils/navigation'

function Header() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    isLetterWriteModal,
    setIsLetterWriteModal,
    showHamburgerMenu,
    setShowHamburgerMenu,
    loggedInUser,
  } = useContext(Context)
  const [path, setPath] = useState('')
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname
    setPath(pathname)
  }, [location])

  // 로그인 버튼 출력
  const loginComponent = () => {
    return (
      <div className={styles.button}>
        <Button
          btnName={t(`ui.atom.button.login`)}
          type="secondary"
          disabled={false}
          clickEvent={() => goToLogin(navigate)}
        />
      </div>
    )
  }

  // 편지쓰기 버튼 출력
  const letterWriteModalComponent = () => {
    if (path === '/') {
      return (
        <Button
          btnName={t(`header.regist-letter`)}
          disabled={false}
          clickEvent={() => {
            setIsLetterWriteModal(!isLetterWriteModal)
          }}
        />
      )
    }
  }

  return (
    <header className={styles.container}>
      <div className={styles.container_logo} onClick={goToHome}>
        <img src={logoImage} alt="Heartter" className={styles.logo} />
        <span>Heartter</span>
      </div>
      <div className={styles.container_menu}>
        <div className={styles.button}>
          {loggedInUser?.id ? letterWriteModalComponent() : loginComponent()}
        </div>
        <div
          className={styles.container_menu_more}
          onClick={() => {
            setShowHamburgerMenu(!showHamburgerMenu)
          }}
        >
          <i className="fa-solid fa-bars"></i>
        </div>
      </div>
    </header>
  )
}

export default Header
