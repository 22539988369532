import MainMap from '../../UI/organisms/MainMap'
import LetterModal from '../../UI/organisms/LetterModal/index'
import LoadingSpinner from '../../templates/LoadingSpinner/index'
import ElectronicDisplayBoard from '../../UI/organisms/ElectronicDisplayBoard/index'
import { useContext, useEffect, useState } from 'react'
import {
  fetchLetter,
  fetchLetters,
  putUpdateLetterViews,
} from '../../../api/letter'
import { LettersResponse, LetterDetailType } from '../../../type/letter'
import { constants } from '../../../constants/index'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Context } from '../../../Root'
import { switchAddPeople } from '../../../utils/friend'

const { ALL_LETTER_MAX_MILLI_SECOND } = constants

function Home() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [letterDetail, setLetterDetail] = useState<LetterDetailType | null>(
    null,
  )
  const [selectedletterId, setSelectedLetterId] = useState<number>(0)
  const [letters, setLetters] = useState<LettersResponse>([])
  const [isCalledApi, setIsCallApi] = useState(false)
  const location = useLocation()
  const { reloadAccount } = useContext(Context)

  const queryParams = new URLSearchParams(location.search)

  const getLetterId = () => {
    // URL에 letterId를 직접 적었을 경우 편지 상세 모달이 표시되게끔 함
    return Number(queryParams.get('id')) ?? 0
  }

  /**
   * 편지를 클릭 했을 때 실행
   * @param letter
   */
  const clickLetter = (letter: LetterDetailType) => {
    putUpdateLetterViews(t, letter.id)
    fetchLetter(t, letter.id, setLetterDetail)
    setLetterDetail(letter)
    setSelectedLetterId(letter.id)
    navigate(`?id=${letter.id}`)
  }

  useEffect(() => {
    fetchLetters(t, setLetters, setIsCallApi)

    const letterId = getLetterId()
    if (letterId) {
      setSelectedLetterId(letterId)
      putUpdateLetterViews(t, letterId)
      fetchLetter(t, letterId, setLetterDetail)
    }

    // 10분 단위로 모든 편지를 다시 불러온다
    const intervalId = setInterval(() => {
      setIsCallApi(false)
      fetchLetters(t, setLetters, setIsCallApi)
    }, ALL_LETTER_MAX_MILLI_SECOND)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div>
      {isCalledApi ? (
        <div>
          <MainMap letters={letters} clickLetter={clickLetter} />
          <ElectronicDisplayBoard letters={letters} clickLetter={clickLetter} />
        </div>
      ) : (
        <LoadingSpinner />
      )}

      {letterDetail && selectedletterId ? (
        <LetterModal
          letter={letterDetail}
          switchAddPeople={(id) => switchAddPeople(t, id, reloadAccount)}
          cancelEvent={() => {
            setLetterDetail(null)
            navigate('/')
          }}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default Home
