import { NavigateFunction } from 'react-router-dom'
import { constants } from '../../../../constants'
import { fetchTermsOfUse } from '../../../../api/account'
import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { TermsOfUseResponse } from '../../../../type/account'

const { LOCATION } = constants

export const goToNext = (navigate: NavigateFunction) => {
  navigate(LOCATION.PATH.REGIST_ACCOUNT)
}

export const goToBack = (navigate: NavigateFunction) => {
  navigate(LOCATION.PATH.LOGIN)
}

/**
 * 이용규약 API를 불러옴
 * @param t
 */
export const callApi = async (
  t: TFunction<'translation', undefined>,
  setTermsOfUse: Dispatch<SetStateAction<TermsOfUseResponse['termsOfUse']>>,
  setCalledApi: Dispatch<SetStateAction<boolean>>,
) => {
  await fetchTermsOfUse(t, setTermsOfUse)
  setCalledApi(true)
}
