import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { ReactEnv } from '../../type/react'
import { fetchReactEnv } from '../../api/react'

/**
 * 리엑트에서 필요한 env 취득
 * @param t
 * @param setReactEnv
 */
export const setEnv = async (
  t: TFunction<'translation', undefined>,
  setReactEnv: Dispatch<SetStateAction<ReactEnv>>,
) => {
  await fetchReactEnv(t, setReactEnv)
}
