import { EditAccountInfo } from '../../../type/account'
import { checkValidEmail } from '../../../utils/validate'

// check account info with Empty
export const checkEmptyValues = (
  account: EditAccountInfo,
  setMapMarkerState: React.Dispatch<
    React.SetStateAction<{
      noMoveMarker: boolean
    }>
  >,
) => {
  let isEmpty = false
  Object.entries(account).map(([keys, value]) => {
    if (Array.isArray(value)) {
      const result = value[0] === 0 || value[1] === 0
      isEmpty = result
      if (result) {
        setMapMarkerState((prevState) => {
          return Object.assign({ ...prevState }, { noMoveMarker: true })
        })
      }
    }
  })
  return isEmpty
}

export const checkValidation = (
  account: EditAccountInfo,
  setEmailValidationState: React.Dispatch<
    React.SetStateAction<{
      emailValidationErr: boolean
    }>
  >,
  setEmailState: React.Dispatch<
    React.SetStateAction<{
      noEmail: boolean
    }>
  >,
  setNameState: React.Dispatch<
    React.SetStateAction<{
      noName: boolean
    }>
  >,
) => {
  let isValidationError = false
  Object.entries(account).map(([key, value]) => {
    if (key === 'name' && value === '') {
      isValidationError = true
      setNameState((preveState) => {
        return Object.assign({ ...preveState }, { noName: true })
      })
    }
    if (key === 'email') {
      if (value === '') {
        setEmailState((preveState) => {
          return Object.assign({ ...preveState }, { noEmail: true })
        })
        isValidationError = true
      }
      const isValidEmail = checkValidEmail(value as string)
      setEmailValidationState((preveState) => {
        return Object.assign(
          { ...preveState },
          { emailValidationErr: !isValidEmail },
        )
      })
      isValidationError = !isValidEmail
    }
  })
  return isValidationError
}
