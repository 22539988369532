import { useTranslation } from 'react-i18next'
import Button from '../../atoms/Button'
import TextInput from '../../atoms/TextInput'
import styles from './style.module.scss'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { handleInputEvent } from './functions'
import { formatTime } from '../../../../utils/authentication/authenticationCode'
import { SetterOrUpdater } from 'recoil'

type AuthenticationCodeType = {
  remainingTime: number
  authenticationTimeout: boolean
  authenticationCodeSuccess: {
    isSuccess: boolean
  }
  timerId: NodeJS.Timer | null
  setAuthenticationTimeout: React.Dispatch<React.SetStateAction<boolean>>
  setAuthenticationCodeErr: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >
  setAuthenticationCodeSuccess: Dispatch<
    SetStateAction<{
      isSuccess: boolean
    }>
  >
  setEmailVerification: SetterOrUpdater<boolean>
}

function AuthenticationCode({
  remainingTime,
  authenticationTimeout,
  authenticationCodeSuccess,
  timerId,
  setAuthenticationTimeout,
  setAuthenticationCodeErr,
  setAuthenticationCodeSuccess,
  setEmailVerification,
}: AuthenticationCodeType) {
  const { t } = useTranslation()
  const [inputStr, setInputStr] = useState('')

  const authenticationTimeOverMeg = t(
    `page.regist-account.error-massage.authentication-time-over`,
  )

  useEffect(() => {
    return () => {
      setAuthenticationTimeout(false)
    }
  }, [authenticationCodeSuccess.isSuccess])

  return (
    <div className={styles.container}>
      <span>{t(`page.regist-account.label.email-authentication`)}</span>
      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_input}>
          <TextInput
            min={0}
            type="number"
            onChange={setInputStr}
            disabled={
              !authenticationTimeout || authenticationCodeSuccess.isSuccess
            }
          />
        </div>
        <div className={styles.container_wrap_btn}>
          <Button
            btnName={t(`ui.atom.button.check`)}
            type="secondary"
            disabled={
              !authenticationTimeout || authenticationCodeSuccess.isSuccess
            }
            clickEvent={() =>
              handleInputEvent(
                inputStr,
                timerId,
                setAuthenticationCodeErr,
                setAuthenticationCodeSuccess,
                setEmailVerification,
                t,
              )
            }
          />
        </div>
        {authenticationCodeSuccess.isSuccess ? '' : formatTime(remainingTime)}
      </div>
      {authenticationTimeout || authenticationCodeSuccess.isSuccess
        ? ''
        : authenticationTimeOverMeg}
    </div>
  )
}

export default AuthenticationCode
