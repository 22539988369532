import { checkValidate } from '../../../../utils/validate'
import { fetchLogin } from '../../../../api/account'
import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'

export const updateEmail =
  (
    setEmailState: React.Dispatch<React.SetStateAction<string>>,
    setEmailErrorState: React.Dispatch<
      React.SetStateAction<{
        emailValidationErr: boolean
      }>
    >,
    setNoEnteredEmailState: React.Dispatch<
      React.SetStateAction<{
        noEmail: boolean
      }>
    >,
  ) =>
  (e: string) => {
    setNoEnteredEmailState({ noEmail: false })
    setEmailErrorState({ emailValidationErr: false })
    setEmailState(e)
  }

export const updatePassword =
  (setPasswordState: React.Dispatch<React.SetStateAction<string>>) =>
  (e: string) => {
    setPasswordState(e)
  }

export const onSignup = async (
  e: React.FormEvent<HTMLFormElement>,
  emailState: string,
  passwordState: string,
  setEmailErrorState: React.Dispatch<
    React.SetStateAction<{
      emailValidationErr: boolean
    }>
  >,
  setNoEnteredEmailState: React.Dispatch<
    React.SetStateAction<{
      noEmail: boolean
    }>
  >,
  setIsPasswordState: Dispatch<
    SetStateAction<{
      noPassword: boolean
    }>
  >,
  t: TFunction<'translation', undefined>,
) => {
  e.preventDefault()
  setIsPasswordState({ noPassword: false })
  const isError = checkValidate(
    emailState,
    setEmailErrorState,
    setNoEnteredEmailState,
  )
  const isPassowordError = () => {
    return passwordState ? false : true
  }
  setIsPasswordState({ noPassword: isPassowordError() })

  if (!isError && passwordState) {
    const loginInfo = {
      email: emailState,
      password: passwordState,
    }
    await fetchLogin(t, loginInfo)
  }
}
