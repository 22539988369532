import React, { useContext, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { createSubscription } from '../../../../api/payment'
import { useTranslation } from 'react-i18next'
import { Context } from '../../../../Root'
import { constants } from '../../../../constants'
import Button from '../../atoms/Button'
import { checkSubscriptionNow } from '../../../../utils/authentication/checkSubscriptionNow'

const { LOCATION } = constants

const CheckoutForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { reactEnv, loggedInUser } = useContext(Context)
  const baseUrl = reactEnv.baseUrl

  const handleError = (error: any) => {
    setLoading(false)
    setErrorMessage(error.message)
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    if (loggedInUser) {
      if (checkSubscriptionNow(loggedInUser)) {
        alert(t(`page.register-membership.error-msg.already-member`))
        return
      }
    } else {
      alert(t(`page.register-membership.error-msg.not-logged-in`))
      window.location.href = LOCATION.PATH.LOGIN
    }

    setLoading(true)

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    // Create the subscription
    const res = await createSubscription(t)

    if (res) {
      const { type, clientSecret, subscriptionId } = res
      const confirmIntent =
        type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment

      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${baseUrl}${LOCATION.PATH.SUBSCRIPT_COMPLETE}?sub_id=${subscriptionId}`,
        },
      })

      if (error) {
        // This point is only reached if there's an immediate error when confirming the Intent.
        // Show the error to your customer (for example, "payment details incomplete").
        handleError(error)
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        btnName={t(`ui.atom.button.regist-membership`)}
        disabled={!stripe || loading}
      />
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}

export default CheckoutForm
