import { Dispatch, MutableRefObject, RefObject, SetStateAction } from 'react'

/**
 * 스크롤의 현재 위치를 파악해서 이미지를 보여줄 곳이 아직 화면에 보여지지 않는 영역이라면 setIsLoading을 true로 한다
 * 참고 파일: frontend/src/components/UI/molecules/Avatar/index.tsx
 *  * 레퍼런스: https://jforj.tistory.com/163
 * @param observer
 * @param imgRef
 * @param setIsLoading
 */
export const intersectionOberserverHook = (
  observer: MutableRefObject<IntersectionObserver | undefined>,
  imgRef: RefObject<HTMLImageElement>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  observer.current = new IntersectionObserver(
    intersectionOberserver(setIsLoading),
  ) // 인스턴스 생성
  imgRef.current && observer.current.observe(imgRef.current) // 이미지 태그 관찰 시작
}

// IntersectionObserver 설정
const intersectionOberserver =
  (setIsLoading: Dispatch<SetStateAction<boolean>>) =>
  (entries: IntersectionObserverEntry[], io: IntersectionObserver) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // 관찰되고 있는 entry가 보여지게 된 다면
        io.unobserve(entry.target) // 관찰 종료
        setIsLoading(true) // 로딩 체크
      }
    })
  }
