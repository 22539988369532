import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import styles from './style.module.scss'

type RadioButtonType = {
  id: number
  value: number
  labelName: string
  selectedId: number
  disabled: boolean
  width: number
  height: number
  changeValue: (value: number) => void
}

function RadioButton({
  id,
  value,
  labelName,
  selectedId,
  disabled,
  width,
  height,
  changeValue,
}: RadioButtonType) {
  const handleOnChange = (newValue: number) => {
    changeValue(newValue)
  }

  const handleOnClick = () => {
    if (!disabled) {
      handleOnChange(value)
    }
  }

  const containerClassName = `
  ${styles.container}
  ${value !== selectedId ? styles['not-selected'] : ''}
  ${disabled ? styles['disabled'] : ''}
`

  return (
    <div
      className={containerClassName}
      style={{ width: `${width}px`, height: `${height}px` }}
      onClick={handleOnClick}
    >
      <input
        type="radio"
        id={String(id)}
        value={value}
        checked={value === selectedId}
        onChange={(e) => handleOnChange(Number(e.currentTarget.value))}
        disabled={disabled}
      />
      <label htmlFor={String(id)}>{labelName}</label>
    </div>
  )
}

export default RadioButton
