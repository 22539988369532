import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import Button from '../../../UI/atoms/Button'
import TextInput from '../../../UI/atoms/TextInput'
import { useNavigate } from 'react-router-dom'
import { goToRegistComplete, goToBack, makeTextPrivate } from './functions'
import { useRecoilState } from 'recoil'
import { registAccountState } from '../../../../store/account'
import * as maptilersdk from '@maptiler/sdk'
import { imgStyle } from './style'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../locales/i18n'
import { scrollToFirst } from '../../../../utils/domEvent'
import { constants } from '../../../../constants'
import { ReactEnv } from '../../../../type/react'
import { setEnv } from '../../../../utils/authentication/reactEnv'

const { LOCATION } = constants

function RegistConfirm() {
  const { t } = useTranslation()
  const mapContainer = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [account, setAccount] = useRecoilState(registAccountState)
  const [reactEnv, setReactEnv] = useState<ReactEnv>({
    baseUrl: '',
    reactAppMaptilerApi: '',
    stripePublicKey: '',
  }) // react의 env파일을 취득
  const [zoom] = useState(3)
  const map = useRef<maptilersdk.Map | null>(null)
  const tokyo = { lng: 139.753, lat: 35.6844 }

  const checkCoordinate = () => {
    const [lng, lat] = account.coordinate
    if (lng === 0 && lat === 0) {
      return [tokyo.lng, tokyo.lat]
    }
    return [lng, lat]
  }
  const nowCoordinate = checkCoordinate() as [number, number]

  // 리엑트에서 필요한 env 취득
  useEffect(() => {
    setEnv(t, setReactEnv)
  }, [])

  useEffect(() => {
    // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
    // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
    const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
    const lang = localStorage.getItem('language') ?? localeLanguege
    i18n.changeLanguage(lang)

    // Maptiler apikey를 불러온다
    const apiKey = reactEnv.reactAppMaptilerApi
    maptilersdk.config.apiKey = apiKey

    map.current = null

    if (reactEnv.reactAppMaptilerApi) {
      map.current = new maptilersdk.Map({
        container: mapContainer.current!,
        style: maptilersdk.MapStyle.OUTDOOR,
        center: nowCoordinate,
        zoom: zoom,
        interactive: false,
        navigationControl: false,
        geolocateControl: false,
      })

      new maptilersdk.Marker({
        draggable: false,
      })
        .setLngLat(nowCoordinate)
        .addTo(map.current)
    }

    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [reactEnv])

  // 화면을 리로드 했을 시 이용규약 페이지로 이동시킨다
  useEffect(() => {
    if (
      !account.email ||
      !account.password ||
      !account.coordinate ||
      !account.name
    ) {
      alert(t(`page.regist-confirm.error-massage.reload`))
      navigate(LOCATION.PATH.TERMS_OF_USE)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div style={imgStyle}></div>
      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_form}>
          <h1>{t(`page.regist-confirm.page-name`)}</h1>
          <div className={styles.container_wrap_form_confirm}>
            <div>
              <strong>{t(`page.regist-confirm.label.user-name`)}</strong>
              <TextInput type="text" value={account.name} disabled={true} />
            </div>

            <div>
              <strong>{t(`page.regist-confirm.label.gender`)}</strong>
              {account.gender === 1 ? (
                <TextInput
                  type="text"
                  value={t(`ui.atom.radio-button.female`)}
                  disabled={true}
                />
              ) : (
                <TextInput
                  type="text"
                  value={t(`ui.atom.radio-button.male`)}
                  disabled={true}
                />
              )}
            </div>

            <div>
              <strong>{t(`page.regist-confirm.label.email`)}</strong>
              <TextInput type="text" value={account.email} disabled={true} />
            </div>
            <div>
              <strong>{t(`page.regist-confirm.label.password`)}</strong>
              <TextInput
                type="text"
                value={makeTextPrivate(account.password)}
                disabled={true}
              />
            </div>
            <div className={styles.container_wrap_form_map_wrap}>
              {reactEnv.reactAppMaptilerApi ? (
                <div
                  ref={mapContainer}
                  className={styles.container_wrap_form_map_wrap_map}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={styles.container_wrap_form_btn}>
            <Button
              btnName={t(`ui.atom.button.regist`)}
              disabled={false}
              clickEvent={() =>
                goToRegistComplete(t, navigate, account, setAccount)
              }
            />
            <Button
              btnName={t(`ui.atom.button.back`)}
              disabled={false}
              type="white"
              clickEvent={() => goToBack(navigate)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistConfirm
