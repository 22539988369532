import * as maptilersdk from '@maptiler/sdk'
import { ProfileEditPayload } from '../../../../type/mypage'
import { ReactEnv } from '../../../../type/react'
import { Dispatch, RefObject, SetStateAction } from 'react'
import { Options } from 'browser-image-compression'
import { scrollToFirst } from '../../../../utils/domEvent'

export const useMapEffect =
  (
    profileEditPayload: ProfileEditPayload,
    mapContainer: React.RefObject<HTMLDivElement>,
    map: React.MutableRefObject<maptilersdk.Map | null>,
    reactEnv: ReactEnv,
    setAvatar: Dispatch<SetStateAction<string | undefined>>,
  ) =>
  () => {
    if (map.current) return // stops map from intializing more than once
    const { loggedInUser, tokyo, zoom, setAccount, setMapMarkerState } =
      profileEditPayload

    // Maptiler apikey를 불러온다
    const apiKey = reactEnv.reactAppMaptilerApi
    maptilersdk.config.apiKey = apiKey

    // 이전에 마크를 이동한 적이 있다면 이동시킨 좌표를 맵에 표시시킨다
    const checkCoordinate = () => {
      const [lng, lat] = profileEditPayload.loggedInUser?.coordinate ?? [0, 0]
      if (lng === 0 && lat === 0) {
        return [tokyo.lng, tokyo.lat]
      }
      return [lng, lat]
    }
    const nowCoordinate = checkCoordinate() as [number, number]

    map.current = new maptilersdk.Map({
      container: mapContainer.current!,
      style: maptilersdk.MapStyle.OUTDOOR,
      center: nowCoordinate,
      zoom: zoom,
    })

    let marker = new maptilersdk.Marker({
      draggable: true,
    })
      .setLngLat(nowCoordinate)
      .addTo(map.current)

    function onDragEnd() {
      let lngLat = marker.getLngLat()
      setAccount((prevState) => {
        return Object.assign(
          { ...prevState },
          { coordinate: [lngLat.lng, lngLat.lat] },
        )
      })

      setMapMarkerState((prevState) => {
        return Object.assign({ ...prevState }, { noMoveMarker: false })
      })
    }

    marker.on('dragend', onDragEnd)

    // 프로필 사진 표시되도록 하기
    setAvatar(loggedInUser?.avatar)

    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }

// change Name
export const updateName =
  (
    setAccount: ProfileEditPayload['setAccount'],
    setNameState: ProfileEditPayload['setNameState'],
  ) =>
  (name: string) => {
    setNameState((prevState) => {
      return Object.assign({ ...prevState }, { noName: false })
    })
    setAccount((prevState) => {
      return Object.assign({ ...prevState }, { name })
    })
  }

// 자기소개란 업데이트
export const updateDescription =
  (setAccount: ProfileEditPayload['setAccount']) => (description: string) => {
    setAccount((prevState) => {
      return Object.assign({ ...prevState }, { description })
    })
  }

/**
 * 프로필 사진 업로드 버튼 클릭시 실행
 * @param profileInputImg
 */
export const updateProfileImg = (
  profileInputImg: RefObject<HTMLInputElement>,
) => {
  profileInputImg.current?.click()
}

/**
 * input 파일이 변경될 때 실행되는 함수
 * @param event
 * @param setAvatar
 * @param setIsImgTypeErr
 * @param setIsImgSizeErr
 * @returns
 */
export const handleImageChange = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setAvatar: Dispatch<SetStateAction<string | undefined>>,
  setIsImgTypeErr: Dispatch<SetStateAction<boolean>>,
  setIsImgSizeErr: Dispatch<SetStateAction<boolean>>,
  setAvatarFile: Dispatch<SetStateAction<File | null>>,
  imageCompression: (image: File, options: Options) => Promise<File>,
  setShowSaveBtn: Dispatch<SetStateAction<boolean>>,
) => {
  setIsImgTypeErr(false)
  setIsImgSizeErr(false)
  const file = event.target.files?.[0] // 첫 번째 파일만 가져옵니다.

  if (file) {
    // 파일이 이미지 파일인지 확인합니다.
    if (!file.type.startsWith('image/')) {
      setIsImgTypeErr(true) // 이미지 파일이 아닌 경우 알림을 띄웁니다.
      return // 함수 실행을 중단합니다.
    }

    const reader = new FileReader()
    reader.onload = () => {
      const imageUrl = reader.result as string
      const image = new Image()
      image.src = imageUrl

      image.onload = () => {
        // 이미지의 너비와 높이를 가져옵니다.
        const width = image.width
        const height = image.height

        // 이미지의 너비와 높이가 500이상이 아닌 경우 알림을 표시
        if (width < 500 || height < 500) {
          setIsImgSizeErr(true)
          return
        }

        // 원하는 크기와 일치하는 경우, 이미지를 state에 저장합니다.
        setAvatar(imageUrl)
      }
    }

    // 리사이즈가 변환 될때까지 저장 버튼이 안눌리게 함
    const progressPer = (progress: number) => {
      setShowSaveBtn(true)
      if (progress === 100) {
        setShowSaveBtn(false)
      }
    }

    // 이미지를 리사이징
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      onProgress: progressPer,
    }
    const compressedFile = await imageCompression(file, options)
    setAvatarFile(compressedFile)
    reader.readAsDataURL(file) // 파일을 읽어 Base64 형식의 URL로 변환합니다.
  }
}
