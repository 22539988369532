import { useContext, useEffect, useRef } from 'react'
import { upateRegisterMembership, updatePayment } from '../../../api/payment'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import Button from '../../UI/atoms/Button'
import { useLocation } from 'react-router-dom'
import { constants } from '../../../constants'
import { Context } from '../../../Root'
import { checkSubscriptionNow } from '../../../utils/authentication/checkSubscriptionNow'

const { LOCATION } = constants

function Purchase() {
  const { t } = useTranslation()
  const { loggedInUser } = useContext(Context)
  const location = useLocation()
  const videoTag = useRef<HTMLVideoElement>(null)

  const queryParams = new URLSearchParams(location.search)
  const subscriptionId = queryParams.get('sub_id') ?? ''
  const playVideo = () => {
    return (
      <video
        src={require('../../../media/videos/video-1.mp4')}
        ref={videoTag}
        autoPlay={true}
        controls={false}
        loop={true}
        muted={true}
        playsInline={true}
      />
    )
  }

  const goToHome = () => {
    window.location.href = LOCATION.PATH.HOME
  }

  const updateMembership = async (subscriptionId: string) => {
    await upateRegisterMembership(t, subscriptionId)
  }

  useEffect(() => {
    if (loggedInUser) {
      if (checkSubscriptionNow(loggedInUser)) {
        alert(t(`page.register-membership.error-msg.already-member`))
        window.location.href = LOCATION.PATH.HOME
      } else {
        updateMembership(subscriptionId)

        // Payment DB에 구매 내역을 추가한다
        updatePayment(t, subscriptionId)
      }
    } else {
      alert(t(`page.register-membership.error-msg.not-logged-in`))
      window.location.href = LOCATION.PATH.LOGIN
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.container_body}>
        <div className={styles.container_body_wrap}>
          <p>{t(`page.purchase-complete.label.success`)}</p>
          <p>{t(`page.purchase-complete.label.let-is-write-letter`)}</p>
          <div className={styles.container_body_wrap_btn}>
            <Button
              btnName={t(`ui.atom.button.home`)}
              disabled={false}
              clickEvent={goToHome}
            />
          </div>
        </div>
      </div>
      <div className={styles.background_video}>{playVideo()}</div>
    </div>
  )
}

export default Purchase
