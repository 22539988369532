import { LoggedInUser } from '../../../../type/account'
import { checkSubscriptionNow } from '../../../../utils/authentication/checkSubscriptionNow'
import LetterIcon from '../../molecules/LetterIcon'
import { letterIcons } from '../../../../constants/letterIcons'
import styles from './style.module.scss'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

type SelecteLetterIcons = {
  loggedInUser: LoggedInUser
  selectedLetterIconId: number
  setSelectedLetterIconId: Dispatch<SetStateAction<number>>
}

function SelecteLetterIcon({
  loggedInUser,
  selectedLetterIconId,
  setSelectedLetterIconId,
}: SelecteLetterIcons) {
  const { t } = useTranslation()
  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLetterIconId(Number(event.currentTarget.value))
  }

  const isDisable = checkSubscriptionNow(loggedInUser)

  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        {t(`ui.organisms.regist-letter-modal.label.select-letter-icon`)}
      </div>
      <div className={styles.container_body}>
        <div className={styles.container_body_row}>
          {letterIcons.map((radio, index) => {
            return (
              <LetterIcon
                backgroundImage={radio.img}
                disabled={!isDisable}
                value={radio.value}
                selectedLetterIconId={selectedLetterIconId}
                id={radio.id}
                changeValue={changeValue}
                key={index}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SelecteLetterIcon
