import { constants } from '../../../../constants'
import { NavigateFunction } from 'react-router-dom'

const { LOCATION } = constants

/**
 * 편지지 상세 페이지로 이동
 * @param navigate
 * @param letterId
 * @param loggedInUser
 */
export const goToLetterDetail = (
  navigate: NavigateFunction,
  letterId: number,
) => {
  navigate(`${LOCATION.PATH.LETTER_DETAIL}?id=${letterId}`)
}
