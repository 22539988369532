import { NavigateFunction } from 'react-router-dom'
import { constants } from '../../../../constants'
import { LetterDetailType } from '../../../../type/letter'

const { PATH } = constants.LOCATION

// 로그인 버튼을 눌렀을 시 화면 전환
export const goToLogin = (navigate: NavigateFunction) => {
  navigate(PATH.LOGIN)
}

/**
 * 사진이 있다면 편지지 배경에 사진 추가
 * @param letter
 * @param containerWrapWidth
 * @returns
 */
export const customContainerStyle = (
  letter: LetterDetailType,
  containerWrapWidth: number,
) => {
  if (letter.images[0]) {
    return {
      backgroundImage: `url(${letter.images[0]})`,
      height: `${containerWrapWidth}px`,
      backgroundPosition: 'center',
    }
  }
  return {}
}

/**
 * 사진이 있다면 편지지 글자 부분 스타일 제어
 * @param letter
 * @returns
 */
export const customContainerTextStyle = (letter: LetterDetailType) => {
  if (letter.images[0]) {
    return {
      background: `linear-gradient(
        to right,
        rgba(0, 0, 0, 0.7) 20%,
        rgba(0, 0, 0, 0)
      )` /* 그라데이션 설정 */,
      color: '#fff',
    }
  }
  return {
    background: 'none',
    color: '#000',
  }
}
