import { TFunction } from 'i18next'
import { checkResetToken, resetPassword } from '../../../../api/account'
import backgroundImg from '../../../../media/img/login_background.jpeg'
import { Dispatch, SetStateAction } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { checkValidPassword } from '../../../../utils/validate'

export const imgStyle = {
  background: `url(${backgroundImg})`,
  backgroundSize: 'cover',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  filter: 'blur(14px)',
  backdropFilter: 'blur(14px)',
  WebkitBackdropFilter: 'blur(14px)',
  WebkitFilter: 'blur(10px)',
  transform: 'scale(1.07)',
} as const

export const customEffect = async (
  t: TFunction<'translation', undefined>,
  token: string,
  navigate: NavigateFunction,
) => {
  await checkResetToken(t, token, navigate)
}

type SubmitPayloadType = {
  t: TFunction<'translation', undefined>
  newPassword: string
  newConfirmPassword: string
  setPasswordNoMatchErrorState: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >
  setPasswordState: Dispatch<
    SetStateAction<{
      noPassword: boolean
    }>
  >
  setInappropriatePasswordState: Dispatch<
    SetStateAction<{
      inappropriatePassword: boolean
    }>
  >
  navigate: NavigateFunction
}

/**
 * 새 패스워드 입력시
 * @param setNewPassword
 * @returns
 */
export const handleNewPasswordEvent =
  (setNewPassword: Dispatch<SetStateAction<string>>) => (e: string) => {
    setNewPassword(e)
  }

/**
 * 새 패스워드 확인용
 * @param setNewConfirmPassword
 * @returns
 */
export const handleNewConfirmPasswordEvent =
  (setNewConfirmPassword: Dispatch<SetStateAction<string>>) => (e: string) => {
    setNewConfirmPassword(e)
  }

/**
 * 비밀번호 리셋 버튼을 눌렀을 시 실행
 * @param submitPayload
 * @returns
 */
export const submitBtn = async (submitPayload: SubmitPayloadType) => {
  const {
    t,
    newPassword,
    newConfirmPassword,
    setPasswordNoMatchErrorState,
    setPasswordState,
    setInappropriatePasswordState,
    navigate,
  } = submitPayload
  initState(
    setPasswordNoMatchErrorState,
    setPasswordState,
    setInappropriatePasswordState,
  )

  // 패스워드 입력란이 공란인지 체크
  if (!newPassword || !newConfirmPassword) {
    setPasswordState({ noPassword: true })
    return
  }

  // 새로운 패스워드와 확인용 패스워드가 일치한지 체크
  if (newPassword !== newConfirmPassword) {
    setPasswordNoMatchErrorState({ isError: true })
    return
  }

  // 패스워드 형식 체크
  const isValidPassword = checkValidPassword(newPassword)
  if (!isValidPassword) {
    setInappropriatePasswordState({ inappropriatePassword: !isValidPassword })
    return
  }
  // 비밀번호 리셋API 호출
  await resetPassword(t, newPassword, navigate)
}

/**
 * state의 초기화
 * @param setPasswordNoMatchErrorState
 * @param setPasswordState
 * @param setInappropriatePasswordState
 */
const initState = (
  setPasswordNoMatchErrorState: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >,
  setPasswordState: Dispatch<
    SetStateAction<{
      noPassword: boolean
    }>
  >,
  setInappropriatePasswordState: Dispatch<
    SetStateAction<{
      inappropriatePassword: boolean
    }>
  >,
) => {
  setPasswordNoMatchErrorState({ isError: false })
  setPasswordState({ noPassword: false })
  setInappropriatePasswordState({ inappropriatePassword: false })
}
