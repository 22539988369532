import styles from './style.module.scss'
import Select from '../../atoms/Select'
import { useTranslation } from 'react-i18next'
import { SelectedLetterClassType } from '../../../../type/letter'

type LetterSelectType = {
  setHandleEvent: (e: SelectedLetterClassType) => void
}
function LetterSelect({ setHandleEvent }: LetterSelectType) {
  const { t } = useTranslation()

  const options = [
    { value: 0, text: t(`ui.molecules.letter-select.options.type0`) },
    { value: 1, text: t(`ui.molecules.letter-select.options.type1`) },
    { value: 10, text: t(`ui.molecules.letter-select.options.type10`) },
  ]

  return (
    <div className={styles.content}>
      <p>{t(`ui.molecules.letter-select.select-title`)}</p>
      <div className={styles.content_select}>
        <Select setHandleEvent={setHandleEvent} options={options} />
      </div>
    </div>
  )
}

export default LetterSelect
