import { IconType } from '../../../../type/button'

/**
 * icon type을 넣으면 i 엘레멘트의 해당 아이콘이 반환된다
 * @param iconType
 * @returns
 */
export const createIcon = (iconType: IconType) => {
  const FRE_FIX = 'fa-regular fa-'
  const className = `${FRE_FIX}${iconType}`
  return <i className={className}></i>
}
