import ForgetPassword from '../../components/pages/Account/ForgetAccount'
import Login from '../../components/pages/Account/Login'
import RegistAccount from '../../components/pages/Account/RegistAccount'
import RegistComplete from '../../components/pages/Account/RegistComplete'
import RegistConfirm from '../../components/pages/Account/RegistConfirm'
import ResetPassword from '../../components/pages/Account/ResetPassword'
import PasswordChange from '../../components/pages/Account/PasswordChange'
import TermsOfUse from '../../components/pages/Account/TermsOfUse'
import CancelMembership from '../../components/pages/Account/CancelMembership'
import NotFound from '../../components/pages/Error/notFound'
import { constants } from '../../constants'

const { PATH } = constants.LOCATION

export const accountRoute = [
  {
    path: PATH.LOGIN,
    element: <Login />,
    errorElement: <NotFound />,
  },
  {
    path: PATH.REGIST_ACCOUNT,
    element: <RegistAccount />,
    errorElement: <NotFound />,
  },
  {
    path: PATH.REGIST_CONFIRM,
    element: <RegistConfirm />,
    errorElement: <NotFound />,
  },
  {
    path: PATH.FORGET_PASSWORD,
    element: <ForgetPassword />,
    errorElement: <NotFound />,
  },
  {
    path: PATH.RESET_PASSWORD,
    element: <ResetPassword />,
    errorElement: <NotFound />,
  },
  {
    path: PATH.REGIST_COMPLETE,
    element: <RegistComplete />,
    errorElement: <NotFound />,
  },
  {
    path: PATH.CHANGE_PASSWORD,
    element: <PasswordChange />,
    errorElement: <NotFound />,
  },
  {
    path: PATH.TERMS_OF_USE,
    element: <TermsOfUse />,
    errorElement: <NotFound />,
  },
  {
    path: PATH.CANCEL_MEMBERSHIP_COMPLETE,
    element: <CancelMembership />,
    errorElement: <NotFound />,
  },
] as const
