import { useContext, useEffect, useState } from 'react'
import styles from './style.module.scss'
import { fetchReceivingLetters } from '../../../api/letter'
import { LettersResponse } from '../../../type/letter'
import { Context } from '../../../Root'
import { useNavigate } from 'react-router-dom'
import { constants } from '../../../constants'
import { useTranslation } from 'react-i18next'
import LetterCard from '../../UI/molecules/letterCard'
import { switchAddPeople } from '../../../utils/friend'

const { LOCATION } = constants

function ReceivingTray() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loggedInUser, reloadAccount } = useContext(Context)
  const [receivingLetters, setReceivingLetters] =
    useState<LettersResponse | null>(null)

  const userId = loggedInUser?.id ?? 0

  useEffect(() => {
    if (userId) {
      fetchReceivingLetters(t, userId, setReceivingLetters)
    } else {
      alert(t(`page.receive-letters.error-massage.no-login`))
      navigate(LOCATION.PATH.LOGIN)
      return
    }
  }, [])

  const letterCards = () => {
    return receivingLetters?.length ?? 0 >= 0 ? (
      receivingLetters?.map((letter, index) => {
        return (
          <div className={styles.letters_wrap} key={index}>
            <LetterCard
              letter={letter}
              showReplyBtn={true}
              switchAddPeople={(id) => switchAddPeople(t, id, reloadAccount)}
            />
          </div>
        )
      })
    ) : (
      <p>{t(`page.receive-letters.error-massage.no-received-letters`)}</p>
    )
  }
  return (
    <div className={styles.container}>
      <p>{t(`page.receive-letters.page-name`)}</p>
      <div className={styles.letters}>{letterCards()}</div>
    </div>
  )
}

export default ReceivingTray
