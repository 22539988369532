import styles from './style.module.scss'
import i18n from '../../../../locales/i18n'
import { useContext } from 'react'
import { Context } from '../../../../Root'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { constants } from '../../../../constants'

const { PATH } = constants.LOCATION

function Footer() {
  const { defaultLanguage } = useContext(Context)
  const { t } = useTranslation()

  // 현재 년도 출력
  function getCurrentYear() {
    const now = new Date()
    return now.getFullYear()
  }

  // 언어 변경하기
  const handleChangeLanguage: React.ChangeEventHandler<HTMLSelectElement> = (
    e,
  ) => {
    const lang = e.target.value
    i18n.changeLanguage(lang)
    localStorage.setItem('language', lang)
  }
  return (
    <div>
      <div className={styles.footer}>
        <p className={styles.footer_logo}>
          Heartter <span>{getCurrentYear()}</span>
        </p>
        <Link to={PATH.HOW_TO_USE}>
          <span>사용하는 법</span>
        </Link>
        <Link to={PATH.SPECIFIED_COMMERCIAL_TRANSACTION_ACT}>
          <span>{t(`footer.specified-commercial-transaction-act`)}</span>
        </Link>
        <select
          className={styles.footer_language}
          onChange={handleChangeLanguage}
          defaultValue={defaultLanguage}
        >
          <option value="ko">한국어</option>
          <option value="jp">日本語</option>
          <option value="en">English</option>
        </select>
      </div>
    </div>
  )
}

export default Footer
