import { Outlet, useLocation } from 'react-router-dom'
import Header from './components/UI/organisms/Header'
import Footer from './components/UI/organisms/Footer'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { createContext } from 'react'
import { fetchCheckLogin } from './api/account'
import { LoggedInUser } from './type/account'
import HamburgerMenu from './components/UI/organisms/HamburgerMenu'
import styles from './style.module.scss'
import CreatLetter from './components/UI/organisms/CreatLetter'
import i18n from './locales/i18n'
import { useTranslation } from 'react-i18next'
import { switchOverflowHidden } from './utils/customStyle'
import { ReactEnv } from './type/react'
import { fetchReactEnv } from './api/react'

type LanguageType = 'ko' | 'jp' | 'en'

type ContextType = {
  isLetterWriteModal: boolean
  setIsLetterWriteModal: (arg: boolean) => void
  loggedInUser: null | LoggedInUser
  showHamburgerMenu: boolean
  setShowHamburgerMenu: (arg: boolean) => void
  showCreatLetter: boolean
  setShowCreatLetter: (arg: boolean) => void
  defaultLanguage: LanguageType
  reloadAccount: () => Promise<void>
  reactEnv: ReactEnv
  replyUserId: number
  setReplyUserId: Dispatch<SetStateAction<number>>
}

export const Context = createContext<ContextType>({
  isLetterWriteModal: false,
  setIsLetterWriteModal: () => {},
  loggedInUser: null,
  showHamburgerMenu: false,
  setShowHamburgerMenu: () => {},
  showCreatLetter: false,
  setShowCreatLetter: () => {},
  defaultLanguage: 'ko',
  reloadAccount: async () => {},
  reactEnv: {
    baseUrl: '',
    reactAppMaptilerApi: '',
    stripePublicKey: '',
  },
  replyUserId: 0,
  setReplyUserId: () => {},
})

const Root = () => {
  const { t } = useTranslation()
  const [isLetterWriteModal, setIsLetterWriteModal] = useState<boolean>(false) // 편지쓰기 버튼 클릭 유무를 확인하는 states
  const [loggedInUser, setLoggedInUser] = useState<null | LoggedInUser>(null) // 로그인 유무를 확인하는 state
  const [isApiCallComplete, setIsApiCallComplete] = useState<boolean>(false) // 리엑트 처음 기동시에 호출하는 API가 호출이 끝났는지 확인하는 state
  const [showHamburgerMenu, setShowHamburgerMenu] = useState<boolean>(false) // 햄버거 메뉴의 클릭 유무를 확인하는 state
  const [showCreatLetter, setShowCreatLetter] = useState<boolean>(false) // 편지쓰기 모달의 온오프 유무를 확인하는 state
  const [defaultLanguage, setdefaultLanguage] = useState<LanguageType>('ko') // 사이트의 기본 언어설정 state
  const [reactEnv, setReactEnv] = useState<ReactEnv>({
    baseUrl: '',
    reactAppMaptilerApi: '',
    stripePublicKey: '',
  }) // react의 env파일을 취득
  const [position, setPosition] = useState(0)
  const [replyUserId, setReplyUserId] = useState(0)
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const msg = String(queryParams.get('msg')) ?? ''

  const reloadAccount = async () => {
    await fetchCheckLogin(t, setLoggedInUser, setIsApiCallComplete)
  }

  const onScroll = () => {
    setPosition(window.scrollY)
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  useEffect(() => {
    fetchCheckLogin(t, setLoggedInUser, setIsApiCallComplete)
    fetchReactEnv(t, setReactEnv)

    // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
    // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 브라우져의 언어가 설정 되도록 한다.
    const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
    const lang = localStorage.getItem('language') ?? localeLanguege
    i18n.changeLanguage(lang)
    setdefaultLanguage(lang as LanguageType)
  }, [])

  useEffect(() => {
    switchOverflowHidden(showHamburgerMenu)
  }, [showHamburgerMenu])

  useEffect(() => {
    switchOverflowHidden(isLetterWriteModal)
  }, [isLetterWriteModal])

  return (
    <div>
      {isApiCallComplete ? (
        <Context.Provider
          value={{
            isLetterWriteModal,
            setIsLetterWriteModal,
            loggedInUser,
            showHamburgerMenu,
            setShowHamburgerMenu,
            showCreatLetter,
            setShowCreatLetter,
            defaultLanguage,
            reloadAccount,
            reactEnv,
            replyUserId,
            setReplyUserId,
          }}
        >
          <Header />
          <main>
            {/* URL에 따라 변경되는 부분 */}
            <Outlet />
            {showHamburgerMenu ? (
              <div
                className={styles.hamburger_menu}
                onClick={() => setShowHamburgerMenu(false)}
                style={{ top: position }}
              >
                <HamburgerMenu
                  showHamburgerMenu={showHamburgerMenu}
                  setShowHamburgerMenu={setShowHamburgerMenu}
                  isAuth={loggedInUser?.id ?? 0}
                />
              </div>
            ) : (
              ''
            )}
            {isLetterWriteModal ? (
              <CreatLetter
                msg={msg}
                cancelEvent={() => {
                  setIsLetterWriteModal(!isLetterWriteModal)
                }}
              />
            ) : (
              ''
            )}
          </main>
          <Footer />
        </Context.Provider>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default Root
