import defaultLetterIcon from '../media/img/letterIcon/paper_letter.gif'
import kame from '../media/img/letterIcon/kame.gif'
import roket from '../media/img/letterIcon/roket.gif'
import roket2 from '../media/img/letterIcon/roket2.gif'
import present from '../media/img/letterIcon/present.gif'
import crab from '../media/img/letterIcon/crab.gif'
import butterfly from '../media/img/letterIcon/butterfly.gif'
import cat from '../media/img/letterIcon/cat.gif'
import arrow from '../media/img/letterIcon/arrow.gif'
import satellite from '../media/img/letterIcon/satellite.gif'
import star from '../media/img/letterIcon/star.gif'
import meteor from '../media/img/letterIcon/meteor.gif'
import fireball from '../media/img/letterIcon/fireball.gif'
import eagle from '../media/img/letterIcon/eagle.gif'

export const letterIcons = [
  {
    name: 'default',
    img: defaultLetterIcon,
    value: 0,
    id: 0,
  },
  {
    name: 'kame',
    img: kame,
    value: 1,
    id: 1,
  },
  {
    name: 'roket',
    img: roket,
    value: 2,
    id: 2,
  },
  {
    name: 'roket2',
    img: roket2,
    value: 3,
    id: 3,
  },
  {
    name: 'present',
    img: present,
    value: 4,
    id: 4,
  },
  {
    name: 'crab',
    img: crab,
    value: 5,
    id: 5,
  },
  {
    name: 'butterfly',
    img: butterfly,
    value: 6,
    id: 6,
  },
  {
    name: 'cat',
    img: cat,
    value: 7,
    id: 7,
  },
  {
    name: 'arrow',
    img: arrow,
    value: 8,
    id: 8,
  },
  {
    name: 'satellite',
    img: satellite,
    value: 9,
    id: 9,
  },
  {
    name: 'star',
    img: star,
    value: 10,
    id: 10,
  },
  {
    name: 'meteor',
    img: meteor,
    value: 11,
    id: 11,
  },
  {
    name: 'fireball',
    img: fireball,
    value: 12,
    id: 12,
  },
  {
    name: 'eagle',
    img: eagle,
    value: 13,
    id: 13,
  },
]
