import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { SetterOrUpdater } from 'recoil'
import { addImageStore } from '../../../../utils/letter'
import { checkMinLengthText } from '../../../../utils/validate'
import { constants } from '../../../../constants'

const { MIN_INPUT_LENGTH } = constants

const ABLE_UPLOAD_FILES = ['image/jpeg', 'image/png']

export const showMaxTextLengthErr = (
  t: TFunction<'translation', undefined>,
) => {
  return (
    <p>
      {t(
        `ui.organisms.regist-letter-modal.error-massage.character-limit-exceeded`,
      )}
    </p>
  )
}

export const showNoInputErr = (t: TFunction<'translation', undefined>) => {
  return <p>{t(`ui.organisms.regist-letter-modal.error-massage.no-input`)}</p>
}

/**
 * 편지 내용 입력란에 내용을 입력 했을 시 실행
 * @param e
 * @param MAX_INPUT_LENGTH
 * @param setInputText
 * @param setMaxTextLengthErr
 */
export const handleInputEvent = (
  e: React.FormEvent<HTMLDivElement>,
  MAX_INPUT_LENGTH: number,
  setRegistLetter: SetterOrUpdater<string>,
  setMaxTextLengthErr: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const newValue = e.currentTarget.innerText
  const isLengthExceeding = newValue.length >= MAX_INPUT_LENGTH

  if (isLengthExceeding) {
    setMaxTextLengthErr(true)
  } else {
    setMaxTextLengthErr(false)
  }
  setRegistLetter(newValue)
}

/**
 * input 파일이 변경될 때 실행되는 함수
 * @param event
 * @param setBackgroundImg
 * @param setIsImgTypeErr
 * @param setIsImgSizeErr
 * @returns
 */
export const handleImageChange = async (
  event: React.ChangeEvent<HTMLInputElement>,
  setCompressedImageUrl: SetterOrUpdater<string>,
  setIsImgTypeErr: Dispatch<SetStateAction<boolean>>,
  setIsImgSizeErr: Dispatch<SetStateAction<boolean>>,
  setBackgroundFile: SetterOrUpdater<File | null>,
  setProgress: Dispatch<SetStateAction<number>>,
  t: TFunction<'translation', undefined>,
) => {
  setIsImgTypeErr(false)
  setIsImgSizeErr(false)
  const file = event.target.files?.[0] // 첫 번째 파일만 가져옵니다.

  if (file) {
    const fileType = file.type
    // 파일이 이미지 파일인지 확인합니다.
    if (!ABLE_UPLOAD_FILES.includes(String(fileType))) {
      setIsImgTypeErr(true) // 이미지 파일이 아닌 경우 알림을 띄웁니다.
      return // 함수 실행을 중단합니다.
    }

    const reader = new FileReader()
    reader.onload = () => {
      const imageUrl = reader.result as string
      const image = new Image()
      image.src = imageUrl

      image.onload = () => {
        // 이미지의 너비와 높이를 가져옵니다.
        const width = image.width
        const height = image.height

        // 이미지의 너비와 높이가 500이상이 아닌 경우 알림을 표시
        if (width < 1200 || height < 1200) {
          setIsImgSizeErr(true)
          return
        }

        // 원하는 크기와 일치하는 경우, 이미지를 state에 저장합니다.
        setCompressedImageUrl(imageUrl)
      }
    }

    // 이미지를 리사이징 후 스토어에 저장
    addImageStore(
      setBackgroundFile,
      setCompressedImageUrl,
      setProgress,
      file,
      t,
    )
  }
}

/**
 * 편지쓰기 첫번째 모달에서 다음 버튼을 눌렀을 시 실행
 * @param registLetter
 * @param setNoInputErr
 * @param setIsFirstStep
 * @returns
 */
export const nextStep = (
  registLetter: string | null,
  setNoInputErr: React.Dispatch<React.SetStateAction<boolean>>,
  setIsFirstStep: Dispatch<SetStateAction<boolean>>,
) => {
  const isMinInput = checkMinLengthText(registLetter ?? '', MIN_INPUT_LENGTH)
  setNoInputErr(isMinInput)

  if (!isMinInput) {
    setIsFirstStep(false)
  }
}
