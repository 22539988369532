import styles from './style.module.scss'

type LetterIconType = {
  backgroundImage: string
  disabled: boolean
  value: number
  selectedLetterIconId: number
  id: number
  changeValue: (event: React.ChangeEvent<HTMLInputElement>) => void
}
function LetterIcon({
  backgroundImage,
  disabled,
  value,
  selectedLetterIconId,
  id,
  changeValue,
}: LetterIconType) {
  return (
    <div className={styles.container}>
      <div className={styles.container_radio}>
        <input
          type="radio"
          id={String(id)}
          value={value}
          checked={value === selectedLetterIconId}
          onChange={(e) => changeValue(e)}
          disabled={disabled && id !== 0}
        />
        <label
          htmlFor={String(id)}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></label>
        {disabled && id !== 0 ? (
          <div className={styles.container_mask}>{`Only\nMembership`}</div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default LetterIcon
