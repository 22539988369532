import imageCompression from 'browser-image-compression'
import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { SetterOrUpdater } from 'recoil'

/**
 * 문자열을 아스키 코드로 변환
 * @param str
 * @returns
 */
export const stringToAscii = (str: string) => {
  let ascii = ''
  for (let i = 0; i < str.length; i++) {
    ascii += str.charCodeAt(i) + ' '
  }
  return ascii.trim()
}

/**
 * 아스키 코드를 원래 문자로 변경
 * @param ascii
 * @returns
 */
export const asciiToString = (ascii: string) => {
  const asciiArr = ascii.split(' ')
  let str = ''
  for (let i = 0; i < asciiArr.length; i++) {
    str += String.fromCharCode(parseInt(asciiArr[i]))
  }
  return str
}

/**
 * 추가한 이미지를 리사이징 후 스토어에 저장
 * @param setBackgroundFile
 * @param setBackgroundImg
 * @param file
 */
export const addImageStore = async (
  setBackgroundFile: SetterOrUpdater<File | null>,
  setCompressedImageUrl: SetterOrUpdater<string>,
  setProgress: Dispatch<SetStateAction<number>>,
  file: File,
  t: TFunction<'translation', undefined>,
) => {
  // 이미지를 리사이징
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 2400,
    onProgress: (progress: number) => {
      setProgress(progress)
    },
  }
  try {
    const compressedFile = await imageCompression(file, options)
    setBackgroundFile(compressedFile)

    const compressedImageUrl = URL.createObjectURL(compressedFile)
    setCompressedImageUrl(compressedImageUrl)
  } catch (error) {
    alert(t(`api-error-msg.have-to-jpg`))
  }
}
