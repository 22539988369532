import { TFunction } from 'i18next'
import { LoggedInUser } from '../../../../type/account'
import { Dispatch, SetStateAction } from 'react'
import { fetchAvatar } from '../../../../api/letter'

/**
 * LoggedInUser의 friends 안에 지정한 friendId가 있는지 확인하는 함수
 * @param user
 * @param friendId
 * @returns
 */
export function hasFriendWithId(user: LoggedInUser, friendId: number): boolean {
  return user.friends.some((friend) => friend.id === friendId)
}

/**
 * 프로필 이미지 취득
 * @param t
 * @param letter
 * @param setSenderAvatarUrl
 */
export const fetchSenderAvatar = async (
  t: TFunction<'translation', undefined>,
  userId: number,
  setSenderAvatarUrl: Dispatch<SetStateAction<string>>,
) => {
  await fetchAvatar(t, userId, setSenderAvatarUrl)
}
