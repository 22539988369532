import styles from './style.module.scss'
import backgroundImg from '../../../media/img/login_background.jpeg'
import TextInput from '../../UI/atoms/TextInput'
import TextBox from '../../UI/molecules/TextBox'
import { useState } from 'react'
import Button from '../../UI/atoms/Button'
import { postSlackSendContact } from '../../../api/slack'
import { SendContact } from '../../../type/slack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { constants } from '../../../constants'

const { LOCATION } = constants

export const imgStyle = {
  background: `url(${backgroundImg})`,
  backgroundSize: 'cover',
  height: '100%',
  backgroundRepeat: 'no-repeat',
  filter: 'blur(14px)',
  backdropFilter: 'blur(14px)',
  WebkitBackdropFilter: 'blur(14px)',
  WebkitFilter: 'blur(10px)',
  transform: 'scale(1.07)',
} as const

function ContactUs() {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const sendToSlack = async () => {
    const contactObj: SendContact = {
      name,
      email,
      message,
    }
    await postSlackSendContact(t, contactObj)
    navigate(LOCATION.PATH.CONTACT_COMPLETE)
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    await sendToSlack()
  }

  return (
    <div className={styles.container}>
      <div style={imgStyle}></div>

      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_form}>
          <div className={styles.container_wrap_form_title}>
            {t(`page.contact-us.title`)}
          </div>
          <div className={styles.container_wrap_form_desc}>
            <span>{t(`page.contact-us.label.description`)}</span>
          </div>

          <form onSubmit={handleSubmit}>
            <div className={styles.container_wrap_form_body}>
              <TextInput
                type="text"
                placeholder={t(`page.regist-confirm.label.user-name`)}
                onChange={setName}
                required={true}
              />
              <TextInput
                type="email"
                placeholder={t(`page.regist-confirm.label.email`)}
                onChange={setEmail}
                required={true}
              />
              <div className={styles.container_wrap_form_body_text}>
                <TextBox
                  setInputText={setMessage}
                  maxLength={500}
                  height={100}
                  required={true}
                />
              </div>
            </div>
            <Button
              btnName={t(`ui.atom.button.send`)}
              disabled={false}
              submit={true}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
