import { useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import defaultUser from '../../../../media/img/default_user.png'
import { fetchSenderAvatar } from '../LetterUser/functions'
import { useTranslation } from 'react-i18next'
import { intersectionOberserverHook } from '../../../../utils/intersectionOberserver'

type LetterUserType = {
  userId: number
  imgWidth: number
  imgHeight: number
}

function Avatar({ userId, imgWidth, imgHeight }: LetterUserType) {
  const { t } = useTranslation()
  const [senderAvatarUrl, setSenderAvatarUrl] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false) // 실제 화면에 보여지고 있는지 여부를 확인
  const imgRef = useRef<HTMLImageElement>(null) // 이미지 태그 요소
  const observer = useRef<IntersectionObserver>()

  const showAvatar = () => {
    return isLoading ? senderAvatarUrl : defaultUser
  }

  useEffect(() => {
    // useEffect에서 isLoading를 감시하고 isLoading가 true가 되면 프로필 이미지를 불러온다
    if (isLoading) {
      fetchSenderAvatar(t, userId, setSenderAvatarUrl)
    }

    // 스크롤의 현재 위치를 파악해서 이미지를 보여줄 곳이 아직 화면에 보여지지 않는 영역이라면 setIsLoading을 true로 한다
    intersectionOberserverHook(observer, imgRef, setIsLoading)
  }, [isLoading])
  return (
    <div className={styles.container}>
      <div
        className={styles.container_avatar}
        style={{ width: `${imgWidth}px`, height: `${imgHeight}px` }}
      >
        <div
          ref={imgRef}
          className={styles.container_avatar_inner}
          style={{
            backgroundImage: `url(${showAvatar()})`,
            width: `${imgWidth - 2}px`,
            height: `${imgHeight - 2}px`,
          }}
        ></div>
      </div>
    </div>
  )
}

export default Avatar
