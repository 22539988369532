import { TFunction } from 'i18next'
import { constants } from '../../constants'
import axios from 'axios'
import { API_STATUS } from '../../constants/apiStatus'
import { defineErrorHandler } from '../../plugins/AxiosPlugin'
import { Dispatch, SetStateAction } from 'react'
import { PaymentHistoryResponse } from '../../type/mypage'
import {
  CancelSubscriptionResponse,
  SubscriptionResponseType,
} from '../../type/payment'

const { LOCATION, API_URL } = constants

/**
 * 결제가 성공 되었는지 확인한다
 * @param t
 */
export const checkPayment = async (
  t: TFunction<'translation', undefined>,
  paymentIntent: string,
) => {
  await axios
    .get(API_URL.PATH.CHECK_PAYMENT, { params: { paymentIntent } })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        window.location.href = LOCATION.PATH.LOGIN
      }
      if (errorStatus === API_STATUS.UNPROCESSABLE_ENTITY) {
        alert(t(`page.my-page.error-msg.payment-failed`))
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * Payment DB에 구매 내역을 추가한다
 * @param t
 */
export const updatePayment = async (
  t: TFunction<'translation', undefined>,
  subscriptionId: string,
) => {
  await axios.post(API_URL.PATH.PAYMENT, { subscriptionId }).catch((err) => {
    const errorStatus = err.response.status
    const errConstant = defineErrorHandler(errorStatus, t)
    alert(errConstant)
    return
  })
}

/**
 * 신규 구독 추가하기
 * @param t
 */
export const createSubscription = async (
  t: TFunction<'translation', undefined>,
) => {
  return await axios
    .post<SubscriptionResponseType>(API_URL.PATH.CREATE_SUBSCRIPTION)
    .then(({ data }) => {
      return data
    })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        window.location.href = LOCATION.PATH.LOGIN
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 구독 취소하기
 * @param t
 * @returns
 */
export const postCancelSubscription = async (
  t: TFunction<'translation', undefined>,
) => {
  return await axios
    .post<CancelSubscriptionResponse>(API_URL.PATH.CANCEL_SUBSCRIPTION)
    .then(({ data }) => {
      console.log('data : ', data)
      return data.subscriptionStatus
    })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        window.location.href = LOCATION.PATH.LOGIN
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 멤버십 결제 성공후 subscriptionId의 추가 & DB플러그 업데이트를 하는 API
 * @param t
 */
export const upateRegisterMembership = async (
  t: TFunction<'translation', undefined>,
  subscriptionId: string,
) => {
  await axios
    .post(API_URL.PATH.UPDATE_REGIST_MEMBERSHIP, { subscriptionId })
    .catch((err) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}
