export const constants = {
  LOCATION: {
    PATH: {
      HOME: '/',
      LOGIN: '/login',
      TERMS_OF_USE: '/terms-of-use',
      REGIST_ACCOUNT: '/regist-account',
      REGIST_CONFIRM: '/regist-confirm',
      FORGET_PASSWORD: '/forget-password',
      RESET_PASSWORD: '/reset-password',
      CHANGE_PASSWORD: '/change-password',
      REGIST_COMPLETE: '/regist-complete',
      MY_PAGE: '/mypage',
      RECEIVING_TRAY: '/receiving-tray',
      SEND_TRAY: '/send-tray',
      REGISTER_MEMBERSHIP: '/register-membership',
      ADD_FRIENDS: '/add-friends',
      CANCEL_MEMBERSHIP_COMPLETE: '/cancel-membership-complete',
      LETTER_DETAIL: '/letter-detail',
      SUBSCRIPT_COMPLETE: '/subscript-complete',
      CONTACT: '/contact-us',
      HOW_TO_USE: '/how-to-use',
      CONTACT_COMPLETE: '/contact-us-complete',
      SPECIFIED_COMMERCIAL_TRANSACTION_ACT:
        '/specified-commercial-transaction-act',
    },
    ROUTE_NAME: {
      HOME: 'home',
      LOGIN: 'login',
      TERMS_OF_USE: 'termsOfUse',
      REGIST_ACCOUNT: 'registAccount',
      REGIST_CONFIRM: 'registConfirm',
      FORGET_PASSWORD: 'forgetPassword',
      RESET_PASSWORD: 'resetPassword',
      CHANGE_PASSWORD: 'changePassword',
      REGIST_COMPLETE: 'registComplete',
      MY_PAGE: 'mypage',
      RECEIVING_TRAY: 'receivingTray',
      SEND_TRAY: 'sendTray',
      REGISTER_MEMBERSHIP: 'registerMembership',
      ADD_FRIENDS: 'addFriends',
      CANCEL_MEMBERSHIP_COMPLETE: 'cancelMembershipComplete',
      LETTER_DETAIL: 'letterDetail',
      SUBSCRIPT_COMPLETE: 'subscriptComplete',
      CONTACT: 'contact',
      HOW_TO_USE: 'howToUse',
      CONTACT_COMPLETE: 'contactUsComplete',
      SPECIFIED_COMMERCIAL_TRANSACTION_ACT: 'specifiedCommercialTransactionAct',
    },
  },
  API_URL: {
    PATH: {
      REGIST_ACCOUNT: '/api/regist_account',
      LOGIN: '/api/login',
      LOGOUT: '/api/logout',
      LOGIN_CHECK: '/api/login_check',
      REGIST_LETTER: '/api/regist_letter',
      FETCH_LETTERS: '/api/letters',
      RECEIVING_LETTERS: '/api/receiving_letters/:loggedInUserId',
      SEND_LETTERS: '/api/send_letters/:loggedInUserId',
      LETTER_DETAIL: '/api/letter_detail',
      LETTER_VIEWS_UPDATE: '/api/letter_views_update',
      REPLY_LETTER: '/api/reply/letter',
      UPDATE_ACCOUNT: '/api/update_account',
      UPDATE_AVATAR: '/api/update_avatar',
      CHECKING_PASSWORD: '/api/checking_password',
      PASSWORD_CHANGE: '/api/password_change',
      DUPLICATE_EMAIL: '/api/duplicate_email',
      AUTHENTICATION_CODE: '/api/authentication_email',
      SEND_RESET_PASSWORD_MAIL: '/api/reset_password/send_mail',
      CHECK_RESET_TOKEN: '/api/reset_token',
      RESET_PASSWORD: '/api/reset_password',
      CHECK_PAYMENT: '/api/check_payment',
      PAYMENT: '/api/payment',
      FRIEND: '/api/friend',
      QRCODE_TOKEN: '/api/qrcode_token',
      CHECK_QRCODE_TOKEN: '/api/check_qrcode_token',
      CANCEL_MEMBERSHIP: '/api/cancel_membership',
      REACT_ENV: '/api/react_env',
      TERMS_OF_USE: '/api/terms_of_use',
      AVATAR: '/api/avatar',
      COMMENT: '/api/comment',
      COMMENT_FAVORITE: '/api/comment_favorite',
      UPDATE_REGIST_MEMBERSHIP: '/api/upate_register_membership',
      PORTAL_SESSION: '/api/portal_session',
      CREATE_SUBSCRIPTION: '/api/create-subscription',
      CANCEL_SUBSCRIPTION: '/api/cancel_subscription',
      SLACK_SEND_CONTACT: '/api/slack_send_contact',
    },
  },
  LETTER: {
    TYPE: {
      RANDOM: 0,
      CLASS1: 1,
      CLASS2: 2,
      CLASS3: 3,
      LEVEL4: 4,
      CLASS5: 5,
      CLASS6: 6,
      CLASS7: 7,
      CLASS8: 8,
      CLASS9: 9,
      CLASS10: 10,
    },
  },
  MENU: {
    CLICK_NUMBER: {
      RECEIVING_TRAY: 1,
      SEND_TRAY: 2,
      MY_PAGE: 3,
      HOW_TO_USE: 4,
      STORE: 5,
    },
  },
  MAX_INPUT_LENGTH: 200, // 편지의 최대 입력 글자 수
  COMMENT_MAX_INPUT_LENGTH: 200, // 댓글의 최대 입력 글자 수
  MIN_INPUT_LENGTH: 10, // 편지의 최소 입력 글자 수
  INPUT_BOX_DEFAULT_HEIGHT: 100, // 편지 인풋박스의 기본 높이
  // 10분 간격으로 탑 페이지의 편지들을 불러오는 API를 콜하기 위한 타이머(단위: 밀리초)
  ALL_LETTER_MAX_MILLI_SECOND: 600000,
  PAYMENT: {
    STATUS: {
      /** 구매 전 */
      BEFORE_PURCHASE: 0,
      /** 구매 완료 */
      SUCCESS: 1,
      /** 캔슬 */
      CANCEL: 2,
    },
  },
} as const
