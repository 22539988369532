import { useTranslation } from 'react-i18next'
import TextInput from '../../atoms/TextInput'
import styles from './style.module.scss'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as maptilersdk from '@maptiler/sdk'
import {
  showNoMoveMarkerErrMsg,
  showNoNameErrMsg,
} from '../../../../utils/errorMsg'
import { ProfileEditPayload } from '../../../../type/mypage'
import {
  handleImageChange,
  updateDescription,
  updateName,
  updateProfileImg,
  useMapEffect,
} from './functions'
import { Context } from '../../../../Root'
import { Options } from 'browser-image-compression'
import defaultUser from '../../../../media/img/default_user.png'

type ProfileEditType = {
  profileEditPayload: ProfileEditPayload
  setAvatarFile: Dispatch<SetStateAction<File | null>>
  imageCompression(image: File, options: Options): Promise<File>
  setShowSaveBtn: Dispatch<SetStateAction<boolean>>
}

function ProfileEdit({
  profileEditPayload,
  setAvatarFile,
  imageCompression,
  setShowSaveBtn,
}: ProfileEditType) {
  const { t } = useTranslation()
  const mapContainer = useRef<HTMLDivElement>(null)
  const profileInputImg = useRef<HTMLInputElement>(null)
  const { reactEnv } = useContext(Context)
  const map = useRef<maptilersdk.Map | null>(null)
  const [avatar, setAvatar] = useState<string | undefined>(undefined) // 이미지를 저장할 state 추가
  const [isImgTypeErr, setIsImgTypeErr] = useState(false)
  const [isImgSizeErr, setIsImgSizeErr] = useState(false)

  useEffect(
    useMapEffect(profileEditPayload, mapContainer, map, reactEnv, setAvatar),
    [],
  )

  // DB에 아바타 사진이 없다면 기본 이미지를 보여준다
  const showUserImg = () => {
    return avatar ? avatar : defaultUser
  }

  return (
    <div className={styles.container}>
      <p>{t(`page.regist-account.page-name`)}</p>
      <div className={styles.container_form}>
        <div className={styles.container_form_profile}>
          <div className={styles.container_form_profile_img}>
            <div
              className={styles.container_form_profile_img_inner}
              style={{ backgroundImage: `url(${showUserImg()})` }} // 배경 이미지로 설정합니다.
              onClick={() => updateProfileImg(profileInputImg)}
            >
              <div className={styles.container_form_profile_img_inner_default}>
                {t(`ui.atom.button.img-change`)}
              </div>
            </div>
            <input
              type="file"
              ref={profileInputImg}
              style={{ display: 'none' }}
              onChange={(e) =>
                handleImageChange(
                  e,
                  setAvatar,
                  setIsImgTypeErr,
                  setIsImgSizeErr,
                  setAvatarFile,
                  imageCompression,
                  setShowSaveBtn,
                )
              } // input 파일이 변경될 때 실행되는 함수를 설정합니다.
            ></input>
          </div>
        </div>
        <div className={styles.container_form_text}>
          <div>
            <span>
              <strong>{t(`page.regist-account.label.user-name`)}</strong>
            </span>
            <TextInput
              type="text"
              placeholder={t(`ui.atom.placeholder.user-name`)}
              onChange={updateName(
                profileEditPayload.setAccount,
                profileEditPayload.setNameState,
              )}
              value={profileEditPayload.account.name}
            />
            {showNoNameErrMsg(profileEditPayload.nameState, t)}
          </div>
          <div>
            <strong>{t(`page.regist-confirm.label.description`)}</strong>
            <TextInput
              type="text"
              value={profileEditPayload.account.description}
              onChange={updateDescription(profileEditPayload.setAccount)}
              disabled={false}
            />
          </div>
        </div>
      </div>
      {isImgTypeErr ? (
        <div className={styles.container_form_profile_img_inner_err}>
          {t(`page.my-page.error-msg.avatar-upload-type-err`)}
        </div>
      ) : (
        ''
      )}
      {isImgSizeErr ? (
        <div className={styles.container_form_profile_img_inner_err}>
          {t(`page.my-page.error-msg.avatar-upload-size-err`)}
        </div>
      ) : (
        ''
      )}
      <span>
        <p>
          <strong>
            {t(`page.regist-account.label.move-the-marker-to-your-place`)}
          </strong>
        </p>
      </span>
      <div className={styles.container_form_wrap}>
        <div ref={mapContainer} className={styles.container_form_wrap_map} />
      </div>
      {showNoMoveMarkerErrMsg(profileEditPayload.mapMarkerState, t)}
    </div>
  )
}

export default ProfileEdit
