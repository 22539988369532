import { TFunction } from 'i18next'
import { format } from 'date-fns-tz'
import {
  PaymentHistoryResponse,
  PaymentHistoryTransformDatas,
} from '../../type/mypage'
import { constants } from '../../constants/index'

const { PAYMENT } = constants

/**
 * 결제 상태를 문자로 변환하는 함수
 * @param t
 * @param status
 * @returns
 */
const showPaymentStatusTitle = (
  t: TFunction<'translation', undefined>,
  status: number,
) => {
  switch (status) {
    case PAYMENT.STATUS.BEFORE_PURCHASE:
      return t(`page.my-page.label.before-purchase`)
    case PAYMENT.STATUS.SUCCESS:
      return t(`page.my-page.label.success`)
    case PAYMENT.STATUS.CANCEL:
      return t(`page.my-page.label.cancel`)
    default:
      return ''
  }
}

/**
 * 백엔드에서 받아온 구매이력 데이터에 각 항목에 타이틀을 추가하는 함수
 * @param data
 * @returns
 */
export const transformPaymentHistory = (
  t: TFunction<'translation', undefined>,
  data: PaymentHistoryResponse,
): PaymentHistoryTransformDatas => {
  return data.map((item) => ({
    id: {
      title: t(`page.my-page.label.payment-id`),
      paymentId: item.id,
    },
    letterType: {
      title: t(`page.my-page.label.latter-type`),
      typeName: item.letterType,
    },
    quantity: {
      title: t(`page.my-page.label.quantity`),
      detail: item.quantity,
    },
    price: {
      title: t(`page.my-page.label.price`),
      detail: item.price,
    },
    createdAt: {
      title: t(`page.my-page.label.create-at`),
      detail: format(new Date(item.createdAt), t(`util.date-time-format`)),
    },
    status: {
      title: t(`page.my-page.label.status`),
      detail: showPaymentStatusTitle(t, item.status),
    },
  }))
}
