import { IconType } from '../../../../type/button'
import { createIcon } from './funstions'
import styles from './style.module.scss'

type ButtonType = 'white' | 'secondary'

type Button = {
  btnName: string
  disabled: boolean
  submit?: boolean
  type?: ButtonType
  iconType?: IconType
  clickEvent?: () => void
  onSubmit?: () => void
}

function Button({
  btnName,
  disabled,
  type,
  iconType,
  clickEvent,
  onSubmit,
}: Button) {
  const customClass = () => {
    if (disabled) {
      return styles.disabled_button
    }
    if (type === 'white') {
      return styles.white_button
    } else if (type === 'secondary') {
      return styles.secondary_button
    } else {
      return styles.button
    }
  }

  return (
    <button
      className={customClass()}
      onClick={clickEvent}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {iconType ? createIcon(iconType) : btnName}
    </button>
  )
}

export default Button
