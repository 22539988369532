import { API_STATUS } from '../constants/apiStatus'
import { UnauthorizedError } from '../utils/exceptions/UnauthorizedError'
import { NotFoundError } from '../utils/exceptions/NotFoundError'
import { UnprocessableEntityError } from '../utils/exceptions/UnprocessableEntityError'
import { FailedDependencyError } from '../utils/exceptions/FailedDependencyError'
import { InternalServerError } from '../utils/exceptions/InternalServerError'
import { ServiceUnavailableError } from '../utils/exceptions/SerciveUnavailableError'
import { NetworkError } from '../utils/exceptions/NetworkError'
import { UnexpectedServerError } from '../utils/exceptions/UnexpectedServerError'
import { TFunction } from 'i18next'
import { RequestEntityTooLargeError } from '../utils/exceptions/RequestEntityTooLargeError'

export const defineErrorHandler = (
  errStatus: number,
  t: TFunction<'translation', undefined>,
) => {
  switch (errStatus) {
    case API_STATUS.UNAUTHORIZED:
      return new UnauthorizedError(t)
    case API_STATUS.NOT_FOUND:
      return new NotFoundError(t)
    case API_STATUS.UNPROCESSABLE_ENTITY:
      return new UnprocessableEntityError(t)
    case API_STATUS.FAILED_DEPENDENCY:
      return new FailedDependencyError(t)
    case API_STATUS.INTERNAL_SERVER_ERROR:
      return new InternalServerError(t)
    case API_STATUS.SERVICE_UNAVAILABLE:
      return new ServiceUnavailableError(t)
    case API_STATUS.REQUEST_ENTITY_TOO_LARGE:
      return new RequestEntityTooLargeError(t)
    case undefined:
      return new NetworkError(t)
    default:
      return new UnexpectedServerError(t, errStatus)
  }
}
