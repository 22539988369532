import * as maptilersdk from '@maptiler/sdk'
import * as d3 from 'd3'
import { LoggedInUser } from '../../../../type/account'
import { LettersResponse, LetterDetailType } from '../../../../type/letter'
import { ReactEnv } from '../../../../type/react'
import { letterIcons } from '../../../../constants/letterIcons'

type MapDataType = {
  type: string
  features: {
    type: string
    geometry: {
      type: string
      coordinates: [number, number][]
    }
  }[]
}

export type Payload = {
  defaultCoordinate: {
    lng: number
    lat: number
  }
  mapContainer: React.RefObject<HTMLDivElement>
  RangeValue: number
  map: React.MutableRefObject<maptilersdk.Map | null>
  loggedInUser: LoggedInUser | null
  letters: LettersResponse
  reactEnv: ReactEnv
  clickLetter: (letter: LetterDetailType) => void
}

export const customMapEffect = ({
  defaultCoordinate,
  mapContainer,
  RangeValue,
  map,
  loggedInUser,
  letters,
  reactEnv,
  clickLetter,
}: Payload) => {
  if (!map.current) {
    const customCoordinate = loggedInUser
      ? loggedInUser.coordinate
      : [defaultCoordinate.lng, defaultCoordinate.lat]

    const apiKey = reactEnv.reactAppMaptilerApi
    maptilersdk.config.apiKey = apiKey

    map.current = new maptilersdk.Map({
      container: mapContainer.current!,
      style: maptilersdk.MapStyle.BRIGHT.PASTEL, // 맵의 스타일을 변경하는 곳
      center: customCoordinate as [number, number],
      zoom: RangeValue,
      navigationControl: false,
      geolocateControl: false,
    })

    if (loggedInUser) {
      new maptilersdk.Marker({ color: '#FF0000' })
        .setLngLat(customCoordinate as [number, number])
        .addTo(map.current)
    }

    map.current.on('load', async function () {
      try {
        loadOnMap(map)

        letters.forEach((letter) => {
          if (!letter.moveCoordinate.length) return
          const markers = createAirplane(map, letter, clickLetter)
          let i = 0
          const intervalId = setInterval(() => {
            markers?.setLngLat(letter.moveCoordinate[i] as [number, number])
            i++
            if (i >= letter.moveCoordinate.length) {
              clearInterval(intervalId)
            }
          }, 1000)
        })
      } catch (err) {
        console.error('There was a problem with your fetch operation:', err)
      }
    })
  } else {
    // 업데이트된 줌 설정
    map.current.setZoom(RangeValue)
  }
}

/**
 * 지도를 불러온다
 * @param map
 * @returns
 */
const loadOnMap = async (
  map: React.MutableRefObject<maptilersdk.Map | null>,
) => {
  const mapData: MapDataType | undefined = await d3.json(
    'https://docs.maptiler.com/sdk-js/assets/hike.geojson',
  ) // 지도 데이터를 가져온다
  if (!mapData || !map.current) return
  const coordinate = mapData.features[0].geometry.coordinates
  mapData.features[0].geometry.coordinates = [coordinate[0]]

  // add it to the map
  map.current.addSource('trace', { type: 'geojson', data: mapData })
}

/**
 * 飛行機のアイコン描画及びクリック時の動作制御
 * @param marker APIから取得した、一つの飛行機情報
 * @param map mapのReactiveデータ
 * @returns 飛行機markerインスタンス
 */
const createAirplane = (
  map: React.MutableRefObject<maptilersdk.Map | null>,
  letter: LetterDetailType,
  clickLetter: (letter: LetterDetailType) => void,
) => {
  if (!map.current) return
  const parentEl = document.createElement('div')
  const childEl = document.createElement('div')
  childEl.style.backgroundImage = `url(${findObjectByValue(letter.selectedLetterIconId)?.img})`
  childEl.style.width = '30px'
  childEl.style.height = '30px'
  childEl.style.backgroundSize = 'cover'
  childEl.style.transform = `rotate(${letter.sinAngle}deg)`
  childEl.style.webkitTransform = `rotate(${letter.sinAngle}deg)`

  // 비행기를 클릭했을 때 발화
  parentEl.addEventListener('click', function () {
    clickLetter(letter)
  })

  parentEl.appendChild(childEl)

  return new maptilersdk.Marker({ element: parentEl })
    .setLngLat(letter.moveCoordinate[0] as [number, number])
    .addTo(map.current)
}

const findObjectByValue = (value: number) => {
  return letterIcons.find((obj) => obj.value === value)
}
