import { atom } from 'recoil'

export const defaultAccount = {
  name: '',
  email: '',
  gender: 1,
  password: '',
  coordinate: [0, 0],
}

export const registAccountState = atom({
  key: 'userAccount',
  default: defaultAccount,
})

// Email 인증 상태
export const emailVerificationStatus = atom({
  key: 'emailVerification',
  default: false,
})
