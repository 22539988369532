import { TFunction } from 'i18next'
import { postComment, postCommentPavorite } from '../../../api/letter'
import { constants } from '../../../constants'
import { LoggedInUser } from '../../../type/account'
import { Dispatch, SetStateAction } from 'react'

const { LOCATION } = constants

/**
 * 댓글 저장하기
 * @param t
 * @param inputComment
 * @param letterId
 */
export const saveComment = async (
  t: TFunction<'translation', undefined>,
  inputComment: string,
  letterId: number,
) => {
  await postComment(t, letterId, inputComment)
  window.location.href = `${LOCATION.PATH.LETTER_DETAIL}?id=${letterId}`
}

/**
 * 댓글의 좋아요 업데이트
 * @param letterId
 * @returns
 */
export const updateFavorite =
  (
    t: TFunction<'translation', undefined>,
    letterId: number,
    loggedInUser: LoggedInUser | null,
    favoriteTogle: boolean,
    setFavoriteTogle: Dispatch<SetStateAction<boolean>>,
  ) =>
  async (commentId: number) => {
    if (loggedInUser) {
      await postCommentPavorite(t, letterId, commentId)
      setFavoriteTogle(!favoriteTogle)
    }
  }
