import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import { LoggedInUser } from '../../../../type/account'
import Button from '../../atoms/Button'
import CustomMap from '../../molecules/CustomMap'

type FriendsType = {
  loggedInUser: LoggedInUser | null
  deleteFriend: (id: number) => void
}

function Friends({ loggedInUser, deleteFriend }: FriendsType) {
  const { t } = useTranslation()

  const createTableBody = () => {
    if (!loggedInUser) return

    return loggedInUser.friends.map((friend, index) => {
      return (
        <tr key={index}>
          {/* <td data-title="No">{index + 1}</td> */}
          <td data-title={t(`ui.organisms.friends.label.name`)}>
            <p>{friend.name}</p>
            {friend.description ? (
              <span>{friend.description}</span>
            ) : (
              <span>{t(`ui.organisms.friends.label.no-desc`)}</span>
            )}
          </td>

          {/* TODO: 편지 보내기 버튼을 어떻게 표시할지 정해야 함 */}
          {/* <td data-title={t(`ui.organisms.friends.label.send`)}>
            {loggedInUser.holdingLetters.slower ? (
              <Button
                btnName={t(`ui.organisms.friends.label.send`)}
                iconType="paper-plane"
                disabled={false}
              />
            ) : (
              <span>{t(`ui.organisms.friends.label.no-letter`)}</span>
            )}
          </td> */}
          <td>
            <CustomMap friend={friend} />
          </td>
          <td data-title={t(`ui.organisms.friends.label.delete`)}>
            <Button
              btnName={t(`ui.organisms.friends.label.delete`)}
              disabled={false}
              type="secondary"
              iconType="trash-can"
              clickEvent={() => deleteFriend(friend.id)}
            />
          </td>
        </tr>
      )
    })
  }
  return (
    <div className={styles.container}>
      <p>{t(`ui.organisms.friends.title`)}</p>
      {loggedInUser?.friends.length ? (
        <table>
          <colgroup>
            {/* <col style={{ width: '10%' }} /> */}
            <col style={{ width: '70%' }} />
            {/* <col style={{ width: '10%' }} /> */}
            <col style={{ width: 'auto' }} />
            <col style={{ width: '14%' }} />
          </colgroup>
          <thead>
            <tr>
              {/* <th>No</th> */}
              <th>{t(`ui.organisms.friends.label.name`)}</th>
              {/* <th>{t(`ui.organisms.friends.label.send`)}</th> */}
              <th>{t(`ui.organisms.friends.label.location`)}</th>
              <th>{t(`ui.organisms.friends.label.delete`)}</th>
            </tr>
          </thead>
          <tbody>{createTableBody()}</tbody>
        </table>
      ) : (
        <div className={styles.container_no_friends}>
          <p>{t(`ui.organisms.friends.label.no-friends`)}</p>
        </div>
      )}
    </div>
  )
}

export default Friends
