import { TFunction } from 'i18next'
import { ApiError } from '.'

/** メンテナンス中 */
export class ServiceUnavailableError extends ApiError {
  constructor(t: TFunction<'translation', undefined>) {
    super(
      'SerciveUnavailableError',
      t(`api-error-msg.sercive-unavailable-error`),
    )
  }
}
