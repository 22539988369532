import { useTranslation } from 'react-i18next'
import { FriendInfo } from '../../../../type/account'
import styles from './style.module.scss'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'

type UserSelectType = {
  friends: FriendInfo[]
  setSelectedReceiveUserId: Dispatch<SetStateAction<number>>
}

function UserSelect({ friends, setSelectedReceiveUserId }: UserSelectType) {
  const { t } = useTranslation()
  const handleEvent = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(e.currentTarget.value)
    setSelectedReceiveUserId(selectedId)
  }

  const createOptions = () => {
    if (friends) {
      return friends.map((option, index) => {
        return (
          <option value={option.id} key={index}>
            {option.name}
          </option>
        )
      })
    }
    return ''
  }

  return (
    <div className={styles.container}>
      <p>{t(`ui.molecules.user-select.label.title`)}</p>
      <div className={styles.container_content}>
        <select onChange={(e) => handleEvent(e)} defaultValue={0}>
          <option value={0}>Random</option>
          {createOptions()}
        </select>
      </div>
    </div>
  )
}

export default UserSelect
