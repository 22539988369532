import { useEffect, useState } from 'react'
import styles from './style.module.scss'
import defaultUser from '../../../../media/img/default_user.png'
import { LoggedInUser } from '../../../../type/account'
import { LetterComment } from '../../../../type/letter'
import { fetchAvatar } from '../../../../api/letter'
import { useTranslation } from 'react-i18next'
import { calculateTimeAgo } from '../../../../utils/date'
import i18n from '../../../../locales/i18n'
import Avatar from '../Avatar'

type CommentUserType = {
  loggedInUser: LoggedInUser | null
  comment: LetterComment
  updateFavorite: (commentId: number) => void
}

function CommentUser({
  loggedInUser,
  comment,
  updateFavorite,
}: CommentUserType) {
  const { t } = useTranslation()
  const [senderAvatarUrl, setSenderAvatarUrl] = useState('')
  const [timeAgo, setTimeAgo] = useState('')
  // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
  // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
  const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
  const lang = localStorage.getItem('language') ?? localeLanguege

  useEffect(() => {
    if (loggedInUser) {
      fetchAvatar(t, comment.userId, setSenderAvatarUrl)
    }

    i18n.changeLanguage(lang)
    calculateTimeAgo(t, comment.createdAt, setTimeAgo)
  }, [lang])

  const showAvatar = () => {
    return senderAvatarUrl ? senderAvatarUrl : defaultUser
  }

  const switchHeartIcon = () => {
    if (comment.favorite.includes(loggedInUser?.id ?? 0)) {
      return (
        <span style={{ color: '#ff6d96' }}>
          <i className={`fa-solid fa-heart`}></i>
        </span>
      )
    } else {
      return (
        <span>
          <i className="fa-solid fa-heart"></i>
        </span>
      )
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.container_info}>
        <div className={styles.container_info_wrap}>
          <Avatar userId={comment.userId} imgWidth={40} imgHeight={40} />
          <div className={styles.container_info_wrap_desc}>
            <div className={styles.container_info_wrap_desc_text}>
              <div className={styles.container_info_wrap_desc_text_detail}>
                <div
                  className={styles.container_info_wrap_desc_text_detail_user}
                >
                  <p>{comment.userName}</p> <span>{timeAgo}</span>
                </div>

                <span>{comment.comment}</span>
              </div>
            </div>
            <div className={styles.container_info_wrap_desc_buttons}>
              <div onClick={() => updateFavorite(comment.id)}>
                {switchHeartIcon()}
                <span>{comment.favorite.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentUser
