import { useContext, useEffect, useRef, useState } from 'react'
import * as maptilersdk from '@maptiler/sdk'
import styles from './style.module.scss'
import { FriendInfo } from '../../../../type/account'
import { Context } from '../../../../Root'

type CustomMapType = {
  friend: FriendInfo
}

function CutomMap({ friend }: CustomMapType) {
  const { reactEnv } = useContext(Context)
  const mapContainer = useRef<HTMLDivElement>(null)
  const [zoom] = useState(1)
  const map = useRef<maptilersdk.Map | null>(null)

  // Maptiler apikey를 불러온다
  const apiKey = reactEnv.reactAppMaptilerApi
  maptilersdk.config.apiKey = apiKey

  useEffect(() => {
    map.current = new maptilersdk.Map({
      container: mapContainer.current!,
      style: maptilersdk.MapStyle.OUTDOOR,
      center: friend.coordinate as [number, number],
      zoom: zoom,
      interactive: false,
      navigationControl: false,
      geolocateControl: false,
    })
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.container_wrap}>
        <div ref={mapContainer} className={styles.container_wrap_map} />
      </div>
      <div className={styles.container_marker}></div>
      <div className={styles.container_marker_animation}></div>
      <div className={styles.container_marker_animation_second}></div>
    </div>
  )
}

export default CutomMap
