import { useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import Button from '../../../UI/atoms/Button'
import { useNavigate } from 'react-router-dom'
import * as maptilersdk from '@maptiler/sdk'
import {
  goToRegistConfirm,
  registAccountEffect,
  updateName,
  updateEmail,
  updateStatePassword,
  checkPassword,
  goToBack,
  clickAuthenticationEmail,
  updateGender,
} from './functions'
import TextInput from '../../../UI/atoms/TextInput'
import { useRecoilState } from 'recoil'
import {
  emailVerificationStatus,
  registAccountState,
} from '../../../../store/account'
import {
  showAuthenticationCodeErrMsg,
  showAuthenticationCodeSuccessMsg,
  showEmailValidationErrMsg,
  showInappropriatePasswordErrMsg,
  showNoEmailErrMsg,
  showNoMoveMarkerErrMsg,
  showNoNameErrMsg,
  showNoPasswordMsg,
  showNotAuthentication,
  showPasswordNoMatchErrMsg,
} from '../../../../utils/errorMsg'
import { useTranslation } from 'react-i18next'
import AuthenticationCode from '../../../UI/organisms/AuthenticationCode'
import { DuplicateEmailCheck } from '../../../../type/account'
import backgroundImg from '../../../../media/img/login_background.jpeg'
import { scrollToFirst } from '../../../../utils/domEvent'
import { ReactEnv } from '../../../../type/react'
import { setEnv } from '../../../../utils/authentication/reactEnv'
import RadioButton from '../../../UI/atoms/RadioButton'

function RegistAccount() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const mapContainer = useRef<HTMLDivElement>(null)
  const containerElement = useRef<HTMLDivElement>(null)
  const defaultCoordinate = { lng: 133.753, lat: 36.6844 }
  const [zoom] = useState(1)
  const map = useRef<maptilersdk.Map | null>(null)
  const [account, setAccount] = useRecoilState(registAccountState)
  const [emailVerification, setEmailVerification] = useRecoilState(
    emailVerificationStatus,
  ) // store의 Email 인증 상태
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordNoMatchErrorState, setPasswordNoMatchErrorState] = useState({
    isError: false,
  })
  const [isPasswordState, setPasswordState] = useState({ noPassword: false })
  const [inappropriatePasswordState, setInappropriatePasswordState] = useState({
    inappropriatePassword: false,
  })
  const [mapMarkerState, setMapMarkerState] = useState({ noMoveMarker: false })
  const [emailValidationState, setEmailValidationState] = useState({
    emailValidationErr: false,
  })
  const [emailState, setEmailState] = useState({ noEmail: false })
  const [nameState, setNameState] = useState({ noName: false })
  const [coordinate, setCoordinate] = useState([0, 0])
  const [showAuthenticationCode, setShowAuthenticationCode] = useState(false)
  const [authenticationTimeout, setAuthenticationTimeout] = useState(false) // 인증시간이 초과 되었는지 확인
  const [remainingTime, setRemainingTime] = useState(0) // 남은 시간을 저장할 스테이트
  const [hasDuplicateEmail, setHasDuplicateEmail] =
    useState<DuplicateEmailCheck>({ res: 0 }) // 이메일 중복 상태 0: 중복아님, 1: 중복임
  const [authenticationCodeErr, setAuthenticationCodeErr] = useState({
    isError: false,
  }) // 인증번호 실패했을 때의 확인 플러그
  const [authenticationCodeSuccess, setAuthenticationCodeSuccess] = useState({
    isSuccess: false,
  }) // 인증번호 성공했을 때의 확인 플러그
  const [timerId, setTimerId] = useState<NodeJS.Timer | null>(null) // 인증시간 타이머 ID
  const [hasAuthentication, setHasAuthentication] = useState(false)
  const [customStyle, setCustomStyle] = useState<{
    background: string
    height: string
  }>({
    background: '',
    height: '',
  })
  const [reactEnv, setReactEnv] = useState<ReactEnv>({
    baseUrl: '',
    reactAppMaptilerApi: '',
    stripePublicKey: '',
  }) // react의 env파일을 취득

  const duplicateErrMsg = (
    <div style={{ color: 'red' }}>
      {t(`page.regist-account.error-massage.already-registered-email`)}
    </div>
  )

  // 리엑트에서 필요한 env 취득
  useEffect(() => {
    setEnv(t, setReactEnv)
  }, [])

  useEffect(() => {
    registAccountEffect(
      t,
      account,
      defaultCoordinate,
      mapContainer,
      zoom,
      map,
      setAccount,
      setCoordinate,
      setMapMarkerState,
      timerId,
      emailVerification,
      setAuthenticationCodeSuccess,
      reactEnv,
    )
  }, [reactEnv])

  // 에러 메세지가 나오면 스크롤의 높이가 달라지기 때문에 그때마다 높이를 다시 계산해서 백그라운드 이미지 높이를 변경시킨다
  useEffect(() => {
    if (!containerElement.current) return
    const containerHeight = containerElement.current.scrollHeight
    const screenHeight = window.screen.height
    const height = Math.max(containerHeight, screenHeight)
    const customStyle = {
      background: `url(${backgroundImg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: `${height + 93}px`, // 여분의 높이 93px를 더 줘서 에러메세지가 나와도 흰 배경이 안보이게 한다
    }
    setCustomStyle(customStyle)
  }, [
    nameState,
    authenticationCodeErr,
    authenticationCodeSuccess,
    emailState,
    emailValidationState,
    hasAuthentication,
    inappropriatePasswordState,
    passwordNoMatchErrorState,
    isPasswordState,
    mapMarkerState,
  ])

  useEffect(() => {
    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.background_img} style={customStyle}></div>
      <div className={styles.container_wrap} ref={containerElement}>
        <div className={styles.container_wrap_form}>
          <h1>{t(`page.regist-account.page-name`)}</h1>
          <div className={styles.container_wrap_form_inputs}>
            <div>
              <span>
                <strong>{t(`page.regist-account.label.user-name`)}</strong>
              </span>
              <TextInput
                type="text"
                placeholder={t(`ui.atom.placeholder.user-name`)}
                onChange={updateName(setAccount, setNameState)}
                value={account.name}
              />
              {showNoNameErrMsg(nameState, t)}
            </div>
            <div>
              <span>
                <strong>{t(`page.regist-account.label.gender`)}</strong>
              </span>
              <div className={styles.container_wrap_form_inputs_gender}>
                <RadioButton
                  id={1}
                  value={1}
                  labelName={t(`ui.atom.radio-button.female`)}
                  width={80}
                  height={40}
                  selectedId={account.gender}
                  disabled={false}
                  changeValue={(value) => updateGender(value, setAccount)}
                />
                <RadioButton
                  id={2}
                  value={2}
                  labelName={t(`ui.atom.radio-button.male`)}
                  width={80}
                  height={40}
                  selectedId={account.gender}
                  disabled={false}
                  changeValue={(value) => updateGender(value, setAccount)}
                />
              </div>
            </div>
            <div>
              <span>
                <strong>{t(`page.regist-account.label.email`)}</strong>
              </span>
              <div className={styles.container_wrap_form_inputs_email}>
                <div className={styles.container_wrap_form_inputs_email_input}>
                  <TextInput
                    type="email"
                    placeholder={t(`ui.atom.placeholder.email`)}
                    onChange={updateEmail(
                      setAccount,
                      setEmailValidationState,
                      setEmailState,
                    )}
                    disabled={
                      authenticationTimeout &&
                      !hasDuplicateEmail.res &&
                      !authenticationCodeSuccess.isSuccess
                    }
                    value={account.email}
                  />
                </div>
                <div className={styles.container_wrap_form_inputs_email_btn}>
                  {/* 이메일 인증 버튼은 입력한 이메일이 중복되었거나 인증코드 입력시간 일때만 비 활성화 하도록 한다 */}
                  <Button
                    btnName={t(`ui.atom.button.email-authentication`)}
                    type="secondary"
                    disabled={
                      authenticationTimeout &&
                      !hasDuplicateEmail.res &&
                      !authenticationCodeSuccess.isSuccess
                    }
                    clickEvent={() =>
                      clickAuthenticationEmail(
                        t,
                        account,
                        setEmailValidationState,
                        setEmailState,
                        setShowAuthenticationCode,
                        setRemainingTime,
                        setAuthenticationTimeout,
                        setHasDuplicateEmail,
                        setAuthenticationCodeSuccess,
                        setTimerId,
                        setHasAuthentication,
                        setAuthenticationCodeErr,
                      )
                    }
                  />
                </div>
              </div>
              {showAuthenticationCode &&
              !hasDuplicateEmail.res &&
              !authenticationCodeSuccess.isSuccess ? (
                <AuthenticationCode
                  remainingTime={remainingTime}
                  authenticationCodeSuccess={authenticationCodeSuccess}
                  setAuthenticationCodeSuccess={setAuthenticationCodeSuccess}
                  setAuthenticationCodeErr={setAuthenticationCodeErr}
                  authenticationTimeout={authenticationTimeout}
                  setAuthenticationTimeout={setAuthenticationTimeout}
                  timerId={timerId}
                  setEmailVerification={setEmailVerification}
                />
              ) : (
                <div></div>
              )}
              {showAuthenticationCodeErrMsg(authenticationCodeErr, t)}
              {showAuthenticationCodeSuccessMsg(authenticationCodeSuccess, t)}
              {showNoEmailErrMsg(emailState, t)}
              {showEmailValidationErrMsg(emailValidationState, emailState, t)}
              {hasDuplicateEmail.res ? duplicateErrMsg : ''}
              {showNotAuthentication(hasAuthentication, emailState, t)}
            </div>
            <div>
              <span>
                <strong>{t(`page.regist-account.label.password`)}</strong>
              </span>
              <TextInput
                type="password"
                placeholder={t(`ui.atom.placeholder.password`)}
                onChange={updateStatePassword(
                  setPassword1,
                  setPasswordState,
                  setInappropriatePasswordState,
                )}
              />
              {showInappropriatePasswordErrMsg(inappropriatePasswordState, t)}
            </div>
            <div>
              <span>
                <strong>
                  {t(`page.regist-account.label.password-confirm`)}
                </strong>
              </span>
              <TextInput
                type="password"
                placeholder={t(`ui.atom.placeholder.password-confirm`)}
                onChange={checkPassword(
                  password1,
                  setPasswordNoMatchErrorState,
                  setAccount,
                  setPassword2,
                  setPasswordState,
                )}
              />
              {showPasswordNoMatchErrMsg(passwordNoMatchErrorState, t)}
              {showNoPasswordMsg(isPasswordState, t)}
            </div>
          </div>
          <span>
            <p>
              <strong>
                {t(`page.regist-account.label.move-the-marker-to-your-place`)}
              </strong>
            </p>
          </span>
          <div className={styles.container_wrap_form_map_wrap}>
            {reactEnv.reactAppMaptilerApi ? (
              <div
                ref={mapContainer}
                className={styles.container_wrap_form_map_wrap_map}
              />
            ) : (
              ''
            )}
          </div>
          {showNoMoveMarkerErrMsg(mapMarkerState, t)}
          <div className={styles.container_wrap_form_btn}>
            <Button
              btnName={t(`ui.atom.button.confirm`)}
              disabled={false}
              clickEvent={() =>
                goToRegistConfirm(
                  navigate,
                  authenticationCodeSuccess,
                  passwordNoMatchErrorState,
                  isPasswordState,
                  mapMarkerState,
                  account,
                  coordinate,
                  setAccount,
                  setPasswordState,
                  setMapMarkerState,
                  setEmailValidationState,
                  setEmailState,
                  setNameState,
                  setInappropriatePasswordState,
                  setHasAuthentication,
                  setAuthenticationCodeSuccess,
                )
              }
            />
            <Button
              btnName={t(`ui.atom.button.back`)}
              disabled={false}
              type="white"
              clickEvent={() => goToBack(navigate)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistAccount
