import { TFunction } from 'i18next'
import { postAddFriend } from '../../api/friend'

/**
 * 친구추가 버튼을 눌렀을 시 실행
 * @param id 추가할 친구 아이디
 */
export const switchAddPeople = async (
  t: TFunction<'translation', undefined>,
  id: number,
  reloadAccount: () => Promise<void>,
) => {
  await postAddFriend(t, id)
  await reloadAccount()
}
