import { useNavigate } from 'react-router-dom'
import styles from './style.module.scss'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Root'
import { LettersResponse } from '../../../type/letter'
import { constants } from '../../../constants'
import { fetchSendLetters } from '../../../api/letter'
import { useTranslation } from 'react-i18next'
import LetterCard from '../../UI/molecules/letterCard'
import { switchAddPeople } from '../../../utils/friend'

const { LOCATION } = constants

function SendTray() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loggedInUser, reloadAccount } = useContext(Context)
  const [sendLetters, setSendLetters] = useState<LettersResponse | null>(null)
  const userId = loggedInUser?.id ?? 0

  useEffect(() => {
    if (userId) {
      fetchSendLetters(t, userId, setSendLetters)
    } else {
      alert(t(`page.sent-letters.error-massage.no-login`))
      navigate(LOCATION.PATH.LOGIN)
      return
    }
  }, [])

  const letterCards = () => {
    return sendLetters?.length ?? 0 >= 0 ? (
      sendLetters?.map((letter, index) => {
        return (
          <div className={styles.letters_wrap} key={index}>
            <LetterCard
              letter={letter}
              showReplyBtn={false}
              switchAddPeople={(id) => switchAddPeople(t, id, reloadAccount)}
            />
          </div>
        )
      })
    ) : (
      <p>{t(`page.sent-letters.error-massage.no-sent-letters`)}</p>
    )
  }
  return (
    <div className={styles.container}>
      <p>{t(`page.sent-letters.page-name`)}</p>
      <div className={styles.letters}>{letterCards()}</div>
    </div>
  )
}

export default SendTray
