import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import Button from '../../atoms/Button'
import { useNavigate } from 'react-router-dom'
import { constants } from '../../../../constants'

const { LOCATION } = constants

type MembershipInfo = {
  cancelEvent: () => void
}

function MembershipInfo({ cancelEvent }: MembershipInfo) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  /**
   * 구매 버튼을 클릭했을 시 멤버쉽 가입 페이지로 이동한다
   * @param navigate
   */
  const goToMembership = () => {
    navigate(LOCATION.PATH.REGISTER_MEMBERSHIP)
    cancelEvent()
  }
  return (
    <div className={styles.container}>
      <span>{t(`ui.organisms.regist-letter-modal.label.no-membership`)}</span>
      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_btn}>
          <Button
            btnName={t(`ui.atom.button.regist-membership`)}
            disabled={false}
            clickEvent={goToMembership}
          />
        </div>
      </div>
    </div>
  )
}

export default MembershipInfo
