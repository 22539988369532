import { Dispatch, RefObject, SetStateAction } from 'react'

/**
 * 모달 바깥쪽을 클릭해서 닫게 하는 함수
 * @param e
 * @param childElementRef
 * @param cancelEvent
 */
export const clickModalOutSide = (
  e: React.MouseEvent<HTMLElement>,
  childElementRef: RefObject<HTMLDivElement>,
  cancelEvent: () => void,
) => {
  const cardArea = childElementRef.current?.parentElement
  const clickArea = e.target
  if (cardArea === clickArea) {
    cancelEvent()
  }
}

/**
 * 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
 */
export const scrollToFirst = () => {
  window.scrollTo(0, 0)
}

/**
 * 컴포넌트가 마운트될 때와 리사이징될 때마다 가로 길이를 측정
 * @param containerWrapRef
 * @param setContainerWrapWidth
 */
export const handleResize = (
  containerWrapRef: RefObject<HTMLDivElement>,
  setContainerWrapWidth: Dispatch<SetStateAction<number>>,
) => {
  if (containerWrapRef.current) {
    const containerWrapWidth = containerWrapRef.current.offsetWidth
    setContainerWrapWidth(containerWrapWidth)
  }
}
