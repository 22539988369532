import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { constants } from '../../../../constants'
import {
  customEffect,
  handleNewConfirmPasswordEvent,
  handleNewPasswordEvent,
  imgStyle,
  submitBtn,
} from './functions'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../locales/i18n'
import styles from './style.module.scss'
import TextInput from '../../../UI/atoms/TextInput'
import Button from '../../../UI/atoms/Button'
import {
  showInappropriatePasswordErrMsg,
  showNoPasswordMsg,
  showPasswordNoMatchErrMsg,
} from '../../../../utils/errorMsg'
import { scrollToFirst } from '../../../../utils/domEvent'

const { LOCATION } = constants

function ResetPassword() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [query] = useSearchParams()
  const [language, setLanguage] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newConfirmPassword, setNewConfirmPassword] = useState('')
  const [passwordNoMatchErrorState, setPasswordNoMatchErrorState] = useState({
    isError: false,
  })
  const [isPasswordState, setPasswordState] = useState({ noPassword: false })
  const [inappropriatePasswordState, setInappropriatePasswordState] = useState({
    inappropriatePassword: false,
  })
  const token = query.get('token') ?? ''

  const submitPayload = {
    t,
    newPassword,
    newConfirmPassword,
    setPasswordNoMatchErrorState,
    setPasswordState,
    setInappropriatePasswordState,
    navigate,
  }

  useEffect(() => {
    // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
    // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
    const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
    const lang = localStorage.getItem('language') ?? localeLanguege
    setLanguage(lang)
    i18n.changeLanguage(lang)
    customEffect(t, token, navigate)

    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [])
  return (
    <div className={styles.container}>
      <div style={imgStyle}></div>
      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_form}>
          <div
            className={styles.container_wrap_form_arrow}
            onClick={() => {
              navigate(LOCATION.PATH.LOGIN)
            }}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </div>
          <h1>{t(`page.password-reset.page-name`)}</h1>
          <span>{t(`page.password-reset.discription`)}</span>
          <div className={styles.container_wrap_form_inputs}>
            <TextInput
              type="password"
              placeholder={t(`ui.atom.placeholder.new-password`)}
              onChange={handleNewPasswordEvent(setNewPassword)}
            />
            {showPasswordNoMatchErrMsg(passwordNoMatchErrorState, t)}
            {showNoPasswordMsg(isPasswordState, t)}
            {showInappropriatePasswordErrMsg(inappropriatePasswordState, t)}
            <TextInput
              type="password"
              placeholder={t(`ui.atom.placeholder.new-password-confirm`)}
              onChange={handleNewConfirmPasswordEvent(setNewConfirmPassword)}
            />
          </div>
          <div className={styles.container_wrap_form_btn}>
            <Button
              btnName={t(`ui.atom.button.reset-password`)}
              disabled={false}
              clickEvent={() => submitBtn(submitPayload)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
