import styles from './style.module.scss'
import LetterDetailContents from '../../UI/organisms/LetterDetailContents'
import { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Root'
import { switchAddPeople } from '../../../utils/friend'
import { useTranslation } from 'react-i18next'
import { LetterDetailType } from '../../../type/letter'
import { fetchLetter } from '../../../api/letter'
import { useLocation } from 'react-router-dom'
import noLetter from '../../../media/img/no_letter.png'
import { getRemainingDays } from '../../../utils/date'
import { saveComment, updateFavorite } from './functions'

function LetterDetail() {
  const { loggedInUser, reloadAccount } = useContext(Context)
  const { t } = useTranslation()
  const [letterDetail, setLetterDetail] = useState<LetterDetailType | null>(
    null,
  )
  const [inputComment, setInputComment] = useState('')
  const [favoriteTogle, setFavoriteTogle] = useState(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const getLetterId = () => {
    // URL에 letterId를 직접 적었을 경우 편지 상세 페이지가 표시되게끔 함
    return Number(queryParams.get('id')) ?? 0
  }

  const arrivalDate = new Date(letterDetail?.arrivalDate ?? '')
  const nowDate = new Date()
  const remainingDays = getRemainingDays(arrivalDate, nowDate)

  useEffect(() => {
    const letterId = getLetterId()
    fetchLetter(t, letterId, setLetterDetail)
  }, [favoriteTogle])

  return (
    <div className={styles.container}>
      {letterDetail && remainingDays ? (
        <LetterDetailContents
          letter={letterDetail}
          loggedInUser={loggedInUser}
          showReplyBtn={false}
          remainingDays={remainingDays}
          setInputComment={setInputComment}
          inputComment={inputComment}
          updateFavorite={updateFavorite(
            t,
            letterDetail.id,
            loggedInUser,
            favoriteTogle,
            setFavoriteTogle,
          )}
          saveComment={() => saveComment(t, inputComment, getLetterId())}
          switchAddPeople={(id) => switchAddPeople(t, id, reloadAccount)}
        />
      ) : (
        <div className={styles.container_error}>
          <img src={noLetter} alt="" />
          <p>{t(`page.letter-detail.title`)}</p>
        </div>
      )}
    </div>
  )
}

export default LetterDetail
