import { atom } from 'recoil'

export const registLetter = ''
export const registLetterState = atom({
  key: 'registLetter',
  default: registLetter,
})

/** 편지지에 업로드 할 이미지 관련 */
export const backgroundFile = null
export const backgroundFileState = atom<File | null>({
  key: 'backgroundFile',
  default: backgroundFile,
})

export const compressedImageUrl = ''
export const compressedImageUrlState = atom({
  key: 'compressedImageUrl',
  default: compressedImageUrl,
})
