import { Dispatch, SetStateAction } from 'react'
import CommentUser from '../../molecules/CommentUser'
import WriteComment from '../../molecules/WriteComment'
import styles from './style.module.scss'
import { LetterDetailType } from '../../../../type/letter'
import { LoggedInUser } from '../../../../type/account'
import Button from '../../atoms/Button'
import { useNavigate } from 'react-router-dom'
import { goToLogin } from './functions'
import { useTranslation } from 'react-i18next'

type LetterCommentsType = {
  letter: LetterDetailType
  loggedInUser: LoggedInUser | null
  setInputComment: Dispatch<SetStateAction<string>>
  saveComment: () => void
  inputComment: string
  updateFavorite: (commentId: number) => void
}

function LetterComments({
  letter,
  loggedInUser,
  setInputComment,
  saveComment,
  inputComment,
  updateFavorite,
}: LetterCommentsType) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const createCommentUser = () => {
    if (letter.comments.length) {
      return letter.comments.map((comment, index) => (
        <CommentUser
          loggedInUser={loggedInUser}
          comment={comment}
          key={index}
          updateFavorite={updateFavorite}
        />
      ))
    } else {
      return (
        <div className={styles.container_no_comments}>
          <span>{t(`ui.organisms.letter-comments.label.no-comments`)}</span>
          <span>
            {t(`ui.organisms.letter-comments.label.expression-heart`)}
          </span>
          {loggedInUser ? (
            ''
          ) : (
            <div className={styles.container_no_comments_btn}>
              <Button
                btnName={t(`ui.atom.button.login`)}
                disabled={false}
                clickEvent={() => goToLogin(navigate)}
              />
            </div>
          )}
        </div>
      )
    }
  }

  return (
    <div className={styles.container}>
      <p>Comments</p>
      {createCommentUser()}
      {loggedInUser ? (
        <WriteComment
          setInputComment={setInputComment}
          saveComment={saveComment}
          inputComment={inputComment}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default LetterComments
