import { TFunction } from 'i18next'

export const showNoNameErrMsg = (
  nameState: { noName: boolean },
  t: TFunction,
) => {
  const msg = t(`page.regist-account.error-massage.no-name`)
  if (nameState.noName) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

/**
 * Email이 입력 되지 않았을 경우 에러메세지 출력
 * @param emailState
 * @param t
 * @returns
 */
export const showNoEmailErrMsg = (
  emailState: { noEmail: boolean },
  t: TFunction,
) => {
  const msg = t(`page.regist-account.error-massage.no-email`)
  if (emailState.noEmail) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

/**
 * Email이 입력 되었으며 Email형식이 바르지 않았을 경우 에러메세지 출력
 * @param emailValidationState
 * @param t
 * @returns
 */
export const showEmailValidationErrMsg = (
  emailValidationState: {
    emailValidationErr: boolean
  },
  emailState: { noEmail: boolean },
  t: TFunction,
) => {
  const msg = t(`page.regist-account.error-massage.email-verification-failed`)
  if (emailValidationState.emailValidationErr && !emailState.noEmail) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

export const showPasswordNoMatchErrMsg = (
  passwordNoMatchErrorState: {
    isError: boolean
  },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.error-massage.no-match-password`)
  if (passwordNoMatchErrorState.isError) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

export const showNoPasswordMsg = (
  isPasswordState: { noPassword: boolean },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.error-massage.no-password`)
  if (isPasswordState.noPassword) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

export const showNoMoveMarkerErrMsg = (
  mapMarkerState: {
    noMoveMarker: boolean
  },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.error-massage.no-moving-marker`)
  if (mapMarkerState.noMoveMarker) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

export const showInappropriatePasswordErrMsg = (
  inappropriatePasswordState: {
    inappropriatePassword: boolean
  },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.error-massage.password-rule-violation`)
  if (inappropriatePasswordState.inappropriatePassword) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

/**
 * 기존 패스워드과 새로운 패스워드가 달라진 점이 없다면 에러메세지 출력
 */
export const showPasswordChangedErrMsg = (
  sethasPasswordChanged: {
    isError: boolean
  },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.error-massage.has-password-changed`)
  if (sethasPasswordChanged.isError) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

/**
 * 기존 패스워드과 새로운 패스워드가 달라진 점이 없다면 에러메세지 출력
 */
export const showOldPasswordNoMatchErrMsg = (
  hasOldPasswordNoMatch: {
    isError: boolean
  },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.error-massage.has-old-password-no-match`)
  if (hasOldPasswordNoMatch.isError) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

/**
 * 인증코드가 잘못 되었을 시 실행
 * @param authenticationCodeErr
 * @param t
 * @returns
 */
export const showAuthenticationCodeErrMsg = (
  authenticationCodeErr: {
    isError: boolean
  },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.error-massage.authentication-code-err`)
  if (authenticationCodeErr.isError) {
    return <div style={{ color: 'red', fontSize: '12px' }}>{msg}</div>
  }
}

/**
 * 인증코드가 성공 되었을 시 실행
 * @param authenticationCodeSuccess
 * @param t
 * @returns
 */
export const showAuthenticationCodeSuccessMsg = (
  authenticationCodeSuccess: {
    isSuccess: boolean
  },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.success-massage.authentication-code`)
  if (authenticationCodeSuccess.isSuccess) {
    return (
      <div style={{ color: 'green' }}>
        <i className="fa-solid fa-circle-check"></i>
        {msg}
      </div>
    )
  }
}

/**
 * email이 입력 되었으나 인증하지 않았을 경우 에러 메세지를 출력한다
 * @param hasAuthentication
 * @param emailState
 * @param t
 * @returns
 */
export const showNotAuthentication = (
  hasAuthentication: boolean,
  emailState: { noEmail: boolean },
  t: TFunction<'translation', undefined>,
) => {
  const msg = t(`page.regist-account.error-massage.not-authentication`)
  if (hasAuthentication && !emailState.noEmail) {
    return (
      <div style={{ color: 'red', fontSize: '12px' }}>
        <i className="fa-solid fa-circle-check"></i>
        {msg}
      </div>
    )
  }
}
