import { TFunction } from 'i18next'
import { Dispatch, SetStateAction } from 'react'

/**
 * 두 날짜를 비교해서 남은 일수를 구한다
 * @param date1
 * @param date2
 * @returns
 */
export const getRemainingDays = (date1: Date, date2: Date) => {
  // 날짜를 밀리초로 변환하여 차이를 계산합니다.
  const differenceInMilliseconds = date1.getTime() - date2.getTime()
  // 일 단위로 변환하여 올림 처리합니다.
  return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24))
}

/**
 * createdAt을 기반으로 작성된 시간을 계산하여 표시
 * @param t
 * @param createdDate
 * @param setTimeAgo
 */
export const calculateTimeAgo = (
  t: TFunction<'translation', undefined>,
  createdDate: string,
  setTimeAgo: Dispatch<SetStateAction<string>>,
) => {
  const createdAt = new Date(createdDate)
  const now = new Date()
  const diffInDays = Math.floor(
    (now.getTime() - createdAt.getTime()) / (1000 * 3600 * 24),
  )

  if (diffInDays < 1) {
    const diffInSeconds = Math.floor(
      (now.getTime() - createdAt.getTime()) / 1000,
    )
    if (diffInSeconds < 60) {
      setTimeAgo(
        `${diffInSeconds}` + t(`ui.molecules.comment-user.label.seconds-ago`),
      )
    } else if (diffInSeconds < 3600) {
      setTimeAgo(
        `${Math.floor(diffInSeconds / 60)}` +
          t(`ui.molecules.comment-user.label.minutes-ago`),
      )
    } else {
      setTimeAgo(
        `${Math.floor(diffInSeconds / 3600)}` +
          t(`ui.molecules.comment-user.label.hours-ago`),
      )
    }
  } else if (diffInDays < 30) {
    setTimeAgo(`${diffInDays}` + t(`ui.molecules.comment-user.label.days-ago`))
  } else if (diffInDays < 180) {
    setTimeAgo(t(`ui.molecules.comment-user.label.half-a-year-ago`))
  } else if (diffInDays < 365) {
    setTimeAgo(t(`ui.molecules.comment-user.label.a-year-ago`))
  } else {
    const yearsAgo = Math.floor(diffInDays / 365)
    setTimeAgo(`${yearsAgo}` + t(`ui.molecules.comment-user.label.years-ago`))
  }
}
