import axios from 'axios'
import { constants } from '../../constants'
import { TFunction } from 'i18next'
import { API_STATUS } from '../../constants/apiStatus'
import { defineErrorHandler } from '../../plugins/AxiosPlugin'
import { Dispatch, SetStateAction } from 'react'
import { CheckQRCodeToke, QRCodeTokenResponse } from '../../type/friends'

const { LOCATION, API_URL } = constants

/**
 * QR Code로 친구추가를 하거나 친구추가 버튼을 눌렀을 시 실행
 * @param id 추가할 친구 아이디
 */
export const postAddFriend = async (
  t: TFunction<'translation', undefined>,
  friendId: number,
  setIsAddSuccess?: Dispatch<
    SetStateAction<{
      isSuccess: boolean
    }>
  >,
) => {
  await axios
    .post(API_URL.PATH.FRIEND, { friendId })
    .then(() => {
      if (setIsAddSuccess) {
        setIsAddSuccess({ isSuccess: true })
      }
    })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        window.location.href = LOCATION.PATH.LOGIN
        return
      }
      // 이미 등록된 친구일 경우 422에러를 반환하며 이미 등록된 친구라는 메세지를 표시한다
      if (errorStatus === API_STATUS.UNPROCESSABLE_ENTITY) {
        if (setIsAddSuccess) {
          setIsAddSuccess({ isSuccess: false })
        }
        alert(t(`page.add-friends.error.registed-friend`))
        window.location.href = `${LOCATION.PATH.MY_PAGE}?tab-id=3`
        return
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

export const postDeleteFriend = async (
  t: TFunction<'translation', undefined>,
  friendId: number,
) => {
  await axios
    .delete(API_URL.PATH.FRIEND, { params: { friendId } })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        window.location.href = LOCATION.PATH.LOGIN
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * QR code로 친구 추가시 생성한 Token 취득
 * @param t
 */
export const fetchQRCodeToken = async (
  t: TFunction<'translation', undefined>,
  setQRCodeToken: Dispatch<SetStateAction<string>>,
) => {
  await axios
    .get<QRCodeTokenResponse>(API_URL.PATH.QRCODE_TOKEN)
    .then(({ data }) => {
      setQRCodeToken(data.qrCodeToken)
    })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        window.location.href = LOCATION.PATH.LOGIN
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}

/**
 * 프론트에서 넘겨받은 토큰이 유효한지 확인
 * @param t
 * @param token
 */
export const fetchCheckQRToken = async (
  t: TFunction<'translation', undefined>,
  token: string,
  providerId: number,
  setIsMatchToken: Dispatch<
    SetStateAction<{
      isMatch: boolean
    }>
  >,
) => {
  await axios
    .get<CheckQRCodeToke>(API_URL.PATH.CHECK_QRCODE_TOKEN, {
      params: { token, providerId },
    })
    .then(({ data }) => {
      if (!data.isMatchToken) {
        alert(t(`page.add-friends.error.no-token`))
        window.location.href = LOCATION.PATH.HOME
      } else {
        setIsMatchToken({ isMatch: true })
      }
      return
    })
    .catch((err) => {
      const errorStatus = err.response.status
      if (errorStatus === API_STATUS.UNAUTHORIZED) {
        window.location.href = LOCATION.PATH.LOGIN
      }
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}
