import { Dispatch, SetStateAction } from 'react'
import { checkAuthenticationCode } from '../../../../api/account'
import { SetterOrUpdater } from 'recoil'
import { TFunction } from 'i18next'

// 인증번호 확인 버튼을 눌렀을 시 실행
export const handleInputEvent = async (
  inputStr: string,
  timerId: NodeJS.Timer | null,
  setAuthenticationCodeErr: Dispatch<
    SetStateAction<{
      isError: boolean
    }>
  >,
  setAuthenticationCodeSuccess: Dispatch<
    SetStateAction<{
      isSuccess: boolean
    }>
  >,
  setEmailVerification: SetterOrUpdater<boolean>,
  t: TFunction<'translation', undefined>,
) => {
  // 인증에러 초기화
  setAuthenticationCodeErr({ isError: false })
  // 인증번호 확인하기
  const { data } = await checkAuthenticationCode(t, inputStr)
  // 인증번호가 문제가 없다면 타이머 종료
  if (data.certification && timerId) {
    clearInterval(timerId)
  }
  setAuthenticationCodeErr({ isError: !data.certification })
  setAuthenticationCodeSuccess({ isSuccess: data.certification })
  setEmailVerification(data.certification)
}
