import { useEffect, useState } from 'react'
import styles from './style.module.scss'
import i18n from '../../../../locales/i18n'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { constants } from '../../../../constants'
import TextInput from '../../../UI/atoms/TextInput'
import Button from '../../../UI/atoms/Button'
import {
  handleNewConfirmPasswordEvent,
  handleNewPasswordEvent,
  handleOldPasswordEvent,
  submitBtn,
} from './functions'
import {
  showInappropriatePasswordErrMsg,
  showNoPasswordMsg,
  showOldPasswordNoMatchErrMsg,
  showPasswordChangedErrMsg,
  showPasswordNoMatchErrMsg,
} from '../../../../utils/errorMsg'
import { scrollToFirst } from '../../../../utils/domEvent'

const { LOCATION } = constants

function PasswordChange() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [language, setLanguage] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newConfirmPassword, setNewConfirmPassword] = useState('')
  const [passwordNoMatchErrorState, setPasswordNoMatchErrorState] = useState({
    isError: false,
  })
  const [isPasswordState, setPasswordState] = useState({ noPassword: false })
  const [inappropriatePasswordState, setInappropriatePasswordState] = useState({
    inappropriatePassword: false,
  })
  const [hasPasswordChanged, setHasPasswordChanged] = useState({
    isError: false,
  })
  const [hasOldPasswordNoMatch, setHasOldPasswordNoMatch] = useState({
    isError: false,
  })

  const submitPayload = {
    t,
    oldPassword,
    newPassword,
    newConfirmPassword,
    setPasswordNoMatchErrorState,
    setPasswordState,
    setInappropriatePasswordState,
    setHasPasswordChanged,
    setHasOldPasswordNoMatch,
    navigate,
  }
  useEffect(() => {
    // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
    // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
    const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
    const lang = localStorage.getItem('language') ?? localeLanguege
    setLanguage(lang)
    i18n.changeLanguage(lang)

    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.container_wrap}>
        <div className={styles.container_wrap_form}>
          <div className={styles.container_wrap_form_header}>
            <div
              className={styles.container_wrap_form_header_arrow}
              onClick={() => {
                navigate(LOCATION.PATH.MY_PAGE)
              }}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </div>
          </div>

          <h1>{t(`page.password-change.page-name`)}</h1>
          <div className={styles.container_wrap_form_inputs}>
            <TextInput
              type="password"
              placeholder={t(`ui.atom.placeholder.old-password`)}
              onChange={handleOldPasswordEvent(setOldPassword)}
            />
            <TextInput
              type="password"
              placeholder={t(`ui.atom.placeholder.new-password`)}
              onChange={handleNewPasswordEvent(setNewPassword)}
            />
            <TextInput
              type="password"
              placeholder={t(`ui.atom.placeholder.new-password-confirm`)}
              onChange={handleNewConfirmPasswordEvent(setNewConfirmPassword)}
            />
          </div>
          {showPasswordNoMatchErrMsg(passwordNoMatchErrorState, t)}
          {showNoPasswordMsg(isPasswordState, t)}
          {showInappropriatePasswordErrMsg(inappropriatePasswordState, t)}
          {showPasswordChangedErrMsg(hasPasswordChanged, t)}
          {showOldPasswordNoMatchErrMsg(hasOldPasswordNoMatch, t)}
          <div className={styles.container_wrap_form_btn}>
            <Button
              btnName={t(`ui.atom.button.change`)}
              disabled={false}
              clickEvent={() => submitBtn(submitPayload)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordChange
