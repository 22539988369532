import { useContext, useEffect, useState } from 'react'
import RegistLetterFirstStepModal from '../RegistLetterFirstStepModal'
import { Context } from '../../../../Root'
import { sendLetter } from './functions'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import {
  backgroundFileState,
  compressedImageUrlState,
  registLetterState,
} from '../../../../store/letter'
import { asciiToString } from '../../../../utils/letter'
import RegistLetterSecondStepModal from '../RegistLetterSecondStepModal'

type CreatLetterType = {
  msg: string
  cancelEvent: () => void
}

function CreatLetter({ cancelEvent, msg }: CreatLetterType) {
  const { t } = useTranslation()
  const {
    loggedInUser,
    isLetterWriteModal,
    replyUserId,
    setIsLetterWriteModal,
  } = useContext(Context)
  const [noInputErr, setNoInputErr] = useState(false)
  // 편지지를 구매하고 다시 돌아 왔을 때 편지 내용이 사라지지 않도록 하기 위한 조치
  const [registLetter, setRegistLetter] = useRecoilState(registLetterState)
  const [backgroundFile, setBackgroundFile] =
    useRecoilState(backgroundFileState)
  const [compressedImageUrl, setCompressedImageUrl] = useRecoilState(
    compressedImageUrlState,
  )
  const [isFirstStep, setIsFirstStep] = useState(true)
  const [progress, setProgress] = useState(0)
  const [inited, setInited] = useState(false) // 답장하기를 클릭 했을 시 기존 스토어에 있는 데이터를 초기화 했는지 확인하는 플러그
  const [selectedLetterIconId, setSelectedLetterIconId] = useState(0) // 선택한 편지 아이콘 State (default: 0)

  useEffect(() => {
    // selectedType이 null이고 msg가 있다면 URL의 msg를 읽을 수 있는 글자로 변경해서 store에 넣는다
    if (!registLetter && !msg) {
      setRegistLetter(asciiToString(msg))
    }

    // 답장하기를 클릭 했을 시 기존 스토어에 있는 데이터를 초기화 한다
    if (replyUserId) {
      setCompressedImageUrl('')
      setRegistLetter('')
      setBackgroundFile(null)
    }
    setInited(true)
  }, [])

  return (
    <div>
      {isFirstStep && inited ? (
        <RegistLetterFirstStepModal
          loggedInUser={loggedInUser}
          noInputErr={noInputErr}
          cancelEvent={cancelEvent}
          registLetter={registLetter}
          setRegistLetter={setRegistLetter}
          setBackgroundFile={setBackgroundFile}
          setIsFirstStep={setIsFirstStep}
          compressedImageUrl={compressedImageUrl}
          setCompressedImageUrl={setCompressedImageUrl}
          setNoInputErr={setNoInputErr}
          progress={progress}
          setProgress={setProgress}
          selectedLetterIconId={selectedLetterIconId}
          setSelectedLetterIconId={setSelectedLetterIconId}
        />
      ) : (
        ''
      )}
      {!isFirstStep && loggedInUser ? (
        <RegistLetterSecondStepModal
          loggedInUser={loggedInUser}
          cancelEvent={cancelEvent}
          setIsFirstStep={setIsFirstStep}
          registLetter={registLetter}
          sendEvent={(
            registLetter: string,
            selectedLetterType: number,
            selectedReceiveUserId: number,
          ) => {
            sendLetter(
              registLetter,
              selectedLetterType,
              selectedReceiveUserId,
              isLetterWriteModal,
              setIsLetterWriteModal,
              setNoInputErr,
              backgroundFile,
              selectedLetterIconId,
              t,
            )
          }}
          replyUserId={replyUserId}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default CreatLetter
