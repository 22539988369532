import { useEffect, useState } from 'react'
import styles from './style.module.scss'
import Button from '../../../UI/atoms/Button'
import { Link, useNavigate } from 'react-router-dom'
import { onSignup, updateEmail, updatePassword } from './functions'
import { constants } from '../../../../constants'
import TextInput from '../../../UI/atoms/TextInput'
import { imgStyle } from './style'
import {
  showEmailValidationErrMsg,
  showNoEmailErrMsg,
  showNoPasswordMsg,
} from '../../../../utils/errorMsg'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../locales/i18n'
import { scrollToFirst } from '../../../../utils/domEvent'

const { LOCATION } = constants

function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [emailState, setEmailState] = useState('')
  const [passwordState, setPasswordState] = useState('')
  const [emailErrorState, setEmailErrorState] = useState({
    emailValidationErr: false,
  })
  const [noEnteredEmailState, setNoEnteredEmailState] = useState({
    noEmail: false,
  })
  const [isPasswordState, setIsPasswordState] = useState({
    noPassword: false,
  })

  useEffect(() => {
    // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
    // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
    const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
    const lang = localStorage.getItem('language') ?? localeLanguege
    i18n.changeLanguage(lang)

    // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
    scrollToFirst()
  }, [])

  return (
    <div className={styles.container}>
      <div style={imgStyle}></div>
      <div className={styles.container_wrap}>
        <form
          onSubmit={(e) =>
            onSignup(
              e,
              emailState,
              passwordState,
              setEmailErrorState,
              setNoEnteredEmailState,
              setIsPasswordState,
              t,
            )
          }
        >
          <div className={styles.container_wrap_form}>
            <div
              className={styles.container_wrap_form_arrow}
              onClick={() => {
                navigate(LOCATION.PATH.HOME)
              }}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </div>
            <h1>{t(`page.login.page-name`)}</h1>
            <div className={styles.container_wrap_form_inputs}>
              <TextInput
                type="email"
                placeholder={t(`ui.atom.placeholder.email`)}
                onChange={updateEmail(
                  setEmailState,
                  setEmailErrorState,
                  setNoEnteredEmailState,
                )}
              />
              {showEmailValidationErrMsg(
                emailErrorState,
                noEnteredEmailState,
                t,
              )}
              {showNoEmailErrMsg(noEnteredEmailState, t)}
              <TextInput
                type="password"
                placeholder={t(`ui.atom.placeholder.password`)}
                onChange={updatePassword(setPasswordState)}
              />
              {showNoPasswordMsg(isPasswordState, t)}
            </div>
            <div className={styles.container_wrap_form_btn}>
              <Button
                btnName={t(`ui.atom.button.login`)}
                disabled={false}
                submit={true}
              />
            </div>
            <div className={styles.create_account}>
              <Link to={LOCATION.PATH.FORGET_PASSWORD}>
                {t(`page.login.link.forget-password`)}
              </Link>
              <Link to={LOCATION.PATH.TERMS_OF_USE}>
                {t(`page.login.link.register`)}
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
