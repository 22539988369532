import { TFunction } from 'i18next'
import { fetchCheckQRToken, postAddFriend } from '../../../api/friend'
import { Dispatch, SetStateAction } from 'react'

/**
 * QR Code Token유효성 체크
 * @param t
 * @param token
 * @param providerId
 * @param setIsMatchToken
 */
export const checkQRToken = async (
  t: TFunction<'translation', undefined>,
  token: string,
  providerId: number,
  setIsMatchToken: Dispatch<
    SetStateAction<{
      isMatch: boolean
    }>
  >,
) => {
  // QRCode의 토큰이 유효한지 체크
  await fetchCheckQRToken(t, token, providerId, setIsMatchToken)
}

/**
 * QR code로 친구추가
 * @param t
 * @param providerId
 * @param isMatchToken
 * @param setIsAddSuccess
 */
export const addFriend = async (
  t: TFunction<'translation', undefined>,
  providerId: number,
  isMatchToken: {
    isMatch: boolean
  },
  setIsAddSuccess: Dispatch<
    SetStateAction<{
      isSuccess: boolean
    }>
  >,
) => {
  // 토큰에 문제가 없다면 친구추가 작업 시작
  if (isMatchToken.isMatch) {
    await postAddFriend(t, providerId, setIsAddSuccess)
  }
}
