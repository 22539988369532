import { NavigateFunction } from 'react-router-dom'
import { constants } from '../../../../constants'
import * as maptilersdk from '@maptiler/sdk'
import { AccountInfo, DuplicateEmailCheck } from '../../../../type/account'
import { SetterOrUpdater } from 'recoil'
import { checkEmptyValues, checkValidation } from './validation'
import i18n from '../../../../locales/i18n'
import { checkValidEmail } from '../../../../utils/validate'
import { countDown } from '../../../../utils/authentication/authenticationCode'
import {
  checkDuplicateEmail,
  sendAuthenticationCode,
} from '../../../../api/account'
import { TFunction } from 'i18next'
import { scrollToFirst } from '../../../../utils/domEvent'
import { ReactEnv } from '../../../../type/react'
import { Dispatch, SetStateAction } from 'react'

const { LOCATION } = constants

type TokyoType = {
  lng: number
  lat: number
}

export const registAccountEffect = async (
  t: TFunction<'translation', undefined>,
  account: AccountInfo,
  tokyo: TokyoType,
  mapContainer: React.RefObject<HTMLDivElement>,
  zoom: number,
  map: React.MutableRefObject<maptilersdk.Map | null>,
  setAccount: SetterOrUpdater<AccountInfo>,
  setCoordinate: SetterOrUpdater<number[]>,
  setMapMarkerState: React.Dispatch<
    React.SetStateAction<{
      noMoveMarker: boolean
    }>
  >,
  timerId: NodeJS.Timer | null,
  emailVerification: boolean,
  setAuthenticationCodeSuccess: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean
    }>
  >,
  reactEnv: ReactEnv,
) => {
  if (map.current) return // stops map from intializing more than once
  initPassword(setAccount, setCoordinate)

  // 기존에 언어설정을 변경 했다면 로컬스토리지에 저장된 언어 타입을 취득해서 언어를 변경하도록 한다.
  // 단, 로컬스토리지에 언어 설정된 것이 없다면 디폴트로 한국어가 출력 되도록 한다.
  const localeLanguege = Intl.DateTimeFormat().resolvedOptions().locale
  const lang = localStorage.getItem('language') ?? localeLanguege
  i18n.changeLanguage(lang)

  // store에 Email 인증 정보가 있다면 불러온다
  if (emailVerification) {
    setAuthenticationCodeSuccess({ isSuccess: emailVerification })
  }

  // Maptiler apikey를 불러온다
  const apiKey = reactEnv.reactAppMaptilerApi
  maptilersdk.config.apiKey = apiKey

  // 이전에 마크를 이동한 적이 있다면 이동시킨 좌표를 맵에 표시시킨다
  const checkCoordinate = () => {
    const [lng, lat] = account.coordinate
    if (lng === 0 && lat === 0) {
      return [tokyo.lng, tokyo.lat]
    }
    return [lng, lat]
  }
  const nowCoordinate = checkCoordinate() as [number, number]

  map.current = null

  if (reactEnv.reactAppMaptilerApi) {
    map.current = new maptilersdk.Map({
      container: mapContainer.current!,
      style: maptilersdk.MapStyle.OUTDOOR,
      center: nowCoordinate,
      zoom: zoom,
    })
    let marker = new maptilersdk.Marker({
      draggable: true,
    })
      .setLngLat(nowCoordinate)
      .addTo(map.current)

    function onDragEnd() {
      let lngLat = marker.getLngLat()
      setCoordinate([lngLat.lng, lngLat.lat])
      setAccount((prevState) => {
        return Object.assign(
          { ...prevState },
          { coordinate: [lngLat.lng, lngLat.lat] },
        )
      })
      setMapMarkerState((prevState) => {
        return Object.assign({ ...prevState }, { noMoveMarker: false })
      })
    }

    marker.on('dragend', onDragEnd)
  }

  // 컴포넌트가 마운트될 때 화면을 맨 위로 스크롤
  scrollToFirst()

  // 컴포넌트가 해제 되었을 시에 인터벌 타이머 종료
  return () => {
    if (timerId) {
      clearInterval(timerId)
    }
  }
}

// change Name
export const updateName =
  (
    setAccount: SetterOrUpdater<AccountInfo>,
    setNameState: React.Dispatch<
      React.SetStateAction<{
        noName: boolean
      }>
    >,
  ) =>
  (name: string) => {
    setNameState((prevState) => {
      return Object.assign({ ...prevState }, { noName: false })
    })
    setAccount((prevState) => {
      return Object.assign({ ...prevState }, { name: name })
    })
  }

// 성별을 선택했을 시 실행
export const updateGender = (
  value: number,
  setAccount: SetterOrUpdater<AccountInfo>,
) => {
  setAccount((prevState) => {
    return Object.assign({ ...prevState }, { gender: value })
  })
}

// change email
export const updateEmail =
  (
    setAccount: SetterOrUpdater<AccountInfo>,
    setEmailValidationState: React.Dispatch<
      React.SetStateAction<{
        emailValidationErr: boolean
      }>
    >,
    setEmailState: React.Dispatch<
      React.SetStateAction<{
        noEmail: boolean
      }>
    >,
  ) =>
  (email: string) => {
    setEmailValidationState({ emailValidationErr: false })
    setEmailState({ noEmail: false })
    setAccount((prevState) => {
      return Object.assign({ ...prevState }, { email: email })
    })
  }

// update password
export const updateStatePassword =
  (
    setPassword: React.Dispatch<React.SetStateAction<string>>,
    setPasswordState: React.Dispatch<
      React.SetStateAction<{
        noPassword: boolean
      }>
    >,
    setInappropriatePasswordState: React.Dispatch<
      React.SetStateAction<{
        inappropriatePassword: boolean
      }>
    >,
  ) =>
  (password: string) => {
    setInappropriatePasswordState((prevState) => {
      return Object.assign({ ...prevState }, { inappropriatePassword: false })
    })
    setPassword(password)
    setPasswordState((preveState) => {
      return Object.assign({ ...preveState }, { noPassword: false })
    })
  }

export const checkPassword =
  (
    originPassword: string,
    setPasswordNoMatchErrorState: React.Dispatch<
      React.SetStateAction<{
        isError: boolean
      }>
    >,
    setAccount: SetterOrUpdater<AccountInfo>,
    setPassword: React.Dispatch<React.SetStateAction<string>>,
    setPasswordState: React.Dispatch<
      React.SetStateAction<{
        noPassword: boolean
      }>
    >,
  ) =>
  (inputPassword: string) => {
    // Cancels the password error message when entering password confirmation.
    setPasswordState((preveState) => {
      return Object.assign({ ...preveState }, { noPassword: false })
    })
    if (originPassword !== inputPassword) {
      setPasswordNoMatchErrorState({
        isError: true,
      })
    } else {
      setPasswordNoMatchErrorState({
        isError: false,
      })
      setPassword(inputPassword)
      setAccount((prevState) => {
        return Object.assign({ ...prevState }, { password: inputPassword })
      })
      setPasswordState((preveState) => {
        return Object.assign({ ...preveState }, { noPassword: false })
      })
    }
  }

// 입력 확인 버튼을 눌렀을 때 실행
export const goToRegistConfirm = (
  navigate: NavigateFunction,
  authenticationCodeSuccess: {
    isSuccess: boolean
  },
  passwordNoMatchErrorState: {
    isError: boolean
  },
  isPasswordState: {
    noPassword: boolean
  },
  mapMarkerState: {
    noMoveMarker: boolean
  },
  account: AccountInfo,
  coordinate: number[],
  setAccount: SetterOrUpdater<AccountInfo>,
  setPasswordState: React.Dispatch<
    React.SetStateAction<{
      noPassword: boolean
    }>
  >,
  setMapMarkerState: React.Dispatch<
    React.SetStateAction<{
      noMoveMarker: boolean
    }>
  >,
  setEmailValidationState: React.Dispatch<
    React.SetStateAction<{
      emailValidationErr: boolean
    }>
  >,
  setEmailState: React.Dispatch<
    React.SetStateAction<{
      noEmail: boolean
    }>
  >,
  setNameState: React.Dispatch<
    React.SetStateAction<{
      noName: boolean
    }>
  >,
  setInappropriatePasswordState: React.Dispatch<
    React.SetStateAction<{
      inappropriatePassword: boolean
    }>
  >,
  setHasAuthentication: React.Dispatch<React.SetStateAction<boolean>>,
  setAuthenticationCodeSuccess: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean
    }>
  >,
) => {
  const newLng = coordinate[0]
  const newLat = coordinate[1]
  setAccount((prevState) => {
    return Object.assign({ ...prevState }, { coordinate: [newLng, newLat] })
  })

  const isEmpty = checkEmptyValues(
    account,
    setPasswordState,
    setMapMarkerState,
    setNameState,
  )
  const isValidationError = checkValidation(
    account,
    setEmailValidationState,
    setEmailState,
    setInappropriatePasswordState,
  )
  if (!authenticationCodeSuccess.isSuccess) {
    setHasAuthentication(true)
  }
  if (
    !passwordNoMatchErrorState.isError &&
    !isPasswordState.noPassword &&
    !mapMarkerState.noMoveMarker &&
    !isValidationError &&
    !isEmpty &&
    authenticationCodeSuccess.isSuccess
  ) {
    navigate(LOCATION.PATH.REGIST_CONFIRM)
    setAuthenticationCodeSuccess({ isSuccess: false })
  }
}

const initPassword = (
  setAccount: SetterOrUpdater<AccountInfo>,
  setCoordinate: SetterOrUpdater<number[]>,
) => {
  setAccount((prevState) => {
    setCoordinate(prevState.coordinate)
    return Object.assign({ ...prevState }, { password: '' })
  })
}

export const goToBack = (navigate: NavigateFunction) => {
  navigate(LOCATION.PATH.TERMS_OF_USE)
}

/**
 * 이메일 인증버튼 클릭시 실행
 * @param account
 * @param setEmailValidationState
 * @param setEmailState
 * @param setShowAuthenticationCode
 * @returns
 */
export const clickAuthenticationEmail = async (
  t: TFunction<'translation', undefined>,
  account: AccountInfo,
  setEmailValidationState: React.Dispatch<
    React.SetStateAction<{
      emailValidationErr: boolean
    }>
  >,
  setEmailState: React.Dispatch<
    React.SetStateAction<{
      noEmail: boolean
    }>
  >,
  setShowAuthenticationCode: React.Dispatch<React.SetStateAction<boolean>>,
  setRemainingTime: React.Dispatch<React.SetStateAction<number>>,
  setAuthenticationTimeout: React.Dispatch<React.SetStateAction<boolean>>,
  setHasDuplicateEmail: React.Dispatch<
    React.SetStateAction<DuplicateEmailCheck>
  >,
  setAuthenticationCodeSuccess: React.Dispatch<
    React.SetStateAction<{
      isSuccess: boolean
    }>
  >,
  setTimerId: React.Dispatch<React.SetStateAction<NodeJS.Timer | null>>,
  setHasAuthentication: React.Dispatch<React.SetStateAction<boolean>>,
  setAuthenticationCodeErr: React.Dispatch<
    React.SetStateAction<{
      isError: boolean
    }>
  >,
) => {
  // 인증에러 초기화
  setAuthenticationCodeErr({ isError: false })
  if (!account.email) {
    setEmailState({ noEmail: true })
    return
  }
  const isValidEmail = checkValidEmail(account.email as string)
  if (!isValidEmail) {
    setEmailValidationState({ emailValidationErr: true })
    return
  }

  // 기존states 초기화
  setTimerId(null)
  setAuthenticationCodeSuccess({ isSuccess: false })
  setHasAuthentication(false)

  // 입력한 이메일 중복체크하기 0: 중복아님, 1: 중복임
  const DuplicateEmailCheckResponse = await checkDuplicateEmail(
    t,
    account.email,
  )

  // 중복 체크 후 문제 없다면 인증번호 발송API를 호출한다
  if (!DuplicateEmailCheckResponse.res) {
    // 인증번호 발송 후 3분의 타이머를 서버로부터 취득
    const { startTime } = await sendAuthenticationCode(t, account.email)

    const intervalId = countDown(
      startTime,
      setRemainingTime,
      setAuthenticationTimeout,
    )
    setTimerId(intervalId)
    // 위의 이메일 체크에서 문제가 없다면 인증코드 입력 컴포넌트를 표시하도록 함
    setShowAuthenticationCode(true)
  }
  setHasDuplicateEmail(DuplicateEmailCheckResponse)
}
