import { TabDetail } from '../../../../type/mypage'
import styles from './style.module.scss'

type TabType = {
  tab: TabDetail
  selectedTabId: number
  handleTab: (tabId: number) => void
}

function Tab({ tab, selectedTabId, handleTab }: TabType) {
  const handleClick = () => {
    handleTab(tab.tabId)
  }

  const selectedStyle = {
    borderBottom: '3px solid #ff4b7e',
    color: '#ff4b7e',
  }

  const customStyle = () => {
    return selectedTabId === tab.tabId ? selectedStyle : {}
  }
  return (
    <div
      className={styles.container}
      style={customStyle()}
      onClick={handleClick}
    >
      <p>{tab.tabName}</p>
    </div>
  )
}

export default Tab
