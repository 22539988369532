import styles from './style.module.scss'
import Button from '../../UI/atoms/Button/'
import { useTranslation } from 'react-i18next'
import { goToLogin } from '../../../utils/navigation'
import { useNavigate } from 'react-router-dom'

interface SectionProps {
  isEven?: boolean
  text: string
}

function HowToUse() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const Section = ({ isEven, text }: SectionProps) => (
    <div className={`${styles.section} ${isEven ? styles.even : styles.odd}`}>
      <div className={styles.background}></div>
      <div className={styles.text}>{text}</div>
    </div>
  )
  return (
    <div className={styles.container}>
      <Section text={t(`page.how-to-use.title`)} />
      <div className={styles.detail_wrap}>
        <div className={`${styles.detail_wrap_section} ${styles.first_wrap}`}>
          <p className={styles.detail_wrap_section_title}>
            {t(`page.how-to-use.sub-title-1`)}
          </p>
          <span>{t(`page.how-to-use.sub-desc-1`)}</span>
        </div>

        <div className={`${styles.detail_wrap_section} ${styles.second_wrap}`}>
          <p className={styles.detail_wrap_section_title}>
            {t(`page.how-to-use.sub-title-2`)}
          </p>
          <span>{t(`page.how-to-use.sub-desc-2`)}</span>
        </div>

        <div className={`${styles.detail_wrap_section} ${styles.final_wrap}`}>
          <p className={styles.detail_wrap_section_title}>
            {t(`page.how-to-use.sub-title-3`)}
          </p>
          <span>{t(`page.how-to-use.sub-desc-3`)}</span>
        </div>

        <section>
          <div className={styles.detail_wrap_section}>
            <p className={styles.detail_wrap_section_title}>
              {t(`page.how-to-use.sub-title-4`)}
            </p>
            <Button
              btnName={t(`ui.atom.button.login`)}
              type="secondary"
              disabled={false}
              clickEvent={() => goToLogin(navigate)}
            />
          </div>
        </section>
      </div>
    </div>
  )
}

export default HowToUse
