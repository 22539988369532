import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import { constants } from '../../../../constants'

const { INPUT_BOX_DEFAULT_HEIGHT } = constants

type TextBoxType = {
  setInputText: Dispatch<SetStateAction<string>>
  maxLength: number // 최대 글자 수
  registLetter?: string
  height: number
  required?: boolean
}

function TextBox({
  setInputText,
  maxLength,
  registLetter,
  height,
  required,
}: TextBoxType) {
  const inputElement = useRef<HTMLTextAreaElement>(null)
  const [customStyle, setCustomStyle] = useState({})
  const [numNewLines, setNumNewLines] = useState(0)

  // 4줄 이상은 개행 되지 못하도록 막는다
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && numNewLines >= 3) {
      e.preventDefault() // Enter 키의 기본 동작을 막음
    }
  }

  const handleInputEvent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    const newLineCount = (newValue.match(/\n/g) || []).length
    setNumNewLines(newLineCount)

    // 최대 글자 수를 초과하지 않도록 처리
    if (newValue.length <= maxLength) {
      setInputText(newValue)
    } else {
      // 최대 글자 수를 초과하는 경우 이전 값으로 되돌림
      e.target.value = newValue.slice(0, maxLength)
    }
  }

  // 글자를 붙여넣기 했을 시 개행문자를 삭제한다 (4줄 이상 개행 되어있는 텍스트를 붙여넣기 못하게 하기 위함)
  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault() // 붙여넣기 동작의 기본 동작을 막음

    const text = e.clipboardData.getData('text/plain')
    const sanitizedText = text.replace(/\r?\n|\r/g, '') // 개행 문자 제거

    document.execCommand('insertText', false, sanitizedText) // 개행 문자가 제거된 텍스트를 삽입
  }

  useEffect(() => {
    inputElement.current?.focus()

    if (height > INPUT_BOX_DEFAULT_HEIGHT) {
      setCustomStyle({
        height: `${height}px`,
        color: '#fff',
      })
    } else {
      setCustomStyle({ height: `${INPUT_BOX_DEFAULT_HEIGHT}px`, color: '#000' })
    }
  }, [height])

  useEffect(() => {
    if (registLetter && inputElement.current) {
      inputElement.current.value = registLetter
    }
  }, [])

  return (
    <textarea
      ref={inputElement}
      className={styles.input_box}
      onChange={handleInputEvent}
      onKeyDown={handleKeyDown} // Enter 키 눌림을 처리하여 개행을 막음
      maxLength={maxLength} // 최대 글자 수 제한
      onPaste={handlePaste} // 붙여넣기 동작을 처리하여 개행 문자를 삭제
      style={customStyle}
      required={required}
    />
  )
}

export default TextBox
