import { switchOverflowHidden } from '../../../../utils/customStyle'
import styles from './style.module.scss'
import MyQRCode from '../../molecules/MyQRCode'
import { LoggedInUser } from '../../../../type/account'
import { useTranslation } from 'react-i18next'

type MyQRcodeModalType = {
  cancelEvent: () => void
  loggedInUser: LoggedInUser | null
  QRCodeToken: string
}

function MyQRcodeModal({
  cancelEvent,
  loggedInUser,
  QRCodeToken,
}: MyQRcodeModalType) {
  const { t } = useTranslation()

  const handleCancelClickEvent = () => {
    cancelEvent()
    switchOverflowHidden(false)
  }
  return (
    <div className={styles.container}>
      <div className={styles.container_overlay}>
        <div className={styles.container_overlay_title}>
          <p>{t(`ui.atom.button.my-qr-code`)}</p>
        </div>
        <MyQRCode loggedInUser={loggedInUser} QRCodeToken={QRCodeToken} />
        <div className={styles.container_overlay_desc}>
          {t(`ui.organisms.my-qr-code-modal.label`)}
        </div>
      </div>
      <div
        className={styles.container_cancel}
        onClick={() => handleCancelClickEvent()}
      >
        <i className="fa-solid fa-circle-xmark"></i>
      </div>
    </div>
  )
}

export default MyQRcodeModal
