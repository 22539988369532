import { useContext, useEffect, useState } from 'react'
import styles from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { Context } from '../../../Root'
import { useTranslation } from 'react-i18next'
import { constants } from '../../../constants'
import { checkQRToken, addFriend } from './functions'

const { LOCATION } = constants

function AddFriends() {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loggedInUser } = useContext(Context)
  const [isAddSuccess, setIsAddSuccess] = useState({
    isSuccess: false,
  })
  const [isMatchToken, setIsMatchToken] = useState({
    isMatch: false,
  })

  const queryParams = new URLSearchParams(location.search)

  const providerId =
    queryParams.get('id') === null ? 0 : Number(queryParams.get('id'))
  const token =
    queryParams.get('token') === null ? '' : queryParams.get('token')
  const userId = loggedInUser?.id ?? 0

  useEffect(() => {
    if (!userId) {
      alert(t(`page.receive-letters.error-massage.no-login`))
      navigate(LOCATION.PATH.LOGIN)
      return
    }

    if (!providerId) {
      alert(t(`page.add-friends.error.no-provider-id`))
      navigate(LOCATION.PATH.HOME)
      return
    }

    if (!token) {
      alert(t(`page.add-friends.error.no-token`))
      navigate(LOCATION.PATH.HOME)
      return
    }

    if (providerId === userId) {
      alert(t(`page.add-friends.error.match-id`))
      navigate(LOCATION.PATH.HOME)
      return
    }

    checkQRToken(t, token, providerId, setIsMatchToken)
  }, [])

  useEffect(() => {
    addFriend(t, providerId, isMatchToken, setIsAddSuccess)
  }, [isMatchToken.isMatch])

  useEffect(() => {
    if (isAddSuccess.isSuccess) {
      alert(t(`page.add-friends.success`))
      window.location.href = `${LOCATION.PATH.MY_PAGE}?tab-id=3`
    }
  }, [isAddSuccess.isSuccess])

  return <div className={styles.container}></div>
}

export default AddFriends
