import { SelectedLetterClassType } from '../../../../type/letter'
import styles from './style.module.scss'
import { ChangeEvent } from 'react'

type SelectType = {
  setHandleEvent: (selectedId: SelectedLetterClassType) => void
  options: {
    value: number
    text: string
  }[]
}

function Select({ setHandleEvent, options }: SelectType) {
  const handleEvent = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(e.currentTarget.value) as SelectedLetterClassType
    setHandleEvent(selectedId)
  }

  const createOptions = () => {
    return options.map((option, index) => (
      <option value={option.value} key={index}>
        {option.text}
      </option>
    ))
  }

  return (
    <div className={styles.content}>
      <select onChange={(e) => handleEvent(e)} defaultValue={0}>
        {createOptions()}
      </select>
    </div>
  )
}

export default Select
