import { TFunction } from 'i18next'
import axios from 'axios'
import { constants } from '../../constants'
import { ApiError } from '../../type/account'
import { defineErrorHandler } from '../../plugins/AxiosPlugin'
import { SendContact } from '../../type/slack'

const { API_URL } = constants

/**
 * 문의하기 내용을 슬랙에 송신
 * @param t
 * @param contactObj
 */
export const postSlackSendContact = async (
  t: TFunction<'translation', undefined>,
  contactObj: SendContact,
) => {
  await axios
    .post(API_URL.PATH.SLACK_SEND_CONTACT, { contactObj })
    .catch((err: ApiError) => {
      const errorStatus = err.response.status
      const errConstant = defineErrorHandler(errorStatus, t)
      alert(errConstant)
      return
    })
}
