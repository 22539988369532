import { TabsDetail } from '../../../../type/mypage'
import styles from './style.module.scss'
import Tab from '../../atoms/Tab'

type TabBarType = {
  pageTitle: string
  tabs: TabsDetail
  selectedTabId: number
  handleTab: (tabId: number) => void
}

function TabBar({ pageTitle, tabs, selectedTabId, handleTab }: TabBarType) {
  const createTabs = () => {
    return tabs.map((tab, index) => {
      return (
        <Tab
          tab={tab}
          selectedTabId={selectedTabId}
          handleTab={handleTab}
          key={index}
        />
      )
    })
  }
  return (
    <div className={styles.container}>
      <div className={styles.container_page_title}>
        <p>{pageTitle}</p>
      </div>
      <div className={styles.container_tabs}>{createTabs()}</div>
    </div>
  )
}

export default TabBar
