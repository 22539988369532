import { Dispatch, SetStateAction } from 'react'
import { LoggedInUser } from '../../../../type/account'
import { LetterDetailType } from '../../../../type/letter'
import LetterCardBody from '../../molecules/LetterCardBody'
import LetterCardDescription from '../../molecules/LetterCardDescription'
import LetterUser from '../../molecules/LetterUser'
import LetterComments from '../LetterComments'
import styles from './style.module.scss'

type LetterDetailContentsType = {
  letter: LetterDetailType
  loggedInUser: LoggedInUser | null
  showReplyBtn: boolean
  remainingDays: number
  switchAddPeople: (senderId: number) => void
  setInputComment: Dispatch<SetStateAction<string>>
  saveComment: () => void
  inputComment: string
  updateFavorite: (commentId: number) => void
}

function LetterDetailContents({
  letter,
  loggedInUser,
  showReplyBtn,
  remainingDays,
  switchAddPeople,
  setInputComment,
  saveComment,
  inputComment,
  updateFavorite,
}: LetterDetailContentsType) {
  return (
    <div className={styles.container}>
      <LetterUser
        letter={letter}
        loggedInUser={loggedInUser}
        switchAddPeople={switchAddPeople}
        type="sender"
      />

      <LetterCardBody
        letter={letter}
        loggedInUser={loggedInUser}
        showReplyBtn={showReplyBtn}
        switchAddPeople={switchAddPeople}
      />

      <LetterCardDescription letter={letter} remainingDays={remainingDays} />

      <LetterComments
        letter={letter}
        loggedInUser={loggedInUser}
        setInputComment={setInputComment}
        saveComment={saveComment}
        inputComment={inputComment}
        updateFavorite={updateFavorite}
      />
    </div>
  )
}

export default LetterDetailContents
