import { Dispatch, SetStateAction, useState } from 'react'
import styles from './style.module.scss'
import { handleCheckEvent } from './functions'

type CheckBox = {
  labelName: string
  isChecked: boolean
  setIsCehcked: Dispatch<SetStateAction<boolean>>
}
function CheckBox({ labelName, isChecked, setIsCehcked }: CheckBox) {
  return (
    <div className={styles.container}>
      <input
        type="checkBox"
        id="checkBox"
        onClick={() => handleCheckEvent(isChecked, setIsCehcked)}
      />
      <label htmlFor="checkBox">{labelName}</label>
    </div>
  )
}

export default CheckBox
